import React from 'react'
import { hashHistory } from 'react-router'
import Snapshot from '../../../snapshot.V2/Container'
import { SetParentHeight, ClearLocalStorage } from '../../../common.v2/CommonMethods'
import { MAX_WIDTH_TO_OPEN_SNAPSHOT_IN_INPAGE } from '../../../../../utils/appConstants'
import {
  SearchForm, SnapshotModalPopUp
} from 'stifel-intyce-pattern-library'
/*eslint-disable*/
class CompanySearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchItemsStocks: [],
      searchItemsETF: [],
      searchItemsMF: [],
      xid: '',
      showList: '0',
      key: '',
      symbol: '',
      securityType: '',
      searchText: '',
      isSnaphotModalPopupOpen:0
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.selectSymbol = this.selectSymbol.bind(this)
    this.selectSymbolEnter = this.selectSymbolEnter.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.handleOnFocus = this.handleOnFocus.bind(this)
    this.outsideClickListener = this.outsideClickListener.bind(this)
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.outsideClickListener, false)

  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.outsideClickListener, false)
  }

  handleInputChange(event) {
    let inputValue = event
    if (inputValue !== '') {
      this.props.requestSymbolSearchApi(inputValue)
      this.setState((prevState) => {
        return { searchText: inputValue }
      });
    }
    else {
      this.setState((prevState) => {
        return { searchText: '', showList: '0' }
      });
    }
  }

  outsideClickListener(event) {
    if (this.node !== null && !this.node.contains(event.target)) {
      this.setState((prevState) => {
        return { showList: '0' }
      });
    }
  }

  handleOnFocus(event) {
    // resetting searchList 

    if (this.state.searchText == "") {
      this.setState((prevState) => {
        return {
          showList: '0',
          searchItemsStocks: [],
          searchItemsETF: [],
          searchItemsMF: [],
        }
      });
    }
    else {
      this.setState((prevState) => {
        return {
          showList: '1'
        }
      });
    }
  }

  parseData(responseData) {
    let output2 = [];
    if (responseData && responseData.length) {
      responseData.map(result => {
        if ((!!result.symbol) && (!!result.name) && (!!result.securityType)) {
          output2.push({
            key: result.key,
            securityType: result.securityType,
            name: result.name,
            symbol: result.symbol
          });
        }
      });
    }
    return output2;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.symbolSearchResults != this.props.symbolSearchResults &&
      nextProps.symbolSearchResults != null) {
      this.filterSearchItems(nextProps.symbolSearchResults);
      this.setState((prevState) => {
        return { showList: '1' }
      });
    }
    if (nextProps.xid != this.props.xid) {
      let xid = nextProps.xid.xid
      this.setState((prevState) => {
        return { xid: xid }
      });
      this.setState((prevState) => {
        return { showList: '0' }
      });

      if(this.props.searchForWatchlist) {
        this.props.onClick && this.props.onClick(nextProps.xid.xid, this.state.symbol)
        this.props.onKeyPress && this.props.onKeyPress(nextProps.xid.xid, this.state.symbol)
      }
      else {
        if(window.innerWidth < MAX_WIDTH_TO_OPEN_SNAPSHOT_IN_INPAGE) {
          let path = '/Navigator/Snapshot/V2/' + this.state.symbol + '/' + xid + '?returnUrl=/Navigator/Markets/V2'
          hashHistory.push(path);
        }
        else {
          this.setState((prevState) => {
            return {
              symbol: this.state.symbol,
              isSnaphotModalPopupOpen: 1
            }
          }, function () {
            SetParentHeight()
          });
        }
      }
    }
  }

  closePopup(e) {
    e.preventDefault()
    if(e.target.getAttribute('id')=='closeButton' || e.key == 'Escape' || e.keyCode == 27) {
      ClearLocalStorage(this.props.page || '')
      this.setState((prevState) => {
        return {isSnaphotModalPopupOpen: 0}
      }, function () {
        SetParentHeight()
      });
    }
  } 

  selectSymbol(event) {
    event.preventDefault()
    let el = event.target

    let key = event.target.parentElement.getAttribute('data-key');
    this.setState((prevState) => {
      return { key: key }
    });
    let symbol = event.target.parentElement.getAttribute('data-symbol')
    this.setState((prevState) => {
      return { symbol: symbol }
    });
    let securityType = event.target.parentElement.getAttribute('data-security')
    this.setState((prevState) => {
      return { securityType: securityType }
    });
    document.getElementById('search').value = ''
    this.setState((prevState) => {
      return { searchText: '' }
    });

    this.props.requestDecodeApi(key)
    // let path = '/'+ securityType+ 'Snapshot/'+symbol+'/'+ '205778'
    // hashHistory.push(path);
  }

  selectSymbolEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      let el = event.currentTarget
      let key = event.currentTarget.getAttribute('data-key');
      this.setState((prevState) => {
        return { key: key }
      });
      let symbol = event.currentTarget.getAttribute('data-symbol')
      this.setState((prevState) => {
        return { symbol: symbol }
      });
      let securityType = event.currentTarget.getAttribute('data-security')
      this.setState((prevState) => {
        return { securityType: securityType }
      });
      document.getElementById('search').value = ''
      this.setState((prevState) => {
        return { searchText: '' }
      });
      this.props.requestDecodeApi(key)
      // let path = '/'+ securityType+ 'Snapshot/'+symbol+'/'+ '205778'
      // hashHistory.push(path);
    }
  }

  setResultsSizeBasedOnCalculation(searchResults, totalPossibleStockCount, totalPossibleETFCount, totalPossibleMFCount) {
    let stocks = searchResults.stockResults.length > 0 ? searchResults.stockResults.slice(0, totalPossibleStockCount) : []
    let ETF = searchResults.etfResults.length > 0 ? searchResults.etfResults.slice(0, totalPossibleETFCount) : []
    let MF = searchResults.mfResults.length > 0 ? searchResults.mfResults.slice(0, totalPossibleMFCount) : []

    this.setState((prevState) => {
      return {
        searchItemsStocks: stocks,
        searchItemsETF: ETF,
        searchItemsMF: MF
      }
    });
  }

  filterSearchItems(searchResults) {
    //remove items with null data.. 
    searchResults.stockResults = this.parseData(searchResults.stockResults);
    searchResults.etfResults = this.parseData(searchResults.etfResults);
    searchResults.mfResults = this.parseData(searchResults.mfResults);

    let stockCount = searchResults.stockResults != null && Array.isArray(searchResults.stockResults) ? searchResults.stockResults.length : 0;
    let etfCount = searchResults.etfResults != null && Array.isArray(searchResults.etfResults) ? searchResults.etfResults.length : 0;
    let mutualFundCount = searchResults.mfResults != null && Array.isArray(searchResults.mfResults) ? searchResults.mfResults.length : 0;
    let possibleCount = 15;
    let totalPossibleStockCount = 0;
    let totalPossibleETFCount = 0;
    let totalPossibleMFCount = 0;
    let stocks = null;
    let ETF = null;
    let MF = null;
    let resultSize = 5;


    //Edge Cases START
    if ((stockCount + etfCount + mutualFundCount <= 15) ||
      (stockCount <= resultSize && etfCount <= resultSize && mutualFundCount <= resultSize)) {
      totalPossibleStockCount = stockCount;
      totalPossibleETFCount = etfCount;
      totalPossibleMFCount = mutualFundCount;
    }
    else if (stockCount >= resultSize && etfCount >= resultSize && mutualFundCount >= resultSize) {
      totalPossibleStockCount = resultSize;
      totalPossibleETFCount = resultSize;
      totalPossibleMFCount = resultSize;
    }

    // EDGE Cases END

    // Conditional Scenarios Start:
    else if (stockCount <= resultSize) {
      if (mutualFundCount <= resultSize) {
        if (etfCount > resultSize) {
          totalPossibleStockCount = stockCount;
          totalPossibleMFCount = mutualFundCount;
          totalPossibleETFCount = 15 - (totalPossibleMFCount + totalPossibleStockCount);

          totalPossibleETFCount = etfCount < totalPossibleETFCount ? etfCount : totalPossibleETFCount
        }
      }
      else //MF count greater than 5
      {
        if (etfCount > resultSize) {
          totalPossibleStockCount = stockCount;
          totalPossibleMFCount = resultSize;
          totalPossibleETFCount = 15 - (totalPossibleMFCount + stockCount);

          if (etfCount < totalPossibleETFCount) {
            totalPossibleETFCount = etfCount;
            totalPossibleMFCount = 15 - (totalPossibleETFCount + totalPossibleStockCount)
          }

        }
        else {
          totalPossibleStockCount = stockCount;
          totalPossibleETFCount = etfCount;
          totalPossibleMFCount = 15 - (totalPossibleETFCount + totalPossibleStockCount)
        }
      }
    }

    // When Stock Count > pageSize
    else if (stockCount > resultSize) {
      if (mutualFundCount > resultSize) // MF count is greater than 5
      {
        if (etfCount <= resultSize) // ETF count is less than 5
        {
          totalPossibleMFCount = resultSize;
          totalPossibleETFCount = etfCount;
          totalPossibleStockCount = 15 - (totalPossibleETFCount + totalPossibleMFCount);

          if (totalPossibleStockCount > stockCount) // in case calculated stocks are less than available Stocks
          {
            totalPossibleStockCount = stockCount;
            totalPossibleMFCount = 15 - (etfCount + stockCount);
          }

        }
      }
      else //MF count less than 5
      {
        if (etfCount > resultSize) {
          totalPossibleMFCount = mutualFundCount;
          totalPossibleETFCount = resultSize;
          totalPossibleStockCount = 15 - (totalPossibleETFCount + totalPossibleMFCount);

          if (totalPossibleStockCount > stockCount) // in case calculated stocks are less than available Stocks
          {
            totalPossibleStockCount = stockCount;
            totalPossibleETFCount = 15 - (totalPossibleMFCount + totalPossibleStockCount);
          }

        }
        else {
          totalPossibleMFCount = mutualFundCount;
          totalPossibleETFCount = etfCount;
          totalPossibleStockCount = stockCount < totalPossibleStockCount ? stockCount :
            15 - (totalPossibleETFCount + totalPossibleMFCount)  // in case calculated stocks are less than available Stocks
        }
      }
    }

    this.setResultsSizeBasedOnCalculation(searchResults, totalPossibleStockCount, totalPossibleETFCount, totalPossibleMFCount)
  }

  render() {
    return <div
      ref={node => this.node = node}>
      <SearchForm onClick={this.selectSymbol}
        onKeyPress={this.selectSymbolEnter}
        onChange={this.handleInputChange}
        onFocus={this.handleOnFocus}
        searchItemsStocks={this.state.searchItemsStocks}
        searchItemsETFs={this.state.searchItemsETF}
        searchItemsMutualFunds={this.state.searchItemsMF}
        showList={this.state.showList}
        placeHolderText={this.props.placeHolderText}
        searchForWatchlist={this.props.searchForWatchlist}
        isMobileWatchlist={this.props.isMobileWatchlist}
        searchText={this.state.searchText} />

        {this.state.isSnaphotModalPopupOpen == 1 &&
            <SnapshotModalPopUp 
              onClick = {this.closePopup} 
              isOpen = {this.state.isSnaphotModalPopupOpen}> 
              <Snapshot wsodIssue={this.state.xid} symbol={this.state.symbol}></Snapshot>
            </SnapshotModalPopUp>}
    </div>
  }
}

export default CompanySearch
