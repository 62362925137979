/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import PerformanceTableStyles from '../../../../styles/watchlist/holdingsPerformanceTable.scss'
import WatchlistsStyles from '../../../../styles/watchlist/watchlists.scss'
import { Format, Common } from 'stifel-intyce-pattern-library'
import { ISSUE_TYPES_MAP, ISSUE_TYPES } from '../../../../utils/appConstants'
import { mapIndicesSymbol } from '../../../common.v2/CommonMethods'
import commonStyles from '../../../../styles/common.v2/common.scss'

const TablePerformance = props => {
  if (props.holdings.length === 0) {
    return (
      <div className={`${PerformanceTableStyles['tbl-holdings']}`}>
        <div className={`${PerformanceTableStyles['no-data']}`}>
          Add securities to get started.
        </div>
      </div>
    )
  }

  function renderSortIcon (sortBy) {
    return props.sortBy.columnName === sortBy &&
    <span className={`${WatchlistsStyles[ 'arrow-' + props.sortBy.order ]}`} />
  }

  return (
    <div className={`${PerformanceTableStyles['tbl-holdings']}`}>
      <div className={`${PerformanceTableStyles['header']} ${PerformanceTableStyles['row']}`}
        data-sort-by={props.sortBy.columnName}>
        <div className={`${PerformanceTableStyles['symbol']}`}>&nbsp;</div>
        <div className={`${PerformanceTableStyles['last']}`}>&nbsp;</div>
        <div className={`${PerformanceTableStyles['change']}`}>&nbsp;</div>
        <div className={`${PerformanceTableStyles['range-52week']}`}>
          52-Week Range
        </div>
        <div className={`${PerformanceTableStyles['volume']}`}>&nbsp;</div>
        <div className={`${PerformanceTableStyles['action']}`}>&nbsp;</div>
      </div>

      <div className={`${PerformanceTableStyles['header']} ${PerformanceTableStyles['header-1']} ${PerformanceTableStyles['row']}`}>
        <div className={`${PerformanceTableStyles['symbol']}`}>
          { renderSortIcon('symbol') }
          <span
            tabIndex='0'
            data-col-name='symbol'
            data-col-type='String'
            onClick={props.onColHeaderClick}
            onKeyPress={props.onColHeaderClickEnter}
            className={`${PerformanceTableStyles['col-header']}`}>
            Symbol / Name
          </span>
        </div>

        <div className={`${PerformanceTableStyles['last']}`}>
          { renderSortIcon('quote') }
          <span
            tabIndex='0'
            data-col-name='quote'
            data-col-type='Number'
            onClick={props.onColHeaderClick}
            onKeyPress={props.onColHeaderClickEnter}
            className={`${PerformanceTableStyles['col-header']}`}>
            Last
          </span>
        </div>

        <div className={`${PerformanceTableStyles['change']}`}>
          { renderSortIcon('changePct') }
          <span
            tabIndex='0'
            data-col-name='changePct'
            data-col-type='Number'
            onClick={props.onColHeaderClick}
            onKeyPress={props.onColHeaderClickEnter}
            className={`${PerformanceTableStyles['col-header']}`}>
            Change
          </span>
        </div>

        <div className={`${PerformanceTableStyles['range-52week-low-high']}`}>
          <div className={`${PerformanceTableStyles['low']}`}>Low</div>
          <div className={`${PerformanceTableStyles['high']}`}>High</div>
        </div>

        <div className={`${PerformanceTableStyles['volume']}`}>
          { renderSortIcon('tradingVolume') }
          <span
            tabIndex='0'
            data-col-name='tradingVolume'
            data-col-type='Number'
            onClick={props.onColHeaderClick}
            onKeyPress={props.onColHeaderClickEnter}
            className={`${PerformanceTableStyles['col-header']}`}>
            Volume
          </span>
        </div>

        <div className={`${PerformanceTableStyles['action']}`}>
          &nbsp;
        </div>
      </div>

      <div data-symbols-container='true'>
        {
          props.holdings.map((item, index) => (
            <div className={`${PerformanceTableStyles['row']} ${PerformanceTableStyles['data-row']}`}
              key={item.xid} data-holding-row={item.holdingId}>
              <div tabIndex='0' className={`${PerformanceTableStyles['symbol']}`}
                data-wsod-issue={item.xid}
                data-symbol={mapIndicesSymbol(item.xid, item.symbol)}
                onClick={props.onSymbolClick}
                onKeyDown={props.onSymbolClickEnter}>
                <div className={`${PerformanceTableStyles['name']}`}>
                  { mapIndicesSymbol(item.xid, item.symbol) }
                </div>
                <div className={`${PerformanceTableStyles['company']}`} title={item.name}>
                  { item.name }
                </div>
              </div>

              <div className={`${PerformanceTableStyles['last']}`}>
                { Format.formatNumber(ISSUE_TYPES.MutualFund === ISSUE_TYPES_MAP[item.issueType] ? item.nav : item.quote, {currencyPreFix: true}) }
              </div>

              <div className={`${PerformanceTableStyles['change']}`}>
                <div className={`${PerformanceTableStyles['price-percent']}`}>
                  <div>
                    { Format.formatNumber(item.change, {currencyPreFix: true, negSign: true, color: true}) }
                  </div>
                  <div>
                    { Format.formatNumber(item.changePct, {postFix: true, negSign: true, color: true}) }
                  </div>
                </div>
                <div className={`${PerformanceTableStyles['icon']}`}>
                  { (Format.formatNumber(item.change) !== '--' ||
                        Format.formatNumber(item.changePct) !== '--')
                    ? Common.RenderImageArrow(item.change) : '' }
                </div>
              </div>

              <div className={`${PerformanceTableStyles['range-52week-low-high']}`}>
                <div className={`${PerformanceTableStyles['low']}`}>
                  { Format.formatNumber(item.low52W, {currencyPreFix: true}) }
                </div>
                <div className={`${PerformanceTableStyles['slider-container']} ${WatchlistsStyles['slider-container']}`}>
                  <input type='range' step='any' disabled
                    min={item.low52W}
                    max={item.high52W}
                    value={item.quote || ''}
                    className={`${WatchlistsStyles['slider']}`} />
                </div>
                <div className={`${PerformanceTableStyles['high']}`}>
                  { Format.formatNumber(item.high52W, {currencyPreFix: true}) }
                </div>
              </div>

              <div className={`${PerformanceTableStyles['volume']}`}>
                { Format.formatNumber(item.tradingVolume, {precision: 0}) }
              </div>

              <div data-holdingId={item.holdingId} data-symbol={item.symbol}
                onClick={props.onDeleteSymbolClick}
                onKeyDown={props.onDeleteSymbolClickEnter}
                tabIndex='0'
                data-el-identifier='holding-performance_delete'
                className={`${PerformanceTableStyles['action']}`}>
                <span className={`${WatchlistsStyles['sprites-img']} ${WatchlistsStyles['del-icon']}  ${PerformanceTableStyles['del-icon']}`}>
                  &nbsp;
                </span>
              </div>

              {
                props.deletingSymbolInfo.holdingId === item.holdingId && props.deletingSymbolInfo.deleting &&
                <div className={`${commonStyles['loader']} ${WatchlistsStyles['deleting-symbol']}`}>
                  <div className={`${commonStyles['rollingloader']}`}>
                    &nbsp;
                  </div>
                  <div className={`${WatchlistsStyles['deleting-symbol-text']}`}>
                    Deleting...
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

TablePerformance.propTypes = {
  holdings: PropTypes.array,
  onSymbolClick: PropTypes.func,
  onSymbolClickEnter: PropTypes.func,
  onDeleteSymbolClickEnter: PropTypes.func,
  onDeleteSymbolClick: PropTypes.func,
  onColHeaderClick: PropTypes.func,
  onColHeaderClickEnter: PropTypes.func,
  deletingSymbolInfo: PropTypes.object,
  sortBy: PropTypes.object
}

export default TablePerformance
