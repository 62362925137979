import * as DataRequestMethods from './dataRequestMethods'
import TagManager from 'react-gtm-module'
import {
  USAGE_REPORTING_TRACK_ENTITY,
  USAGE_REPORTING_TRACK_ENTITY_DATA_CATEGORY_SYMBOL,
  USAGE_REPORTING_TRACK_PAGE,
  USAGE_REPORTING_TRACK_PAGE_DATA_PAGE,
  GTM_ID
} from './appConstants'

const loadScript = (state, callback) => {
  const isDataEmpty = state && state.data && (!state.data.value || !state.data.value.length)
  const isDataRequestSuccessful = state && state.data && state.dataRequestError && !state.dataRequestError.status

  // Do nothing if fetching script content failed
  if (isDataEmpty || !isDataRequestSuccessful) {
    console.warn('Markit Digital | Usage Reporting script retrieval failed. Tracking not executed.')
    return
  }

  // Append script to document body
  const script = document.createElement('script')
  script.text = state.data.value
  script.type = 'text/javascript'

  document.body.appendChild(script)

  callback && callback()
}

const executeUsageReportingTracking = callback => {
  // Execute callback if script is already loaded
  if (window._waq) {
    callback && callback()
    return
  }

  const dataRequestCallback = state => {
    // Execute callback if script is already loaded
    if (window._waq) {
      callback && callback()
      return
    }

    loadScript(state, callback)
  }

  // Get usage reporting analytics tracker script
  DataRequestMethods.executeDataRequestViaApiPath(
    '/usageReportingTracker',
    'GET',
    null,
    dataRequestCallback
  )
}

export const trackPage = (name, callback) => {
  if (!name) return

  const trackPage = () => {
    const options = {
      name: name,
      page: USAGE_REPORTING_TRACK_PAGE_DATA_PAGE
    }

    window._waq.push([USAGE_REPORTING_TRACK_PAGE, options])

    callback && callback()
  }

  // Trigger usage reporting track page
  executeUsageReportingTracking(trackPage)
}

export const trackSymbol = symbol => {
  if (!symbol) return

  const trackEntity = () => {
    const options = {
      category: USAGE_REPORTING_TRACK_ENTITY_DATA_CATEGORY_SYMBOL,
      name: symbol.toUpperCase()
    }

    window._waq.push([USAGE_REPORTING_TRACK_ENTITY, options])
  }

  // Trigger usage reporting track entity
  executeUsageReportingTracking(trackEntity)
}

export const initiaizeGoogleAnalytics = () => {
  // GTM args for Investor (Tracker) and Advisor (Navigator)
  let isInvestorSite = window.location.href.toLowerCase().indexOf('navigator') === -1
  let tagManagerArgs = {
    gtmId: isInvestorSite ? GTM_ID.Investor : GTM_ID.Advisor,
    auth: isInvestorSite ? window.MD.GTM_AUTH_INVESTOR : window.MD.GTM_AUTH_ADVISOR,
    preview: window.MD.GTM_PREVIEW || ''
  }

  // Initialize Google Analytics
  TagManager.initialize(tagManagerArgs)
}
