import Types from './types'
import { ISSUE_TYPES, API_END_POINTS, NEWS_CONSTANTS, SHOW_DIVIDEND_DATA } from '../utils/appConstants'
import { GetXidSymbIndices, CheckIsSymbIndices } from '../components/common.v2/CommonMethods'

const receiveSnapshotV2Data = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_DATA_API_SUCCESS,
    data: response.data
  }
}

const requestSnapshotV2Data = (wsodIssue, symbol) => {
  let allAPICalls = [
    {
      resultKey: 'quoteData',
      endPoint: API_END_POINTS.QUOTE,
      params: { wsodIssue: wsodIssue },
      ajaxType: 'GET'
    },
    {
      resultKey: 'quoteData_Platform',
      endPoint: API_END_POINTS.QUOTE_PLATFORM,
      params: { venueXids: wsodIssue },
      ajaxType: 'GET',
      apiType: 'PLATFORM'
    }
  ]
  if (symbol && !CheckIsSymbIndices(symbol)) {
    const xrefCall = { resultKey: 'symbolXREFData_Platform',
      endPoint: API_END_POINTS.XID_FROM_SYMBOL_PLATFORM + encodeURIComponent(symbol) + '?isCaseSensitive=false',
      params: { },
      ajaxType: 'GET',
      apiType: 'PLATFORM'}
    allAPICalls = [...allAPICalls, xrefCall]
  }
  return {
    type: Types.GET_SNAPSHOT_V2_DATA_API,
    apiCalls: allAPICalls,
    onSuccess: receiveSnapshotV2Data
  }
}

const receiveSnapshotV2FundIndicatorData = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_FUND_INDICATOR_DATA_API_SUCCESS,
    data: response.data
  }
}

const requestSnapshotV2FundIndicatorData = (wsodIssue) => {
  return {
    type: Types.GET_SNAPSHOT_V2_FUND_INDICATOR_DATA_API,
    apiCalls: [
      {
        resultKey: 'fundIndicator_Platform',
        endPoint: API_END_POINTS.ETF_FUND + wsodIssue,
        params: { },
        ajaxType: 'GET',
        apiType: 'PLATFORM'
      }
    ],
    onSuccess: receiveSnapshotV2FundIndicatorData
  }
}

const receiveSnapshotV2OptionChainData = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_OPTION_CHAIN_DATA_API_SUCCESS,
    data: response.data
  }
}

const requestSnapshotV2DividendData = (xids, securityType) => {
  if (xids) {
    const url = `${API_END_POINTS.CORPORATEACTIONS_DIVIDENDS_PLATFORM}/${xids.venue}/current`
    return {
      type: Types.GET_MARKETS_EQUITY_DIVIDENT_DATA_API,
      apiCalls: [
        {
          resultKey: 'equityDividend_Platform',
          endPoint: url,
          params: { },
          ajaxType: 'GET',
          apiType: 'PLATFORM'
        }
      ],
      onSuccess: receiveSnapshotV2DividendData
    }
  }
}

const receiveSnapshotV2DividendData = (response) => {
  return {
    type: Types.GET_MARKETS_EQUITY_DIVIDENT_DATA_API_SUCCESS,
    data: response.data
  }
}
const requestSnapshotV2OptionChainData = (wsodIssue, expirationDate) => {
  return {
    type: Types.GET_SNAPSHOT_V2_OPTION_CHAIN_DATA_API,
    apiCalls: [
      {
        resultKey: 'optionChain_Platform',
        endPoint: API_END_POINTS.OPTION_CHAIN_PLATFORM + wsodIssue + '?callsPuts=all&adjustment=all&expirationDates=' + expirationDate + '&realTimeQuotes=false',
        params: { },
        ajaxType: 'GET',
        apiType: 'PLATFORM'
      }
    ],
    onSuccess: receiveSnapshotV2OptionChainData
  }
}

const receiveSnapshotV2OptionChainExpirationDatesData = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_OPTION_CHAIN_EXPIRATION_DATES_DATA_API_SUCCESS,
    data: response.data
  }
}

const requestSnapshotV2OptionChainExpirationDatesData = (wsodIssue) => {
  return {
    type: Types.GET_SNAPSHOT_V2_OPTION_CHAIN_EXPIRATION_DATES_DATA_API,
    apiCalls: [
      {
        resultKey: 'optionChainsExpirationDates_Platform',
        endPoint: API_END_POINTS.OPTION_CHAIN_EXPIRATION_DATES_PLATFORM + wsodIssue + '?contractTerm=all',
        params: { },
        ajaxType: 'GET',
        apiType: 'PLATFORM'
      }
    ],
    onSuccess: receiveSnapshotV2OptionChainExpirationDatesData
  }
}

const receiveSnapshotV2GeneralInfo = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_GENERALINFO_API_SUCCESS,
    payload: response.data
  }
}

const requestSnapshotV2GeneralInfo = (symbol, instrumentXIDs) => {
  let allAPICalls = [
    {
      resultKey: 'generalInfo',
      endPoint: API_END_POINTS.GENERAL_INFO,
      params: { symbol: symbol },
      ajaxType: 'GET'
    }
  ]
  if (instrumentXIDs && instrumentXIDs.instrument) {
    if (SHOW_DIVIDEND_DATA) {
      allAPICalls.push(
        {
          resultKey: 'equityDividend_History',
          endPoint: API_END_POINTS.EQUITY_DIVIDEND + '?dataSource=EQUITY_DIVIDEND_HISTORY&instrumentXIDs=' + instrumentXIDs.instrument,
          params: { },
          ajaxType: 'GET'
        }
      )
      allAPICalls.push(
        {
          resultKey: 'equitySplit_History',
          endPoint: API_END_POINTS.EQUITY_DIVIDEND + '?dataSource=EQUITY_SPLIT_HISTORY&instrumentXIDs=' + instrumentXIDs.instrument,
          params: { },
          ajaxType: 'GET'
        }
      )
    }
  }
  return {
    type: Types.GET_SNAPSHOT_V2_GENERALINFO_API,
    apiCalls: allAPICalls,
    onSuccess: receiveSnapshotV2GeneralInfo
  }
}

const receiveSnapshotV2PerformanceSummaryData = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_PERFORMANCE_SUMMARY_API_SUCCESS,
    payload: response.data
  }
}

const requestSnapshotV2PerformanceSummaryData = (wsodIssue) => {
  return {
    type: Types.GET_SNAPSHOT_V2_PERFORMANCE_SUMMARY_API,
    apiCalls: [
      {
        resultKey: 'topHoldings_Platform',
        endPoint: API_END_POINTS.TOP_HOLDINGS_PLATFORM + wsodIssue,
        params: { },
        ajaxType: 'GET',
        apiType: 'PLATFORM'
      },
      {
        resultKey: 'prospectusFees_Platform',
        endPoint: API_END_POINTS.PROSPECTUS_FEES_PLATFORM + wsodIssue,
        params: { },
        ajaxType: 'GET',
        apiType: 'PLATFORM'
      },
      {
        resultKey: 'shareClassInformation_Platform',
        endPoint: API_END_POINTS.SHARE_CLASS_INFO_PLATFORM + wsodIssue,
        params: { },
        ajaxType: 'GET',
        apiType: 'PLATFORM'
      },
      {
        resultKey: 'trailingMonthEnd_Platform',
        endPoint: API_END_POINTS.TRAILING_MONTH_END_PLATFORM + wsodIssue + '?periodTypes=y,sinceInception&periods=1,5,10&dataPoints=loadAdjustedReturn,return',
        params: { },
        ajaxType: 'GET',
        apiType: 'PLATFORM'
      }
    ],
    onSuccess: receiveSnapshotV2PerformanceSummaryData
  }
}

const receiveRevenueChart = (response) => {
  return {
    type: Types.REQUEST_REVENUE_CHART_API_SUCCESS,
    payload: response.data
  }
}

const requestRevenueChart = (wsodIssue, width = 350, height = 125) => {
  return {
    type: Types.REQUEST_REVENUE_CHART_API,
    apiCalls: [
      {
        resultKey: 'revenueChart',
        endPoint: API_END_POINTS.REVENUE_CHART + '?wsodIssue=' + wsodIssue + '&width=' + Math.floor(width) + '&height=' + Math.floor(height),
        params: { },
        ajaxType: 'GET'
      }
    ],
    onSuccess: receiveRevenueChart
  }
}

const receiveSnapshotV2MFOverviewData = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_MF_OVERVIEW_DATA_API_SUCCESS,
    payload: response.data
  }
}

const receiveDividendChart = (response) => {
  return {
    type: Types.REQUEST_DIVIDEND_CHART_API_SUCCESS,
    payload: response.data
  }
}

const requestDividendChart = (instrumentXIDs, width = 350, securityType, height = 135) => {
  const isStock = securityType === ISSUE_TYPES.Stock
  if (isStock) {
    return requestStockDividendChart(instrumentXIDs)
  }
  return {
    type: Types.REQUEST_DIVIDEND_CHART_API,
    apiCalls: [
      {
        resultKey: 'dividendChart',
        endPoint: API_END_POINTS.DIVIDEND_CHART + '?instrumentXID=' + (instrumentXIDs && instrumentXIDs.instrument) + '&width=' + Math.floor(width) + '&height=' + Math.floor(height) + '&symbolType=' + securityType,
        params: { },
        ajaxType: 'GET'
      }
    ],
    onSuccess: receiveDividendChart
  }
}

const requestStockDividendChart = (venueXids) => {
  const url = `${API_END_POINTS.CORPORATEACTIONS_DIVIDENDS_PLATFORM}/${venueXids.venue}/historical-events`

  return {
    type: Types.REQUEST_DIVIDEND_CHART_API,
    apiCalls: [
      {
        resultKey: 'dividendChart',
        endPoint: url,
        params: {
          convertToUSD: true,
          numberOfResults: 6,
          excludeIrregularDividends: true,
          excludePercentDividends: true
        },
        ajaxType: 'GET',
        apiType: 'PLATFORM'
      }
    ],
    onSuccess: receiveDividendChart
  }
}

const requestSnapshotV2MFOverviewData = (wsodIssue, chartTimeFrame) => {
  return {
    type: Types.GET_SNAPSHOT_V2_MF_OVERVIEW_DATA_API,
    apiCalls: [
      {
        resultKey: 'mfOverviewFundObjective',
        endPoint: API_END_POINTS.SNAPSHOT_INVESTMENT_BASICS,
        params: {wsodIssue: wsodIssue, legalType: 'FO'},
        ajaxType: 'GET'
      },
      {
        resultKey: 'mfOverview10KGrowthChart',
        endPoint: API_END_POINTS.MUTUALFUND_10K_GROWTH_CHART,
        params: {
          wsodIssue: wsodIssue, time: chartTimeFrame
        },
        ajaxType: 'GET'
      }
    ],
    onSuccess: receiveSnapshotV2MFOverviewData
  }
}

const receiveSnapshotV2ETFOverviewData = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_ETF_OVERVIEW_DATA_API_SUCCESS,
    payload: response.data
  }
}

const requestSnapshotV2ETFOverviewData = (wsodIssue, instrumentXIDs) => {
  let allAPICalls = [
    {
      resultKey: 'etfOverviewFundObjective',
      endPoint: API_END_POINTS.SNAPSHOT_INVESTMENT_BASICS,
      params: {wsodIssue: wsodIssue, legalType: 'FE'},
      ajaxType: 'GET'
    }
  ]
  if (instrumentXIDs && instrumentXIDs.instrument) {
    if (SHOW_DIVIDEND_DATA) {
      allAPICalls.push(
        {
          resultKey: 'equityDividend_History',
          endPoint: API_END_POINTS.EQUITY_DIVIDEND + '?dataSource=ETF_DIVIDENDS_HISTORY&instrumentXIDs=' + instrumentXIDs.instrument,
          params: { },
          ajaxType: 'GET'
        }
      )
      allAPICalls.push(
        {
          resultKey: 'equitySplit_History',
          endPoint: API_END_POINTS.EQUITY_DIVIDEND + '?dataSource=EQUITY_SPLIT_HISTORY&instrumentXIDs=' + instrumentXIDs.instrument,
          params: { },
          ajaxType: 'GET'
        }
      )
    }
  }
  return {
    type: Types.GET_SNAPSHOT_V2_ETF_OVERVIEW_DATA_API,
    apiCalls: allAPICalls,
    onSuccess: receiveSnapshotV2ETFOverviewData
  }
}

const receiveSnapshotV2XidFromSymbol = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_XID_FROM_SYMBOL_API_SUCCESS,
    payload: response.data
  }
}

const requestSnapshotV2XidFromSymbol = (symbol) => {
  const formattedSymb = CheckIsSymbIndices(symbol)
  if (formattedSymb) {
    const xid = GetXidSymbIndices(symbol)
    return receiveSnapshotV2XidFromSymbol({data: {xidFromSymbol_Platform: {data: {xids: {venue: xid}, symbol: formattedSymb.toUpperCase()}}}})
  } else {
    return {
      type: Types.GET_SNAPSHOT_V2_XID_FROM_SYMBOL_API,
      apiCalls: [
        {
          resultKey: 'xidFromSymbol_Platform',
          endPoint: API_END_POINTS.XID_FROM_SYMBOL_PLATFORM + symbol + '?isCaseSensitive=false',
          params: { },
          ajaxType: 'GET',
          apiType: 'PLATFORM'
        }
      ],
      onSuccess: receiveSnapshotV2XidFromSymbol
    }
  }
}

const receiveSnapshotV2MFGrowth10KChartData = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_10K_GROWTH_CHART_DATA_API_SUCCESS,
    payload: response.data
  }
}

const requestSnapshotV2MFGrowth10KChartData = (wsodIssue, chartTimeFrame) => {
  return {
    type: Types.GET_SNAPSHOT_V2_10K_GROWTH_CHART_DATA_API,
    apiCalls: [
      {
        resultKey: 'mfOverview10KGrowthChart',
        endPoint: API_END_POINTS.MUTUALFUND_10K_GROWTH_CHART,
        params: {
          wsodIssue: wsodIssue, time: chartTimeFrame
        },
        ajaxType: 'GET'
      }
    ],
    onSuccess: receiveSnapshotV2MFGrowth10KChartData
  }
}

const receiveSnapshotV2NewsData = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_NEWS_API_SUCCESS,
    payload: response.data
  }
}

const requestSnapshotV2NewsData = (securityType, wsodIssue, newsProvider, rowNumber, articleCount = 10, loadImage, imgSize, storeKeyName) => {
  let newsApi = {
    resultKey: storeKeyName || 'newsData',
    endPoint: API_END_POINTS.NEWS,
    loadImage: loadImage,
    imageEndpoint: '/newsImage',
    imgSize: imgSize,
    params: {
      rowNumber: rowNumber,
      articleCount: articleCount
    },
    ajaxType: 'GET'
  }

  if (wsodIssue) {
    newsApi['params']['wsodissue'] = wsodIssue
  }

  switch (securityType) {
    case ISSUE_TYPES.Stock:
    case ISSUE_TYPES.Etf:
    case ISSUE_TYPES.Indices:
      newsApi['params']['provider'] = newsProvider
      break
    case ISSUE_TYPES.MutualFund:
      newsApi['endPoint'] = API_END_POINTS.MF_NEWS
      break
    default:
      newsApi['params']['provider'] = newsProvider
      break
  }

  if (storeKeyName === 'marketsNewsData') {
    newsApi['params']['lastNDaysNews'] = newsProvider === 'Top News' ? 30 : NEWS_CONSTANTS.LAST_N_DAYS_NEWS
  }

  return {
    type: Types.GET_SNAPSHOT_V2_NEWS_API,
    apiCalls: [newsApi],
    onSuccess: receiveSnapshotV2NewsData
  }
}

const receiveSnapshotV2NewsArticleData = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_NEWS_ARTICLE_API_SUCCESS,
    payload: response.data
  }
}

const requestSnapshotV2NewsArticleData = (articleId, loadImage, imgSize) => {
  return {
    type: Types.GET_SNAPSHOT_V2_NEWS_ARTICLE_API,
    apiCalls: [
      {
        resultKey: 'newsArticle',
        endPoint: API_END_POINTS.NEWS_ARTICLE,
        loadImage: loadImage,
        imageEndpoint: API_END_POINTS.NEWS_IMAGE,
        imgSize: imgSize,
        params: {articleId: articleId},
        ajaxType: 'GET'
      }
    ],
    onSuccess: receiveSnapshotV2NewsArticleData
  }
}

const receiveSnapshotV2NewsImageData = (response) => {
  return {
    type: Types.GET_SNAPSHOT_V2_NEWS_IMAGE_API_SUCCESS,
    payload: response.data
  }
}

const requestSnapshotV2NewsImageData = (documentKey, size) => {
  return {
    type: Types.GET_SNAPSHOT_V2_NEWS_IMAGE_API,
    apiCalls: [
      {
        resultKey: 'newsImage',
        endPoint: API_END_POINTS.NEWS_IMAGE,
        params: {documentKey: documentKey, size: size},
        ajaxType: 'GET'
      }
    ],
    onSuccess: receiveSnapshotV2NewsImageData
  }
}

const setFetchingDataFlag = (response) => ({
  type: Types.SET_FETCHING_DATA_FLAG,
  payload: response
})

export {
  setFetchingDataFlag,
  requestSnapshotV2Data,
  receiveSnapshotV2Data,
  requestSnapshotV2FundIndicatorData,
  receiveSnapshotV2FundIndicatorData,
  requestSnapshotV2OptionChainData,
  receiveSnapshotV2OptionChainData,
  requestSnapshotV2OptionChainExpirationDatesData,
  receiveSnapshotV2OptionChainExpirationDatesData,
  requestSnapshotV2MFOverviewData,
  receiveSnapshotV2MFOverviewData,
  requestSnapshotV2MFGrowth10KChartData,
  receiveSnapshotV2MFGrowth10KChartData,
  requestSnapshotV2ETFOverviewData,
  receiveSnapshotV2ETFOverviewData,
  requestSnapshotV2XidFromSymbol,
  receiveSnapshotV2XidFromSymbol,
  requestSnapshotV2NewsData,
  receiveSnapshotV2NewsData,
  requestSnapshotV2GeneralInfo,
  receiveSnapshotV2GeneralInfo,
  requestSnapshotV2NewsArticleData,
  receiveSnapshotV2NewsArticleData,
  requestSnapshotV2PerformanceSummaryData,
  receiveSnapshotV2PerformanceSummaryData,
  requestSnapshotV2NewsImageData,
  receiveSnapshotV2NewsImageData,
  receiveRevenueChart,
  requestRevenueChart,
  receiveDividendChart,
  requestDividendChart,
  requestSnapshotV2DividendData,
  receiveSnapshotV2DividendData
}

