/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React from 'react'
import PropTypes from 'prop-types'
import { MAX_WATCHLISTS, WATCHLIST_MESSAGES } from '../../../utils/appConstants'
import styles from '../../../styles/watchlist/CreateWatchlistCard.scss'

const CreateWatchlistCard = (props) => {
  let maxLimit = props.watchlistCount >= MAX_WATCHLISTS
  return (
    <div className={styles['create-watchlist-card-container']}>
      <div role='presentation' className={`${styles['create-watchlist-button']} ${maxLimit ? styles['disable'] : ''}`}
        onClick={maxLimit ? '' : props.onClick}
        onKeyPress={maxLimit ? '' : props.onKeyPress}>
        <span>+</span>
        <span data-el-identifier='create-watchlist_value' tabIndex='0'>Create New Watchlist</span>
      </div>
      { maxLimit &&
        <div className={styles['max-limit-watchlist']}>
          <span data-el-identifier='create-watchlist_max-limit'>{WATCHLIST_MESSAGES.MAX_WATCHLISTS}</span>
        </div>
      }
    </div>
  )
}

CreateWatchlistCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  watchlistCount: PropTypes.number.isRequired
}

export default CreateWatchlistCard
