
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
import { ISSUE_TYPES, DATE_TIME_CONSTANTS, SNAPSHOT_NEWS_CONSTANTS } from '../../../../utils/appConstants'
import snapshotStyles from '../../../../styles/snapshot.V2/snapshot.scss'
import newsStyles from '../../../../styles/snapshot.V2/news/newsContainer.scss'
import * as SnapshotV2Actions from '../../../../actions/snapshot.V2.Actions'
import NewsCardMobile from './NewsCardMobile'
// import NewsArticleMobileContainer from './NewsArticleMobileContainer'
import NewsLinkList from './NewsLinkList'
import { Format } from 'stifel-intyce-pattern-library'
import { SetParentHeight, GetLocalStorageIfAvailable, SetSpecificKeysIntoLocalStorage, IsCompanySnapshotPage } from '../../../common.v2/CommonMethods'
import commonStyles from '../../../../styles/common.v2/common.scss'

class NewsMobileContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      wsodIssue: null,
      showMore: sessionStorage.getItem('snapshot-v2-expand-collapse') !== null
        ? sessionStorage.getItem('snapshot-v2-expand-collapse') === 'true' : true,
      articleId: null,
      showNewsArticle: false,
      news: [],
      startRowNumber: 0,
      pageSize: 6,
      currentPageNumber: sessionStorage.getItem('snapshot-v2-news-page-no') === 'null' ? 1 : sessionStorage.getItem('snapshot-v2-news-page-no')
    }

    this.props.setFetchingDataFlag({
      newsData: {
        fetching: true
      }}
    )

    this.expandCollapseNews = this.expandCollapseNews.bind(this)
    this.loadNewsArticle = this.loadNewsArticle.bind(this)
    this.loadNews = this.loadNews.bind(this)
    this.getPageOnNews = this.getPageOnNews.bind(this)
  }

  componentDidMount () {
    this.setState({
      wsodIssue: this.props.wsodIssue
    }, () => {
      this.loadNews()
    })

    SetParentHeight()
    let localStorage = GetLocalStorageIfAvailable(this.getPageOnNews(), SNAPSHOT_NEWS_CONSTANTS)
    if (localStorage) {
      this.loadNewsArticle(null, localStorage)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    SetParentHeight()
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.fetching && nextProps.totalMatches > 0) {
      this.setState({
        news: nextProps.news
      })
    }
  }

  loadNews () {
    let xid = ''
    let newsProvider

    switch (this.props.securityType) {
      case ISSUE_TYPES.Stock:
        newsProvider = 'Stock'
        xid = this.state.wsodIssue
        break
      case ISSUE_TYPES.Etf:
        newsProvider = 'ETF'
        break
      case ISSUE_TYPES.Indices:
        newsProvider = 'Top News'
        break
      case ISSUE_TYPES.MutualFund:
        xid = this.state.wsodIssue
        break
    }

    this.props.requestSnapshotV2NewsData(
      this.props.securityType,
      xid,
      newsProvider,
      this.state.startRowNumber,
      this.state.pageSize,
      true,
      'Medium'
    )
  }

  expandCollapseNews () {
    this.setState({
      showMore: !this.state.showMore
    })
  }

  loadNewsArticle (e, localStorage) {
    let articleId
    let txtPageNumber
    let page = this.getPageOnNews()
    if (!e) {
      if (localStorage) {
        localStorage = JSON.parse(localStorage)
        articleId = localStorage.articleId
        txtPageNumber = localStorage.txtPageNumber
      } else {
        return
      }
    } else {
      e.preventDefault()
      articleId = e.currentTarget.getAttribute('data-articleId')
      txtPageNumber = document.getElementById('txtPageNumber')
    }
    let selectedTab
    switch (this.props.securityType) {
      case ISSUE_TYPES.Stock:
      case ISSUE_TYPES.Indices:
        selectedTab = 'News'
        break
      case ISSUE_TYPES.Etf:
      case ISSUE_TYPES.MutualFund:
        selectedTab = 'Top Holdings News'
        break
    }
    sessionStorage.setItem('snapshot-v2-selected-tab', selectedTab)
    sessionStorage.setItem('snapshot-v2-expand-collapse', this.state.showMore)
    sessionStorage.setItem('snapshot-v2-news-page-no', txtPageNumber && txtPageNumber.value.trim() !== '' ? parseInt(txtPageNumber.value) : null)

    let path = '/Snapshot/V2/News/Article/2/' + articleId + '?page=' + page

    if (IsCompanySnapshotPage()) {
      path += '&fromcompanysnapshot=true'
    }

    hashHistory.push(path)
    if (!localStorage) {
      let obj = {
        articleId: articleId,
        showNewsArticle: true,
        currentPageNumber: txtPageNumber && txtPageNumber.value.trim() !== '' ? parseInt(txtPageNumber.value) : null
      }

      SetSpecificKeysIntoLocalStorage(page, {
        [SNAPSHOT_NEWS_CONSTANTS]: JSON.stringify(obj)
      })
    }
    // hashHistory.push(path)
    // this.setState({
    //   articleId: articleId,
    //   showNewsArticle: true,
    //   currentPageNumber: txtPageNumber && txtPageNumber.value.trim() !== '' ? parseInt(txtPageNumber.value) : null
    // })
  }
  getPageOnNews () {
    return this.props.page ? this.props.page : (this.props.location && this.props.location.query && this.props.location.query.page) ? this.props.location.query.page : ''
  }
  render () {
    if (this.props.fetching) {
      return (
        <div className={`${newsStyles['news-container']}`}>
          <div className={`${commonStyles['loader']} ${commonStyles['loader-no-border']}`}>
            <div className={`${commonStyles['rollingloader']} ${commonStyles['rollingloader-pos']}`} />
          </div>
        </div>
      )
    }

    if (this.props.isErrorOccurred) {
      return (
        <div className={`${newsStyles['news-container']}`}>
          <div className={`${snapshotStyles['error-container']}`}>
            <div>
              Oops! There was an error processing your request. Please try again.<br />
              <div className={`${snapshotStyles['error-info']}`}>
                (Error Code: {this.props.errorInfo.code}, Message: {this.props.errorInfo.message})
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (this.props.totalMatches === 0) {
      return (
        <div className={`${newsStyles['news-container']}`}>
          <div className={`${newsStyles['no-news']}`}>
            <div>
              Oops! We couldn't find related news stories.<br />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={`${newsStyles['news-container']}`}>
        <div className={`${newsStyles['top-news-container']}`}>
          {
            this.state.news && this.state.news.map(
              (item, index) => {
                return item && <NewsCardMobile
                  news={item}
                  cardNumber={index + 1}
                  key={index}
                  onClick={this.loadNewsArticle} />
              }
            )
          }
        </div>
        {
          this.props.totalMatches > this.state.pageSize &&
            <div className={`${newsStyles['show-more-btn-container']}`}>
              {
                this.state.showMore &&
                <button
                  className={`${newsStyles['show-more-btn']}`}
                  onClick={this.expandCollapseNews} id='btnShowMore'>
                  Show More <span className={`${newsStyles['icon']} ${newsStyles['down']}`} />
                </button>
              }
              {
                !this.state.showMore &&
                <button
                  className={`${newsStyles['show-more-btn']}`}
                  onClick={this.expandCollapseNews} id='btnShowMore'>
                  Show Less <span className={`${newsStyles['icon']} ${newsStyles['up']}`} />
                </button>
              }
            </div>
        }

        {
          this.props.totalMatches > this.state.pageSize && !this.state.showMore &&
            <NewsLinkList
              isMobileView
              fetching
              news={[]}
              rowStartNumber={7}
              noRecordsToExclude={6}
              currentPageNumber={this.state.currentPageNumber}
              securityType={this.props.securityType}
              wsodIssue={this.props.wsodIssue}
              onClick={this.loadNewsArticle}
              requestSnapshotV2NewsData={this.props.requestSnapshotV2NewsData} />
        }

        <div className={`${newsStyles['data-as-of-container']}`}>
          {DATE_TIME_CONSTANTS.AS_OF_PREFIX + ' ' + Format.dateTimeFormatter(new Date(), DATE_TIME_CONSTANTS.AS_OF_DATE_FORMAT)}
        </div>
      </div>
    )
  };
}

NewsMobileContainer.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isErrorOccurred: PropTypes.bool.isRequired,
  totalMatches: PropTypes.number.isRequired,
  news: PropTypes.array.isRequired,
  wsodIssue: PropTypes.string.isRequired,
  securityType: PropTypes.string.isRequired,
  setFetchingDataFlag: PropTypes.func.isRequired,
  errorInfo: PropTypes.object.isRequired,
  requestSnapshotV2NewsData: PropTypes.func.isRequired,
  page: PropTypes.string.page,
  location: PropTypes.object
}

function mapStateToProps (state) {
  let obj = {
    fetching: state.snapshotV2Reducer.newsData.fetching,
    isErrorOccurred: !!state.snapshotV2Reducer.newsData.error,
    errorInfo: state.snapshotV2Reducer.newsData.error || {},
    news: [],
    totalMatches: 0
  }
  if (state.snapshotV2Reducer.newsData.data) {
    let totalMatches = state.snapshotV2Reducer.newsData.data.totalMatches

    Object.assign(obj, {
      news: state.snapshotV2Reducer.newsData.data.totalMatches === 0
        ? [] : state.snapshotV2Reducer.newsData.data.news,
      totalMatches: totalMatches
    })
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(SnapshotV2Actions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestSnapshotV2NewsData: (securityType, wsodIssue, newsProvider, rowNumber, articleCount, loadImage, imgSize) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2NewsData(securityType, wsodIssue,
        newsProvider, rowNumber, articleCount, loadImage, imgSize))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsMobileContainer)
