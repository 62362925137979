/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'babel-polyfill'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SnapshotStyles from '../../../../styles/snapshot.V2/snapshot.scss'
import Styles from '../../../../styles/snapshot.V2/btnAddToWatchlists.scss'
import * as WatchlistActions from '../../../../actions/watchlist.Actions'
import { ModalPopUp } from 'stifel-intyce-pattern-library'
import { MAX_WATCHLISTS, MAX_SECURITIES, WATCHLIST_MESSAGES } from '../../../../utils/appConstants'
import CreateWatchlist from '../../../watchlist/SubComponents/CreateWatchlist'
import $ from 'jquery'
import commonStyles from '../../../../styles/common.v2/common.scss'

export class AddToWatchlists extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isListOpen: false,
      watchlists: [],
      fetching: false,
      isModalOpen: 0,
      modalComponent: null,
      modalHeight: null,
      numberOfWatchlistsContainingSymbol: 0
    }

    this.openModel = this.openModel.bind(this)
    this.closeModel = this.closeModel.bind(this)
    this.createWatchlist = this.createWatchlist.bind(this)
    this.displayWatchlistsList = this.displayWatchlistsList.bind(this)
    this.addToWatchlistHandler = this.addToWatchlistHandler.bind(this)
    this.onCreateWatchlistSuccessHandler = this.onCreateWatchlistSuccessHandler.bind(this)
  }

  componentDidMount () {
    this.props.requestWatchlists()

    let self = this

    $(document).on('click', function (event) {
      var $elDdlWatchlists = $('#btnAddToWatchlist')

      if ($elDdlWatchlists !== event.target &&
        !$elDdlWatchlists.has(event.target).length &&
        event.target.getAttribute('data-add-icon') !== 'true' &&
        self.state.isListOpen) {
        self.setState({
          isListOpen: false
        })
      }
    })
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.watchlists.isServerResponse) {
      this.setState({
        watchlists: nextProps.watchlists.data
      })

      nextProps.requestWatchlistsHoldings(
        nextProps.watchlists.data.map((item) => {
          return item.id
        })
      )

      nextProps.setFetchingDataFlag({
        watchlistsData_Platform: {
          isServerResponse: false
        }
      })
    }

    // check symbol exists in the watchlists
    if (nextProps.holdings.isServerResponse) {
      let arrWatchlists = this.state.watchlists
      let numberOfWatchlistsContainingSymbol = 0

      Object.keys(nextProps.holdings.items).map((wHolding, index) => {
        if (wHolding.indexOf('_Inputs') === -1) {
          let watchlistId = wHolding.replace('watchlist_', '')
          let arrHoldings = nextProps.holdings.items[wHolding].data.items

          let symbolExists = arrHoldings.filter(function (holding) {
            return holding.xid === Number(nextProps.wsodIssue)
          }).length > 0

          // find watchlist
          let watchlistContainingSymbol = arrWatchlists.filter(function (watchlist) {
            return watchlist.id === watchlistId
          })

          if (symbolExists) {
            numberOfWatchlistsContainingSymbol++
          }

          if (watchlistContainingSymbol.length > 0) {
            watchlistContainingSymbol[0].symbolExists = symbolExists
            watchlistContainingSymbol[0].holdingCount = arrHoldings.length
          }
        }
        return null
      })

      nextProps.setFetchingDataFlag({
        watchlistsHoldings_Platform: {
          isServerResponse: false
        }
      })

      this.setState({
        fetching: false,
        watchlists: arrWatchlists,
        numberOfWatchlistsContainingSymbol: numberOfWatchlistsContainingSymbol
      })
    }

    if (nextProps.symbolsToWatchlist.isServerResponse) {
      nextProps.setFetchingDataFlag({
        symbolsToWatchlist_Platform: {
          isServerResponse: false
        }
      })
      this.props.requestWatchlists()
    }
  }

  onCreateWatchlistSuccessHandler (watchlistId) {
    let xid = this.props.wsodIssue

    if (watchlistId && xid) {
      this.props.requestAddSymbolToWatchlist(watchlistId, Number(xid))

      this.setState({
        fetching: true,
        isListOpen: true
      })
    }
  }

  addToWatchlistHandler (e) {
    if (e) {
      e.preventDefault()
      let el = e.currentTarget
      let xid = this.props.wsodIssue
      let wid = el.getAttribute('data-watchlist-id')
      let holdingsCount = el.getAttribute('data-holdings-count')

      if (holdingsCount && Number(holdingsCount) >= MAX_SECURITIES) {
        this.props.notificationHandler({show: true, message: WATCHLIST_MESSAGES.MAX_SECURITIES})
        return
      }

      if (wid && xid) {
        this.props.requestAddSymbolToWatchlist(wid, Number(xid))

        this.setState({
          fetching: true,
          isListOpen: true
        })
      }
    }
  }

  createWatchlist (e) {
    e && e.preventDefault()

    this.openModel()
    this.setState({
      isListOpen: false,
      modalHeight: '192px',
      modalComponent: <CreateWatchlist
        onCancel={this.closeModel}
        requestWatchlists={() => {}}
        onSuccessHandler={this.onCreateWatchlistSuccessHandler}
        notificationHandler={this.props.notificationHandler} />
    })
  }

  displayWatchlistsList (e) {
    e && e.preventDefault()

    this.setState({
      isListOpen: !this.state.isListOpen
    })
  }

  openModel () {
    this.setState({
      isModalOpen: 1
    })
  }

  closeModel () {
    this.setState({
      isModalOpen: 0
    })
  }

  render () {
    return (
      <div className={`${Styles['add-to-watchlist-container']}`}>
        {
          this.state.isModalOpen === 1 &&
          <ModalPopUp
            height={this.state.modalHeight}
            onClick={() => { }}
            isOpen={this.state.isModalOpen}>
            {this.state.modalComponent}
          </ModalPopUp>
        }
        {
          <ul id='btnAddToWatchlist' tabIndex='-1'
            className={`${Styles['btn-add-to-watchlist']} ${this.state.isListOpen ? Styles['open'] : ''}`}>
            <li onClick={this.displayWatchlistsList}
              className={`${Styles['btn-add-icon-text']} ${Styles['no-wrap']}`}>
              <span className={`${Styles['btn-add-icon']} ${SnapshotStyles['sprites-img']}`} />
              <span className={`${Styles['btn-add-icon-text']}`}>
                <span>
                  Watchlist {this.state.numberOfWatchlistsContainingSymbol > 0 && (
                    '(' + this.state.numberOfWatchlistsContainingSymbol + ')'
                  )}
                </span>
              </span>
            </li>
            <li onClick={this.displayWatchlistsList}>
              Add to Watchlist
            </li>
            {
              this.state.fetching && <li>
                <div className={`${commonStyles['loader']} ${Styles['loader']}`}>
                  <div className={`${commonStyles['rollingloader']}`} />
                </div>
              </li>
            }
            {
              !this.state.fetching && this.state.watchlists.map((item, index) => (
                <li
                  key={index}
                  className={`${item.symbolExists ? Styles['disabled'] : ''}`}>
                  <span className={`${Styles['watchlist-name']}`}>
                    {item.name}&nbsp;
                    {
                      item.symbolExists && <span>
                        ({this.props.symbol} Included)
                      </span>
                    }
                  </span>
                  {
                    !item.symbolExists && <span
                      onClick={this.addToWatchlistHandler}
                      data-watchlist-id={item.id}
                      data-add-icon='true'
                      data-holdings-count={item.holdingCount}
                      data-el-identifier='add-to-watchlist_add'
                      className={`${Styles['btn-add-icon']} ${SnapshotStyles['sprites-img']}`} />
                  }
                </li>
              ))
            }
            {
              !this.state.fetching && <li
                className={`${this.state.watchlists.length >= MAX_WATCHLISTS ? Styles['disabled'] : Styles['create-new-watchlist']}`}
                onClick={this.state.watchlists.length < MAX_WATCHLISTS ? this.createWatchlist : () => {}}>
                <span className={`${Styles['add-icon']}`}>+</span>
                <span data-el-identifier='add-to-watchlist_create'>Create New Watchlist</span>
              </li>
            }
          </ul>
        }
      </div>
    )
  };
};

AddToWatchlists.propTypes = {
  watchlists: PropTypes.object,
  wsodIssue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  requestAddSymbolToWatchlist: PropTypes.func,
  requestWatchlists: PropTypes.func,
  requestWatchlistsHoldings: PropTypes.func,
  setFetchingDataFlag: PropTypes.func,
  notificationHandler: PropTypes.func,
  holdings: PropTypes.object,
  symbolsToWatchlist: PropTypes.object,
  symbol: PropTypes.string
}

AddToWatchlists.defaultProps = {
}

function mapStateToProps (state, ownProps) {
  let obj = {
    watchlists: {
      data: [],
      isServerResponse: false
    },
    watchlistId: null,
    holdings: {
      items: [],
      isServerResponse: false
    },
    symbolsToWatchlist: {
      holdings: [],
      isServerResponse: false
    }
  }

  if (state.watchlistReducer.watchlistsData_Platform.isServerResponse) {
    let arrWatchlists = state.watchlistReducer.watchlistsData_Platform.data.watchlists

    obj.watchlists = {
      data: arrWatchlists,
      isServerResponse: true
    }
  }

  if (state.watchlistReducer.watchlistsHoldings_Platform.isServerResponse) {
    obj.holdings = {
      items: state.watchlistReducer.watchlistsHoldings_Platform.data.holdings,
      isServerResponse: true
    }
  }

  if (state.watchlistReducer.symbolsToWatchlist_Platform.isServerResponse) {
    obj.symbolsToWatchlist = {
      holdings: state.watchlistReducer.symbolsToWatchlist_Platform.data.holdings,
      isServerResponse: true
    }
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(WatchlistActions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestWatchlists: () => {
      return dispatch(WatchlistActions.requestWatchlists())
    },
    requestWatchlistsHoldings: (watchlistsIds) => {
      return dispatch(WatchlistActions.requestWatchlistsHoldings(watchlistsIds, true))
    },
    requestAddSymbolToWatchlist: (watchlistId, xid) => {
      return dispatch(WatchlistActions.requestAddSymbolToWatchlist(watchlistId, xid))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToWatchlists)
