import Axios from 'axios'

const AxiosWrapper = Axios.create()

AxiosWrapper.interceptors.response.use(response => {
  const showDebugInfo = localStorage.getItem('showDebugInfo')

  if (!response || !showDebugInfo || showDebugInfo !== 'true') {
    return response
  }

  // Remove Debug.url statement from API response (Debug URL set by Caddy response)
  response.data = response.data.replace('Debug.url = "/stifel-intyce/1.0/showdebuginfo/index.html?v=3.0.6";', '')

  // API responses with debug data contain scripts that must be executed (showdebuginfo)
  const o = null
  eval('o = ' + response.data) // eslint-disable-line no-eval
  response.data = o

  return response
}, error => {
  return Promise.reject(error)
})

AxiosWrapper.CancelToken = Axios.CancelToken
AxiosWrapper.isCancel = Axios.isCancel

export default AxiosWrapper
