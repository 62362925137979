/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import HoldingsTableStyles from '../../../../../styles/watchlist/mobile/holdingsTable.scss'
import WatchlistsStyles from '../../../../../styles/watchlist/watchlists.scss'
import { Format } from 'stifel-intyce-pattern-library'
import { mapIndicesSymbol } from '../../../../common.v2/CommonMethods'
import { SHOW_DIVIDEND_DATA } from '../../../../../utils/appConstants'

const HoldingsFundamentalsTable = props => {
  if (props.holdings.length === 0) {
    return (
      <div className={`${HoldingsTableStyles['tbl-holdings']}`}>
        <div className={`${HoldingsTableStyles['no-data']}`}>
          Add securities to get started.
        </div>
      </div>
    )
  }

  function renderSortIcon (sortBy) {
    return props.sortBy.columnName === sortBy &&
    <span className={`${WatchlistsStyles[ 'arrow-' + props.sortBy.order ]}`} />
  }

  return (
    <div className={`${HoldingsTableStyles['tbl-holdings']} ${HoldingsTableStyles['tbl-fundamentals']}`}>
      <div className={`${HoldingsTableStyles['header']} ${HoldingsTableStyles['header-1']}
      ${HoldingsTableStyles['row']}`} data-sort-by={props.sortBy.columnName}>

        <div className={`${HoldingsTableStyles['symbol']}`}>
          { renderSortIcon('symbol') }
          <span
            data-col-name='symbol'
            data-col-type='String'
            onClick={props.onColHeaderClick}
            className={`${HoldingsTableStyles['col-header']}`}>
            Symbol
          </span>
          <br />
          { renderSortIcon('name') }
          <span
            data-col-name='name'
            data-col-type='String'
            onClick={props.onColHeaderClick}
            className={`${HoldingsTableStyles['col-header']}`}>
            Name
          </span>
        </div>

        <div className={`${HoldingsTableStyles['ratioPE']}`}>
          { renderSortIcon('earningsEstimate12M') }
          <span
            data-col-name='earningsEstimate12M'
            data-col-type='Number'
            onClick={props.onColHeaderClick}
            className={`${HoldingsTableStyles['col-header']}`}>
            EPS
          </span>
          <br />
          { renderSortIcon('ratioPE') }
          <span
            data-col-name='ratioPE'
            data-col-type='Number'
            onClick={props.onColHeaderClick}
            className={`${HoldingsTableStyles['col-header']}`}>
            Price/Earnings
          </span>
        </div>

        {
          SHOW_DIVIDEND_DATA &&
          <div className={`${HoldingsTableStyles['dividend']}`}>
            { renderSortIcon('dividendAmountGross') }
            <span
              data-col-name='dividendAmountGross'
              data-col-type='Number'
              onClick={props.onColHeaderClick}
              className={`${HoldingsTableStyles['col-header']}`}>
              Dividend
            </span>
            <br />
            { renderSortIcon('dividendYieldGross') }
            <span
              data-col-name='dividendYieldGross'
              data-col-type='Number'
              onClick={props.onColHeaderClick}
              className={`${HoldingsTableStyles['col-header']}`}>
              Dividend Yield
            </span>
          </div>
        }
      </div>

      <div data-symbols-container='true'>
        {
          props.holdings.map((item, index) => (
            <div className={`${HoldingsTableStyles['row']} ${HoldingsTableStyles['data-row']}`}
              key={item.xid} data-holding-row={item.holdingId}>
              <div className={`${HoldingsTableStyles['symbol']}`}>
                <div className={`${HoldingsTableStyles['name']}`}
                  data-wsod-issue={item.xid} data-el-identifier='holding-fundamental-symbol_value'
                  data-symbol={mapIndicesSymbol(item.xid, item.symbol)}
                  data-return-url={'/Navigator/Watchlist/' + props.watchlistInfo.id + '/' + props.watchlistInfo.name + '?tab=Fundamentals'}
                  onClick={props.onSymbolClick}>
                  { mapIndicesSymbol(item.xid, item.symbol) }
                  <br />
                  <div className={`${HoldingsTableStyles['company']}`} title={item.name}>
                    { item.name }
                  </div>
                </div>
              </div>

              <div className={`${HoldingsTableStyles['ratioPE']}`}>
                { Format.formatNumber(item.earningsEstimate12M,
                  {currencyPreFix: true, negSign: true, precision: 3}) }
                <br />
                { Format.formatNumber(item.ratioPE, {negSign: true}) }
              </div>

              <div className={`${HoldingsTableStyles['dividend']}`}>
                { /* This may need to be changed to the abreviateNumber function depending on how big these numbers get, will know more once data is pulled */ }
                { Format.formatNumber(item.dividendAmountGross, {currencyPreFix: true})}
                <br />
                { Format.formatNumber(item.dividendYieldGross, {postFix: true}) }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

HoldingsFundamentalsTable.propTypes = {
  holdings: PropTypes.array,
  onSymbolClick: PropTypes.func,
  onColHeaderClick: PropTypes.func,
  sortBy: PropTypes.object,
  watchlistInfo: PropTypes.object
}

export default HoldingsFundamentalsTable
