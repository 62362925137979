/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as WatchlistActions from '../../../actions/watchlist.Actions'
import styles from '../../../styles/watchlist/createWatchlist.scss'
import { WATCHLIST_MESSAGES } from '../../../utils/appConstants'

class CreateWatchlist extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      saveLabel: 'Save',
      disable: false,
      alreadyExists: false,
      genericError: false
    }
    this.onSave = this.onSave.bind(this)
    this.disableSave = this.disableSave.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.resetSave = this.resetSave.bind(this)
    this.showError = this.showError.bind(this)
  }
  componentDidMount () {
    if (this.props.watchlistName) {
      this.setState({name: this.props.watchlistName})
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.error && nextProps.error.message) {
      this.resetSave()
      this.showError(nextProps.error.message)
    }
    if (nextProps.response) {
      this.props.onSuccessHandler(nextProps.response)
      this.props.onCancel()
    }
  }

  componentWillUnmount () {
    this.props.resetStore(
      {
        createWatchlistResponse: {
          data: {
            id: null
          },
          error: null
        }
      }
    )
  }

  disableSave () {
    this.setState({
      saveLabel: 'Saving...',
      disable: true
    })
  }

  onSave (e) {
    e && e.preventDefault()
    if (this.state.alreadyExists) {
      this.resetSave()
    }
    if (this.state.genericError) {
      this.props.notificationHandler({show: true})
    }
    let name = this.state.name.trim()
    if (name) {
      this.disableSave()
      if (this.props.watchlistId) {
        this.props.manageWatchlist(
          {
            actionType: 'PUT',
            watchlistId: this.props.watchlistId,
            name: name,
            descption: ''
          }
        )
      } else {
        this.props.manageWatchlist(
          {
            actionType: 'POST',
            watchlistId: '',
            name: name,
            descption: ''
          }
        )
      }
    }
  }

  changeHandler (event) {
    this.setState({
      name: event.target.value,
      alreadyExists: false
    })
  }

  resetSave () {
    this.setState(
      {
        saveLabel: 'Save',
        disable: false
      }
    )
  }

  showError (error) {
    if (error.indexOf('already') !== -1 || error.indexOf('attempting to store the modified container') !== -1) {
      this.setState({alreadyExists: true})
    } else {
      this.setState({genericError: true})
      if (error.indexOf('invalid name length') !== -1) {
        this.props.notificationHandler({show: true, message: error})
      } else {
        this.props.notificationHandler({show: true})
      }
    }
  }

  render () {
    return (
      <div className={`${styles['create-watchlist-container']}`}>
        <div className={`${styles['create-watchlist-header']}`}>
          <span>{this.props.watchlistId ? 'Edit Watchlist Name' : 'Name Watchlist'}</span>
        </div>
        <div className={`${styles['create-watchlist-input']}`}>
          <form onSubmit={this.onSave}>
            <input autoFocus autoComplete='off'
              type='text' data-el-identifier='create-watchlist_name'
              placeholder='Enter Name (max 50 characters)'
              maxLength='50'
              onChange={this.changeHandler}
              value={this.state.name}
            />
            { this.state.alreadyExists &&
              <div className={styles['already-exists']}>
                <span>{`"${this.state.name}"`} {WATCHLIST_MESSAGES.DUPLICATE}</span>
              </div>
            }
          </form>
        </div>
        <div className={`${styles['create-watchlist-footer']}`}>
          <button onClick={this.props.onCancel}>Cancel</button>
          <button data-el-identifier='create-watchlist_save'
            disabled={this.state.disable} onClick={this.onSave}>{this.state.saveLabel}</button>
        </div>
      </div>
    )
  }
}

CreateWatchlist.propTypes = {
  watchlistName: PropTypes.string,
  watchlistId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
  manageWatchlist: PropTypes.func,
  resetStore: PropTypes.func,
  onSuccessHandler: PropTypes.func,
  response: PropTypes.string,
  error: PropTypes.object
}

function mapStateToProps (state) {
  return {
    response: state.watchlistReducer.manageWatchlistResponse.data.id,
    error: state.watchlistReducer.manageWatchlistResponse.error
  }
}

function mapDispatchToProps (dispatch) {
  return {
    manageWatchlist: (data) => {
      return dispatch(WatchlistActions.manageWatchlist(data))
    },
    resetStore: (stateObj) => {
      return dispatch(WatchlistActions.setFetchingDataFlag(stateObj))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWatchlist)
