import React from 'react'
import PropTypes from 'prop-types'
import CompanySearch from '../../../../common.v2/header/headerSubComponents/Container'
import searchSymbolStyles from '../../../../../styles/watchlist/mobile/SearchSymbolPage.scss'
import WatchlistsStyles from '../../../../../styles/watchlist/watchlists.scss'

export default class SearchSymbolPage extends React.Component {
  render () {
    return (
      <div className={`${searchSymbolStyles['search-symbol-page-container']}`}>
        <CompanySearch
          placeHolderText='Search symbol'
          searchForWatchlist
          isMobileWatchlist
          onClick={this.props.addToWatchlist} />
        <div className={searchSymbolStyles['close-btn']}>
          <button data-el-identifier='search-mobile_close' onClick={this.props.closeSearchSymbol} className={`${WatchlistsStyles['button']} ${WatchlistsStyles['btn-wdt']} ${WatchlistsStyles['blue']}`}>Close</button>
        </div>
      </div>
    )
  }
}

SearchSymbolPage.propTypes = {
  closeSearchSymbol: PropTypes.func.isRequired,
  addToWatchlist: PropTypes.func.isRequired
}
