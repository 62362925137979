/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import WatchlistOverviewStyles from '../../../../styles/watchlist/watchlistOverview.scss'

const EmptyWatchlistCard = props => {
  return (
    <div className={WatchlistOverviewStyles['empty-list']}>
      <div className={WatchlistOverviewStyles['empty-list-text']}>
        This list is empty.
      </div>
      <div data-el-identifier='empty-watchlist_add-security' className={WatchlistOverviewStyles['add-security-link']}
        onClick={props.handleWatchlistClickEvent}
        onKeyPress={props.handleWatchlistClickEventEnter} tabIndex='0'>
        Add securities to get started
      </div>
    </div>
  )
}

EmptyWatchlistCard.propTypes = {
  handleWatchlistClickEvent: PropTypes.func,
  handleWatchlistClickEventEnter: PropTypes.func
}

export default EmptyWatchlistCard
