import React from 'react'
import PropTypes from 'prop-types'
import marketsNewsStyles from '../../../styles/markets.V2/news/news.scss'
import { NEWS_CATEGORIES } from '../../../utils/appConstants'
import MarketNews from './MarketsNews'
import MarketNewsMobile from './MarketsNewsMobile'
import { Tabs } from 'stifel-intyce-pattern-library'

export default class MarketsNewsContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
    this.onTabClick = this.onTabClick.bind(this)
    this.getNewsCategory = this.getNewsCategory.bind(this)
  }

  componentDidMount () {
  }

  onTabClick () {
    sessionStorage.removeItem('markets-v2-news-page-no')
    sessionStorage.removeItem('markets-v2-news-category')
    sessionStorage.removeItem('markets-v2-expand-collapse')
  }

  getNewsCategory () {
    let category = sessionStorage.getItem('markets-v2-news-category') || 'Top News'

    return category === 'US' ? 'U.S.' : category
  }

  render () {
    return (
      <div className={`${marketsNewsStyles['markets-news-parent-container']}`}>
        <div className={`${marketsNewsStyles['tabs-container']}`}>
          {
            <Tabs onClick={this.onTabClick} activeTab={this.getNewsCategory()}>
              {
                NEWS_CATEGORIES.map((item, index) => (
                  <div label={item.Key} key={item}>
                    <div className={`${marketsNewsStyles['tab']}`}>
                      {
                        this.props.isMobileView &&
                        <MarketNewsMobile newsCategory={item.Value} />
                      }
                      {
                        !this.props.isMobileView &&
                        <MarketNews newsCategory={item.Value} />
                      }
                    </div>
                  </div>
                ))
              }
            </Tabs>
          }
        </div>
      </div>
    )
  };
};

MarketsNewsContainer.propTypes = {
  isMobileView: PropTypes.bool.isRequired
}
