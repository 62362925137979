/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as WatchlistActions from '../../../../actions/watchlist.Actions'
import styles from '../../../../styles/watchlist/deleteWatchlist.scss'
import { WATCHLIST_MESSAGES } from '../../../../utils/appConstants'

class DeleteWatchlist extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      deleteLabel: 'Delete',
      disable: false
    }
    this.onDelete = this.onDelete.bind(this)
    this.resetDelete = this.resetDelete.bind(this)
    this.disableDelete = this.disableDelete.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.error && nextProps.error.message) {
      this.resetDelete()
      this.props.notificationHandler({show: true})
    }
    if (nextProps.response) {
      this.props.requestWatchlists()
      this.props.onCancel()
    }
  }

  disableDelete () {
    this.setState({
      deleteLabel: 'Deleting...',
      disable: true
    })
  }

  onDelete (e) {
    e && e.preventDefault()
    this.disableDelete()
    let data = {actionType: 'DELETE', watchlistId: this.props.watchlistId}
    this.props.deleteWatchlist(data)
  }

  resetDelete () {
    this.setState(
      {
        deleteLabel: 'Delete',
        disable: false
      }
    )
  }

  render () {
    return (
      <div className={`${styles['delete-watchlist-container']}`}>
        <div className={`${styles['delete-watchlist-header']}`}>
          <span>{WATCHLIST_MESSAGES.DELETE_WATCHLIST_CONFIRMATION(this.props.watchlistName)}</span>
        </div>
        <div className={`${styles['delete-watchlist-footer']}`}>
          <form onSubmit={this.onDelete}>
            <button onClick={this.props.onCancel}>Cancel</button>
            <button autoFocus type='submit' disabled={this.state.disable}>{this.state.deleteLabel}</button>
          </form>
        </div>
      </div>
    )
  }
}

DeleteWatchlist.propTypes = {
  watchlistName: PropTypes.string.isRequired,
  watchlistId: PropTypes.string.isRequired,
  requestWatchlists: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
  deleteWatchlist: PropTypes.func,
  response: PropTypes.string,
  error: PropTypes.object
}

function mapStateToProps (state) {
  return {
    response: state.watchlistReducer.manageWatchlistResponse.data.id,
    error: state.watchlistReducer.manageWatchlistResponse.error
  }
}

function mapDispatchToProps (dispatch) {
  return {
    deleteWatchlist: (data) => {
      return dispatch(WatchlistActions.manageWatchlist(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteWatchlist)
