import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import snapshotNewsArticleStyles from '../../../../styles/snapshot.V2/news/snapshotNewsArticle.scss'
import NewsArticle from './NewsArticle'
import { ClearSpecificKeysFromLocalStorage } from '../../../common.v2/CommonMethods'
import { SNAPSHOT_NEWS_CONSTANTS } from '../../../../utils/appConstants'

export default class NewsArticlePageContainer extends React.Component {
  constructor (props) {
    super(props)

    // viewType: 1-iPad, 2-Mobile
    if (props.params.viewType && props.params.viewType === '2') {
      this.state = {
        articleId: null,
        backButtonText: 'Back to Snapshot'
      }
    } else {
      this.state = {
        articleId: null,
        backButtonText: 'Back to Top News'
      }
    }

    this.goBackHandler = this.goBackHandler.bind(this)
  }

  componentDidMount () {
    this.setState({
      articleId: this.props.articleId ? this.props.articleId : this.props.params.articleId
    })
  }

  goBackHandler () {
    ClearSpecificKeysFromLocalStorage(this.getPageOnNews(), [SNAPSHOT_NEWS_CONSTANTS])
    browserHistory.goBack()
  }
  getPageOnNews () {
    return this.props.page ? this.props.page : ''
  }
  render () {
    return (
      <div className={`${snapshotNewsArticleStyles['news-article-page-container']} ${snapshotNewsArticleStyles['news-article-parent-container']}`}>
        <div className={`${snapshotNewsArticleStyles['header-container']}`}>
          <button className={`${snapshotNewsArticleStyles['back-btn']}`} onClick={this.goBackHandler}>
            <span className={`${snapshotNewsArticleStyles['icon-back-arrow']}`} /> {this.state.backButtonText}
          </button>
        </div>

        {this.state.articleId && <NewsArticle isMobileView articleId={this.state.articleId} />}
      </div>
    )
  };
};

NewsArticlePageContainer.propTypes = {
  articleId: PropTypes.string.isRequired,
  params: PropTypes.object,
  page: PropTypes.string
}
