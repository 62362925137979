import { connect } from 'react-redux'
import Watchlists from './Watchlists'
import * as WatchlistActions from '../../actions/watchlist.Actions'

function mapStateToProps (state, ownProps) {
  let obj = {
    fetching: state.watchlistReducer.watchlistsData_Platform.fetching,
    isErrorOccurred: !!state.watchlistReducer.watchlistsData_Platform.error,
    errorInfo: state.watchlistReducer.watchlistsData_Platform.error || {},
    watchlists: []
  }

  if (state.watchlistReducer.multipleAPICallsResponse &&
    state.watchlistReducer.multipleAPICallsResponse.data) {
    obj.deleteWatchlistsResponse = state.watchlistReducer.multipleAPICallsResponse.data
  }

  if (state.watchlistReducer.watchlistsData_Platform.data &&
    state.watchlistReducer.watchlistsData_Platform.data.watchlists.length > 0) {
    Object.assign(obj, {
      watchlists: state.watchlistReducer.watchlistsData_Platform.data.watchlists
    })
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(WatchlistActions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestWatchlists: () => {
      return dispatch(WatchlistActions.requestWatchlists())
    },
    requestMultipleAPICalls: (apiInputs) => {
      return dispatch(WatchlistActions.requestMultipleAPICalls(apiInputs))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Watchlists)
