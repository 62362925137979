/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-onchange */
import React from 'react'
import PropTypes from 'prop-types'
import marketsStyles from '../../../styles/markets.V2/markets.scss'
import marketMoversStyles from '../../../styles/markets.V2/marketMovers/marketMovers.scss'
import { Format, ArrowV2 } from 'stifel-intyce-pattern-library'
import commonStyles from '../../../styles/common.v2/common.scss'

export default class MarketMoversMobile extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      exchange: 'NYSE',
      rankingType: 'MostActive',
      rowCount: 5,
      fetching: true
    }

    this.loadMarketMoversData = this.loadMarketMoversData.bind(this)
    this.handleExchangeSelectionChange = this.handleExchangeSelectionChange.bind(this)
  }

  componentDidMount () {
    this.loadMarketMoversData()
  }

  handleExchangeSelectionChange (e) {
    e.preventDefault()

    this.setState({
      fetching: true,
      exchange: e.currentTarget.getAttribute('data-filter-type') === 'exchange'
        ? e.currentTarget.getAttribute('data-value') : this.state.exchange,
      rankingType: e.currentTarget.getAttribute('data-filter-type') === 'rank'
        ? e.currentTarget.getAttribute('data-value') : this.state.rankingType
    }, () => {
      this.loadMarketMoversData()
    })
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      fetching: false
    })
  }

  loadMarketMoversData () {
    this.props.requestMarketsV2MoversData(this.state.exchange, this.state.rankingType, this.state.rowCount)
  }

  render () {
    return (
      <div className={`${marketMoversStyles['market-movers-parent-container']}`}>
        <div className={`${marketMoversStyles['top-header-container']}`}>
          <h2>Company Movers</h2>
          <div className={`${marketMoversStyles['exchange-container']}`}>
            <span
              className={`${marketMoversStyles['exchange']} ${this.state.exchange === 'NYSE' ? marketMoversStyles['active'] : ''}`}
              data-filter-type='exchange' data-value='NYSE'
              onClick={this.handleExchangeSelectionChange}>NYSE</span>

            <span className={`${marketMoversStyles['divider']}`} />

            <span className={`${marketMoversStyles['exchange']} ${this.state.exchange === 'NASDAQ' ? marketMoversStyles['active'] : ''}`}
              data-filter-type='exchange' data-value='NASDAQ'
              onClick={this.handleExchangeSelectionChange}>NASDAQ</span>
          </div>
        </div>

        <div className={`${marketMoversStyles['rank-type-container']}`}>
          <ul>
            <li
              data-value='MostActive' data-filter-type='rank'
              onClick={this.handleExchangeSelectionChange}
              className={`${this.state.rankingType === 'MostActive' ? marketMoversStyles['active'] : ''}`}>
              Most Actives
            </li>
            <li
              data-value='Gainers' data-filter-type='rank'
              onClick={this.handleExchangeSelectionChange}
              className={`${this.state.rankingType === 'Gainers' ? marketMoversStyles['active'] : ''}`}>
              Gainers
            </li>
            <li
              data-value='Losers' data-filter-type='rank'
              onClick={this.handleExchangeSelectionChange}
              className={`${this.state.rankingType === 'Losers' ? marketMoversStyles['active'] : ''}`}>
              Losers
            </li>
          </ul>
        </div>

        <div className={`${marketMoversStyles['market-movers-container']}`}>
          <div className={`${marketMoversStyles['market-movers']}`}>
            <div className={`${marketMoversStyles['tbl-market-movers-container']}`}>
              {
                // Fetching records
                this.state.fetching &&
                  <div className={`${commonStyles['loader']} ${marketsStyles['loader-no-border']}`}>
                    <div className={`${commonStyles['rollingloader']}`} />
                  </div>
              }

              {
                // Error occurred
                !this.state.fetching && this.props.isErrorOccurred &&
                  <div className={`${marketMoversStyles['error-container']}`}>
                    Oops! There was an error processing your request. Please try again.<br />
                  </div>
              }

              {
                !this.state.fetching && this.props.allRankData.length === 0 &&
                <div className={`${marketMoversStyles['tbl-market-movers']}`}>
                  <div className={`${marketMoversStyles['no-data']}`}>
                    Oops! We couldn't find related data.
                  </div>
                </div>
              }

              {
                !this.state.fetching && this.props.allRankData.length !== 0 &&
                <div className={`${marketMoversStyles['tbl-market-movers']}`}>
                  <div className={`${marketMoversStyles['row']} ${marketMoversStyles['header']}`}>
                    <div className={`${marketMoversStyles['symbol']}`}>
                      Symbol / Name
                    </div>
                    <div className={`${marketMoversStyles['last']}`}>
                      Last
                    </div>
                    <div className={`${marketMoversStyles['change']} ${this.state.rankingType !== 'MostActive' ? marketMoversStyles['large-Size-Bold'] : ''} `}>
                      Change
                    </div>
                  </div>
                  {
                    this.props.allRankData.map((item, index) => (
                      <div key={index}>
                        <div className={`${marketMoversStyles['row']}`}>
                          <div className={`${marketMoversStyles['symbol']}`}
                            data-wsod-issue={item.xid}
                            data-symbol={item.symbol} data-el-identifier='market-movers-symbol_value'
                            onClick={this.props.openSnapShot}>
                            {item.symbol}
                          </div>
                          <div className={`${marketMoversStyles['last']}`}>
                            { Format.formatNumber(item.last, {currencyPreFix: true}) }
                          </div>
                          <div className={`${marketMoversStyles['change']}`}>
                            <div className={`${marketMoversStyles['price-percent']}`}>
                              <div className={`${marketMoversStyles['price-percent']}`}>
                                <div>
                                  { Format.formatNumber(item.priceChange, {currencyPreFix: true, negSign: true, color: true}) }
                                </div>
                                <div>
                                  { Format.formatNumber(item.priceChangePercent, {postFix: true, negSign: true, color: true, parentheses: false}) }
                                </div>
                              </div>
                              <div className={`${marketMoversStyles['icon']}`}>
                                { (Format.formatNumber(item.priceChange) !== '--' ||
                                Format.formatNumber(item.priceChangePercent) !== '--')
                                  ? <ArrowV2 value={item.priceChange} /> : '' }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`${marketMoversStyles['company']}`}>
                          {item.company}
                        </div>
                      </div>
                    ))
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  };
};

MarketMoversMobile.propTypes = {
  requestMarketsV2MoversData: PropTypes.func.isRequired,
  isErrorOccurred: PropTypes.bool.isRequired,
  allRankData: PropTypes.array.isRequired,
  openSnapShot: PropTypes.func
}
