import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import overviewETFStyles from '../../../../styles/snapshot.V2/overviewETF.scss'
import * as SnapshotV2Actions from '../../../../actions/snapshot.V2.Actions'
import { RenderTextWithHeading, Format } from 'stifel-intyce-pattern-library'
import Chart from './Chart'
import { DATE_TIME_CONSTANTS, ISSUE_TYPES } from '../../../../utils/appConstants'
import commonStyles from '../../../../styles/common.v2/common.scss'

class OverviewETF extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      wsodIssue: null
    }
  }

  componentDidMount () {
    // eslint-disable-next-line react/prop-types
    let wsodIssue = this.props.wsodIssue ? this.props.wsodIssue : this.props.params.wsodissue

    this.setState({
      wsodIssue
    }, () => {
      this.props.requestSnapshotV2ETFOverviewData(this.state.wsodIssue, this.props.symbolXREFData ? this.props.symbolXREFData.xids : '')
    })
  }

  render () {
    if (this.props.fetching) {
      return (
        <div className={`${overviewETFStyles['etf-overview-container']}`}>
          <div className={`${commonStyles['loader']}`}>
            <div className={`${commonStyles['rollingloader']} ${overviewETFStyles['etf-loader']}`} />
          </div>
        </div>
      )
    }

    return (
      <div className={`${overviewETFStyles['etf-overview-container']}`}>
        {this.props.fundObjective &&
          <div className={`${overviewETFStyles['fund-objective-container']}`}>
            <RenderTextWithHeading
              heading='Fund Objective'
              content={this.props.fundObjective} />
          </div>
        }
        <div className={`${overviewETFStyles['chart-view']}`}>
          <Chart showHighLow isMobileView={this.props.isMobileView} handleResize={this.props.handleResize} symbol={this.props.symbol}
            dividendHistory={this.props.dividendHistory} splitHistory={this.props.splitHistory} wsodIssue={this.props.wsodIssue} issueType={ISSUE_TYPES.Etf} />
        </div>
        <div className={`${overviewETFStyles['bottom-container']}`}>
          <span data-el-identifier='overview-etf-date_footer' className={`${overviewETFStyles['date']}`}>
            {Format.getFormattedDateTime(this.props.dateAsOf, {preFix: DATE_TIME_CONSTANTS.AS_OF_PREFIX, tzConversion: true})}
          </span>
        </div>
      </div>
    )
  };
};

OverviewETF.propTypes = {
  fetching: PropTypes.bool.isRequired,
  wsodIssue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  symbol: PropTypes.string.isRequired,
  fundObjective: PropTypes.string.isRequired,
  requestSnapshotV2ETFOverviewData: PropTypes.func.isRequired,
  handleResize: PropTypes.func,
  isMobileView: PropTypes.bool,
  symbolXREFData: PropTypes.object,
  dividendHistory: PropTypes.object,
  splitHistory: PropTypes.object,
  dateAsOf: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  return {
    fetching: state.snapshotV2Reducer.etfOverviewData.data.fetching,
    fundObjective: state.snapshotV2Reducer.etfOverviewData.data.fundObjective,
    symbolXREFData: state.snapshotV2Reducer.symbolXREFData_Platform,
    dividendHistory: state.snapshotV2Reducer.equityDividend_History ? state.snapshotV2Reducer.equityDividend_History?.dividend : null,
    splitHistory: state.snapshotV2Reducer.equitySplit_History ? state.snapshotV2Reducer.equitySplit_History?.dividend : null
  }
}

function mapDispatchToProps (dispatch) {
  return {
    requestSnapshotV2ETFOverviewData: (wsodIssue, instrumentXIDs) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2ETFOverviewData(wsodIssue, instrumentXIDs))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewETF)
