/* eslint-disable */
import { connect } from 'react-redux'
import React from 'react'
import {
  SnapshotOptions
} from 'stifel-intyce-pattern-library'
import * as SnapshotV2Actions from '../../../../actions/snapshot.V2.Actions'
import marketStyles from '../../../../styles/markets.scss'
import commonStyles from '../../../../styles/common.v2/common.scss'

class SnapshotOptionsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      option: 'all',
      selectedDate: '--',
    }

    this.props.setFetchingDataFlag({
      optionChainExpirationDates_Platform: {
        data: {
          fetching: true
        }
      }
    })

    this.props.setFetchingDataFlag({
      optionChain_Platform: {
        data: {
          fetching: true
        }
      }
    })

    this.loadOptionChainData = this.loadOptionChainData.bind(this)
    this.filterOptionChainData = this.filterOptionChainData.bind(this)
  }

  componentDidMount() {
    this.props.requestSnapshotV2OptionChainExpirationDatesData(this.props.wsodIssue)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fetchOptionChainData) {
      let topExpDate = this.props.optionChainExpirationDates_Platform.data.topExpDate

      this.setState({
        selectedDate: topExpDate
      }, () => {
        this.props.requestSnapshotV2OptionChainData(this.props.wsodIssue, topExpDate)
      })
    }
    this.handleResize()
  }

  loadOptionChainData(e) {
    e.preventDefault()
    let selectedDate = e.currentTarget.options[e.currentTarget.selectedIndex].value

    this.props.setFetchingDataFlag({
      optionChain_Platform: {
        data: {
          fetching: true
        }
      }
    });

    this.setState({
      selectedDate: selectedDate
    }, () => {
      this.props.requestSnapshotV2OptionChainData(this.props.wsodIssue, selectedDate)
    })
  }

  filterOptionChainData(e) {
    e.preventDefault()
    this.setState({
      option: e.currentTarget.options[e.currentTarget.selectedIndex].value
    })
  }

  handleResize() {
    const tabCon = document.getElementById('optionContentContainer')
    const snapshotPopUpCotentContainer = document.getElementById('snapshotPopUpCotentContainer');
    if (tabCon != null && snapshotPopUpCotentContainer != null) {
      if(this.props && !this.props.isMobileView) {
        // tabCon.style.height = '38%'--worked for 1080 height
        tabCon.style.height = snapshotPopUpCotentContainer.offsetHeight - 333 + 'px'
      } else {
        tabCon.style.height = snapshotPopUpCotentContainer.offsetHeight - 393 + 'px'
      }
    }
  }

  render() {
    if (this.props.fetchingDates) {
      return (
        <div className={`${commonStyles['loader']}`}>
          <div className={`${commonStyles['rollingloader']}`}></div>
        </div>
      )
    }
    if (this.props.optionChainExpirationDates_Platform.data && this.props.optionChainExpirationDates_Platform.data.dates.length == 0) {
      return (
        <div className={`${marketStyles['no-data']}`}>
          <span>
            Oops! We couldn't find related options.
        </span>
        </div>
      )
    }
    return (
      <SnapshotOptions option={this.state.option}
        fetchingDates={this.props.fetchingDates}
        fetchingOptionChainData={this.props.fetchingOptionChainData}
        expirationDates={this.props.optionChainExpirationDates_Platform.data && this.props.optionChainExpirationDates_Platform.data.dates}
        optionChainData={this.props.optionChain_Platform ?
          this.props.optionChain_Platform.data ?
            this.props.optionChain_Platform.data.items[0] : {} : {}}
        lastPrice={this.props.lastPrice}
        onExpirationDateChange={this.loadOptionChainData}
        onMoneyChange={this.filterOptionChainData}
        selectedDate={this.state.selectedDate}
        isMobileView={this.props.isMobileView} />
    )
  }
}

function mapStateToProps(state) {
  return {
    fetchOptionChainData: state.snapshotV2Reducer.fetchOptionChainData,
    fetchingDates: state.snapshotV2Reducer.optionChainExpirationDates_Platform.data.fetching,
    fetchingOptionChainData: state.snapshotV2Reducer.optionChain_Platform.data.fetching,
    optionChain_Platform: state.snapshotV2Reducer.optionChain_Platform.data.optionChain,
    optionChainExpirationDates_Platform: state.snapshotV2Reducer.optionChainExpirationDates_Platform
  }
}

function mapDispatchToProps(dispatch) {
  return {
    requestSnapshotV2OptionChainExpirationDatesData: (wsodIssue) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2OptionChainExpirationDatesData(wsodIssue))
    },
    requestSnapshotV2OptionChainData: (wsodIssue, expirationDate) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2OptionChainData(wsodIssue, expirationDate))
    },
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(SnapshotV2Actions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotOptionsContainer)
