import { createTypes } from 'reduxsauce'

export default createTypes(`
    SET_ACCESS_TOKEN
    GENERIC_FAILURE
    API_REQUEST_SYMBOLSEARCH_API
    API_REQUEST_DECODE_API
    RECIEVE_SYMBOLSEARCH
    RECIEVE_DECODE
    SET_FETCHING_DATA_FLAG
    GET_SNAPSHOT_V2_DATA_API
    GET_SNAPSHOT_V2_DATA_API_SUCCESS
    GET_SNAPSHOT_V2_FUND_INDICATOR_DATA_API
    GET_SNAPSHOT_V2_OPTION_CHAIN_DATA_API
    GET_SNAPSHOT_V2_OPTION_CHAIN_DATA_API_SUCCESS
    GET_SNAPSHOT_V2_OPTION_CHAIN_EXPIRATION_DATES_DATA_API
    GET_SNAPSHOT_V2_OPTION_CHAIN_EXPIRATION_DATES_DATA_API_SUCCESS
    GET_SNAPSHOT_V2_MF_OVERVIEW_DATA_API
    GET_SNAPSHOT_V2_MF_OVERVIEW_DATA_API_SUCCESS
    GET_SNAPSHOT_V2_ETF_OVERVIEW_DATA_API
    GET_SNAPSHOT_V2_ETF_OVERVIEW_DATA_API_SUCCESS
    GET_SNAPSHOT_V2_10K_GROWTH_CHART_DATA_API
    GET_SNAPSHOT_V2_10K_GROWTH_CHART_DATA_API_SUCCESS
    GET_SNAPSHOT_V2_XID_FROM_SYMBOL_API
    GET_SNAPSHOT_V2_XID_FROM_SYMBOL_API_SUCCESS
    GET_SNAPSHOT_V2_NEWS_API
    GET_SNAPSHOT_V2_NEWS_API_SUCCESS
    GET_SNAPSHOT_V2_GENERALINFO_API
    GET_SNAPSHOT_V2_GENERALINFO_API_SUCCESS
    GET_SNAPSHOT_V2_NEWS_ARTICLE_API
    GET_SNAPSHOT_V2_NEWS_ARTICLE_API_SUCCESS
    GET_SNAPSHOT_V2_NEWS_IMAGE_API
    GET_SNAPSHOT_V2_NEWS_IMAGE_API_SUCCESS
    GET_SNAPSHOT_V2_PERFORMANCE_SUMMARY_API
    GET_SNAPSHOT_V2_PERFORMANCE_SUMMARY_API_SUCCESS
    GET_MARKETS_V2_DATA_API
    GET_MARKETS_V2_DATA_API_SUCCESS
    GET_TREASURYYIELD_API
    GET_TREASURYYIELD_API_SUCCESS
    GET_INDICES_V2_DATA_API
    GET_INDICES_V2_DATA_API_SUCCESS
    GET_MARKETS_V2_MOVERS_DATA_API
    GET_MARKETS_V2_MOVERS_DATA_API_SUCCESS
    GET_WATCHLISTS_DATA_API
    GET_WATCHLISTS_DATA_API_SUCCESS
    MANAGE_WATCHLIST_API
    MANAGE_WATCHLISTS_API_SUCCESS
    GET_WATCHLISTS_HOLDINGS_DATA_API
    GET_WATCHLISTS_HOLDINGS_DATA_API_SUCCESS
    ADD_SYMBOL_TO_WATCHLIST_DATA_API
    ADD_SYMBOL_TO_WATCHLIST_DATA_API_SUCCESS
    REQUEST_REVENUE_CHART_API
    REQUEST_REVENUE_CHART_API_SUCCESS
    REQUEST_DIVIDEND_CHART_API
    REQUEST_DIVIDEND_CHART_API_SUCCESS
    DELETE_SYMBOL_FROM_WATCHLIST_API
    DELETE_SYMBOL_FROM_WATCHLIST_API_SUCCESS
    REQUEST_WATCHLISTS_HOLDINGS_API
    REQUEST_WATCHLISTS_HOLDINGS_API_SUCCESS
    REQUEST_MULTIPLE_CALLS_API
    REQUEST_MULTIPLE_CALLS_API_SUCCESS
    GET_SNAPSHOT_V2_FUND_INDICATOR_DATA_API_SUCCESS
    REQUEST_WATCHLIST_DIVIDEND_API
    REQUEST_WATCHLIST_DIVIDEND_API_SUCCESS
    REQUEST_WATCHLIST_XID_INSTRUMENT_API
    REQUEST_WATCHLIST_XID_INSTRUMENT_API_SUCCESS
    GET_MARKETS_EQUITY_DIVIDENT_DATA_API
    GET_MARKETS_EQUITY_DIVIDENT_DATA_API_SUCCESS
`)
