/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable /*
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
import 'babel-polyfill'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SnapshotStyles from '../../../styles/snapshot.V2/snapshot.scss'
import Styles from '../../../styles/snapshot.V2/btnAddToWatchlists.scss'
import * as WatchlistActions from '../../../actions/watchlist.Actions'
import { ModalPopUp } from 'stifel-intyce-pattern-library'
import { MAX_WATCHLISTS, MAX_SECURITIES, WATCHLIST_MESSAGES } from '../../../utils/appConstants'
import CreateWatchlist from '../../watchlist/SubComponents/CreateWatchlist'
import $ from 'jquery'
import commonStyles from '../../../styles/common.v2/common.scss'

export class AddToWatchlistsMobile extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isListOpen: false,
      watchlists: [],
      fetching: true,
      isModalOpen: 0,
      modalComponent: null,
      modalHeight: null,
      numberOfWatchlistsContainingSymbol: 0,
      selectedTab: 'Existing',
      newWatchlistIds: [],
      responseMessage: null
    }

    this.openModel = this.openModel.bind(this)
    this.closeModel = this.closeModel.bind(this)
    this.createWatchlist = this.createWatchlist.bind(this)
    this.displayWatchlistsList = this.displayWatchlistsList.bind(this)
    this.tabButtonClickEventHandler = this.tabButtonClickEventHandler.bind(this)
    this.addSymbolToWatchlists = this.addSymbolToWatchlists.bind(this)
    this.selectWatchlistHandler = this.selectWatchlistHandler.bind(this)
    this.onCreateWatchlistSuccessHandler = this.onCreateWatchlistSuccessHandler.bind(this)
  }

  componentDidMount () {
    this.props.requestWatchlists()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.watchlists.isServerResponse) {
      let arrWatchlists = nextProps.watchlists.data
      this.setState({
        watchlists: arrWatchlists
      })

      // set is newly created watchlist
      arrWatchlists.map((item) => {
        if (this.state.newWatchlistIds.indexOf(item.id) !== -1) {
          item.isNewWatchlist = true
        }
      })

      nextProps.requestWatchlistsHoldings(
        nextProps.watchlists.data.map((item) => {
          return item.id
        })
      )

      nextProps.setFetchingDataFlag({
        watchlistsData_Platform: {
          isServerResponse: false
        }
      })
    }

    // check symbol exists in the watchlists
    if (nextProps.holdings.isServerResponse) {
      let arrWatchlists = this.state.watchlists
      let numberOfWatchlistsContainingSymbol = 0
      Object.keys(nextProps.holdings.items).map((wHolding, index) => {
        if (wHolding.indexOf('_Inputs') === -1) {
          let watchlistId = wHolding.replace('watchlist_', '')
          let arrHoldings = nextProps.holdings.items[wHolding].data.items

          let symbolExists = arrHoldings.filter(function (holding) {
            return holding.xid === Number(nextProps.wsodIssue)
          }).length > 0

          // find watchlist
          let watchlistContainingSymbol = arrWatchlists.filter(function (watchlist) {
            return watchlist.id === watchlistId
          })

          if (symbolExists) {
            numberOfWatchlistsContainingSymbol++
          }

          if (watchlistContainingSymbol.length > 0) {
            watchlistContainingSymbol[0].symbolExists = symbolExists
            watchlistContainingSymbol[0].holdingCount = arrHoldings.length
          }
        }
        return wHolding
      })

      nextProps.setFetchingDataFlag({
        watchlistsHoldings_Platform: {
          isServerResponse: false
        }
      })

      this.setState({
        fetching: false,
        watchlists: arrWatchlists,
        numberOfWatchlistsContainingSymbol: numberOfWatchlistsContainingSymbol
      })
    }

    if (nextProps.symbolsToWatchlist.isServerResponse) {
      nextProps.setFetchingDataFlag({
        symbolsToWatchlist_Platform: {
          isServerResponse: false
        }
      })

      this.setState({
        responseMessage: 'Saved successfully!'
      })

      let self = this
      setTimeout(function () {
        self.setState({
          responseMessage: null
        })
      }, 4000)

      this.props.requestWatchlists()
    }
  }

  onCreateWatchlistSuccessHandler (watchlistId) {
    let xid = this.props.wsodIssue

    if (watchlistId && xid) {
      this.props.requestWatchlists()
      this.setState({
        fetching: true,
        selectedTab: 'Existing'
      })

      this.state.newWatchlistIds.push(watchlistId)
    }
  }

  addSymbolToWatchlists (e) {
    if (e) {
      e.preventDefault()
      let xid = this.props.wsodIssue

      // get selected watchlist ids
      let arrChk = $('#watchlistsContainer input:checked').not(':disabled') || []

      arrChk.map((index, item) => {
        let wId = $(item).attr('data-watchlist-id')

        if (wId && xid) {
          this.props.requestAddSymbolToWatchlist(wId, Number(xid))

          this.setState({
            fetching: true
          })
        }
        return null
      })

      // reset newly created watchlist
      this.state.newWatchlistIds = []
    }
  }

  createWatchlist (e) {
    e && e.preventDefault()

    this.openModel()
    this.setState({
      modalHeight: '192px',
      modalComponent: <CreateWatchlist
        onCancel={this.closeModel}
        requestWatchlists={() => {}}
        onSuccessHandler={this.onCreateWatchlistSuccessHandler}
        notificationHandler={this.props.notificationHandler} />
    })
  }

  displayWatchlistsList (e) {
    e && e.preventDefault()

    this.setState({
      isListOpen: !this.state.isListOpen
    })
  }

  tabButtonClickEventHandler (e) {
    e && e.preventDefault()
    let btnText = $(e.currentTarget).html()

    if (Number(this.state.watchlists.length) >= MAX_WATCHLISTS) {
      this.props.notificationHandler({show: true, message: WATCHLIST_MESSAGES.MAX_WATCHLISTS})
      return
    }

    this.setState({
      selectedTab: btnText === 'Create New' ? 'Create New' : 'Existing'
    }, function () {
      if (btnText === 'Create New') {
        this.createWatchlist()
      }
    })
  }

  openModel () {
    this.setState({
      isModalOpen: 1
    })
  }

  closeModel () {
    this.setState({
      isModalOpen: 0,
      selectedTab: 'Existing'
    })
  }

  getCheckBox (watchlist) {
    if (watchlist.symbolExists) {
      return <input type='checkbox' checked='true' disabled data-watchlist-id={watchlist.id} />
    }

    if (watchlist.isNewWatchlist) {
      return <input type='checkbox' checked='true' data-el-identifier='add-to-watchlist_checkbox' data-watchlist-id={watchlist.id} />
    }

    return <input type='checkbox' data-el-identifier='add-to-watchlist_checkbox' data-watchlist-id={watchlist.id} />
  }

  selectWatchlistHandler (e) {
    if (e) {
      let holdingsCount = Number(e.currentTarget.getAttribute('data-holdings-count'))

      if (holdingsCount >= MAX_SECURITIES) {
        e.preventDefault()
        this.props.notificationHandler({show: true, message: WATCHLIST_MESSAGES.MAX_SECURITIES})
      }
    }
  }

  render () {
    return (
      <div className={`${Styles['add-to-watchlist-container']} ${this.state.isListOpen ? Styles['open'] : ''}`}>
        {
          this.state.isModalOpen === 1 &&
          <ModalPopUp
            height={this.state.modalHeight}
            onClick={() => { }}
            isOpen={this.state.isModalOpen}>
            {this.state.modalComponent}
          </ModalPopUp>
        }

        {
          !this.state.isListOpen && <div data-el-identifier='add-to-watchlist_value'
            onClick={this.displayWatchlistsList}
            className={`${Styles['btn-add-to-watchlist']} ${Styles['no-wrap']}`}>
            <span className={`${Styles['btn-add-icon']} ${SnapshotStyles['sprites-img']}`} />
            <span className={`${Styles['btn-add-icon-text']}`}>
              <span>
                Watchlist {this.state.numberOfWatchlistsContainingSymbol > 0 && (
                  '(' + this.state.numberOfWatchlistsContainingSymbol + ')'
                )}
              </span>
            </span>
          </div>
        }
        {
          this.state.isListOpen && <div className={`${Styles['watchlists-page-container']}`}>
            <div className={`${Styles['header']} ${SnapshotStyles['back-button']}`}>
              <a onClick={this.displayWatchlistsList} data-el-identifier='add-to-watchlist_back'>
                <span className={`${SnapshotStyles['arrow']}`} />
                <span>Back To Company Overview</span>
                <span className={`${commonStyles['sr-only']}`}>Back To Company Overview</span>
              </a>
            </div>
            <div className={`${Styles['content-container']}`}>
              <h3>Add to Watchlist</h3>
              <ul className={`${Styles['tab-buttons']}`}>
                <li
                  onClick={this.tabButtonClickEventHandler}
                  className={`${this.state.selectedTab === 'Existing' ? Styles['active'] : ''}`}>Existing</li>
                <li data-el-identifier='add-to-watchlist_create'
                  onClick={this.tabButtonClickEventHandler}
                  className={`${this.state.selectedTab === 'Create New' ? Styles['active'] : ''}`}>Create New</li>
              </ul>

              {
                this.state.fetching && <li>
                  <div className={`${commonStyles['loader']} ${Styles['loader']}`}>
                    <div className={`${commonStyles['rollingloader']} ${commonStyles['rollingloader-pos']}`} />
                  </div>
                </li>
              }

              {
                !this.state.fetching &&
                <div id='watchlistsContainer' className={`${Styles['watchlists-container']}`}>
                  {
                    this.state.responseMessage && <div className={`${Styles['message-container']}`}>
                      {this.state.responseMessage}
                    </div>
                  }
                  {
                    this.state.selectedTab === 'Existing' && this.state.watchlists.length === 0 &&
                    <div className={`${Styles['no-watchlist']}`}>
                      No Watchlist available
                    </div>
                  }
                  {
                    this.state.selectedTab === 'Existing' &&
                    this.state.watchlists.map((item, index) => (
                      <div key={index}
                        className={`${Styles['row']}`}>
                        <div className={`${Styles['watchlist-info']}`}>
                          <div className={`${Styles['wacthlist-name']}`}>
                            {item.name}
                            {
                              item.symbolExists && <span>
                                ({this.props.symbol} Included)
                              </span>
                            }
                          </div>
                          <div className={`${Styles['wacthlist-holdings-count']}`}>
                            {item.holdingCount} investments
                          </div>
                        </div>
                        <div className={`${Styles['checkbox-container']}`}>
                          <label
                            className={`${Styles['container']}`}
                            data-holdings-count={item.holdingCount}
                            onClick={item.symbolExists ? () => {} : this.selectWatchlistHandler}>
                            {this.getCheckBox(item)}
                            <span className={`${Styles['checkmark']}`} />
                          </label>
                        </div>
                      </div>
                    ))
                  }
                  {
                    this.state.selectedTab === 'Existing' &&
                    this.state.watchlists.length >= 0 &&
                    <div className={`${Styles['row']} ${Styles['footer']}`}>
                      <button onClick={this.displayWatchlistsList}> Cancel </button>
                      <button data-el-identifier='add-to-watchlist_save' onClick={this.addSymbolToWatchlists} className={`${Styles['blue']}`}> Save </button>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
    )
  };
};

AddToWatchlistsMobile.propTypes = {
  watchlists: PropTypes.object,
  wsodIssue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  requestAddSymbolToWatchlist: PropTypes.func,
  requestWatchlists: PropTypes.func,
  requestWatchlistsHoldings: PropTypes.func,
  setFetchingDataFlag: PropTypes.func,
  notificationHandler: PropTypes.func,
  holdings: PropTypes.object,
  symbolsToWatchlist: PropTypes.object,
  symbol: PropTypes.string
}

function mapStateToProps (state, ownProps) {
  let obj = {
    watchlists: {
      data: [],
      isServerResponse: false
    },
    watchlistId: null,
    holdings: {
      items: [],
      isServerResponse: false
    },
    symbolsToWatchlist: {
      holdings: [],
      isServerResponse: false
    }
  }

  if (state.watchlistReducer.watchlistsData_Platform.isServerResponse) {
    let arrWatchlists = state.watchlistReducer.watchlistsData_Platform.data.watchlists

    obj.watchlists = {
      data: arrWatchlists,
      isServerResponse: true
    }
  }

  if (state.watchlistReducer.watchlistsHoldings_Platform.isServerResponse) {
    obj.holdings = {
      items: state.watchlistReducer.watchlistsHoldings_Platform.data.holdings,
      isServerResponse: true
    }
  }

  if (state.watchlistReducer.symbolsToWatchlist_Platform.isServerResponse) {
    obj.symbolsToWatchlist = {
      holdings: state.watchlistReducer.symbolsToWatchlist_Platform.data.holdings,
      isServerResponse: true
    }
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(WatchlistActions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestWatchlists: () => {
      return dispatch(WatchlistActions.requestWatchlists())
    },
    requestWatchlistsHoldings: (watchlistsIds) => {
      return dispatch(WatchlistActions.requestWatchlistsHoldings(watchlistsIds, true))
    },
    requestAddSymbolToWatchlist: (watchlistId, xid) => {
      return dispatch(WatchlistActions.requestAddSymbolToWatchlist(watchlistId, xid))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToWatchlistsMobile)
