import Types from '../actions/types'
import { deepMerge } from '../../src/utils/deepMerge'
import { createReducer } from 'reduxsauce'
import INITIAL_STATE from './initial-state/watchlist'

const setFetchingDataFlag = (state, action) => {
  return Object.assign({}, deepMerge(state, action.payload))
}

const receiveWatchlists = (state = INITIAL_STATE, action) => {
  let obj = {
    fetching: false,
    isServerResponse: true,
    data: {
      watchlists: []
    }
  }

  if (action.payload.watchlists && action.payload.watchlists.error) {
    Object.assign(obj, action.payload.watchlists)
  } else {
    obj['data']['watchlists'] = action.payload.watchlists.data.items.sort(function (obj1, obj2) {
      let name1 = obj1.name.toLowerCase()
      let name2 = obj2.name.toLowerCase()

      if (name1 < name2) {
        return -1
      }
      if (name1 > name2) {
        return 1
      }
      return 0
    })
  }

  return Object.assign({}, state, {
    watchlistsData_Platform: deepMerge(state.watchlistsData_Platform, obj)
  })
}

const receiveManageWatchlist = (state = INITIAL_STATE, action) => {
  let obj = {
    data: {
      id: null
    },
    error: {
      code: null,
      message: null
    }
  }
  if (action.payload.manageWatchlist.errors &&
    action.payload.manageWatchlist.errors.length > 0) {
    Object.assign(obj, { error: {
      code: action.payload.manageWatchlist.code,
      message: action.payload.manageWatchlist.errors[0].message
    }})
  } else {
    obj.data.id = action.payload.manageWatchlist.data.id
  }

  return Object.assign({}, state, {
    manageWatchlistResponse: obj
  })
}

const receiveWatchlistHoldings = (state = INITIAL_STATE, action) => {
  let obj = {
    fetching: false,
    isHoldingFetched: false,
    isXidsFetched: false,
    data: {
      holdings: [],
      watchlistId: null
    }
  }

  let holdingDetail = {
    totalHoldings: 0,
    totalAdvancers: 0,
    totalDecliners: 0,
    totalUnchanged: 0,
    worstHolding: {},
    bestHolding: {},
    watchlistId: null
  }

  if (!action.payload.watchlistHoldings) {
    Object.assign(obj, {error: {
      code: 400,
      message: 'Unknown Error'
    }})
  } else if (action.payload.watchlistHoldings && action.payload.watchlistHoldings.error) {
    Object.assign(obj, action.payload.watchlistHoldings)
  } else {
    obj.data.watchlistId = action.payload.watchlistHoldings_Inputs.watchlistId
    obj.data.holdings = action.payload.watchlistHoldings.data.holdingsDetails ||
      action.payload.watchlistHoldings.data.items
    obj.isHoldingFetched = action.payload.watchlistHoldings_Inputs.updateFetch
    obj.fetching = action.payload.watchlistHoldings_Inputs.updateFetch
    holdingDetail.totalHoldings = action.payload.watchlistHoldings.data.totalHoldings
    holdingDetail.totalAdvancers = action.payload.watchlistHoldings.data.totalAdvancers
    holdingDetail.totalDecliners = action.payload.watchlistHoldings.data.totalDecliners
    holdingDetail.totalUnchanged = action.payload.watchlistHoldings.data.totalUnchanged
    holdingDetail.worstHolding = action.payload.watchlistHoldings.data.worstHolding
    holdingDetail.bestHolding = action.payload.watchlistHoldings.data.bestHolding
    holdingDetail.watchlistId = action.payload.watchlistHoldings.data.watchlistId
    state.watchlistHoldings_Platform.data.holdingDetails.push(holdingDetail)
  }
  return Object.assign({}, state, {
    watchlistHoldings_Platform: deepMerge(state.watchlistHoldings_Platform, obj)
  })
}

const receiveAddSymbolsToWatchlist = (state = INITIAL_STATE, action) => {
  let obj = {
    isServerResponse: true,
    data: {
      holdings: []
    }
  }

  if (action.payload.symbolsToWatchlist_Platform &&
    action.payload.symbolsToWatchlist_Platform.errors &&
    action.payload.symbolsToWatchlist_Platform.errors.length > 0) {
    Object.assign(obj, { error: {
      code: action.payload.symbolsToWatchlist_Platform.code,
      message: action.payload.symbolsToWatchlist_Platform.errors[0].message
    }})
  } else {
    obj.error = null
    obj.data.holdings = action.payload.symbolsToWatchlist_Platform.data
      ? action.payload.symbolsToWatchlist_Platform.data.items : []
  }

  return Object.assign({}, state, {
    symbolsToWatchlist_Platform: deepMerge(state.symbolsToWatchlist_Platform, obj)
  })
}

const receiveDeleteSymbol = (state = INITIAL_STATE, action) => {
  let obj = {
    data: {
      holdings: []
    }
  }

  if (action.payload.deleteSymbolFromWatchlistResponse &&
    action.payload.deleteSymbolFromWatchlistResponse.errors &&
    action.payload.deleteSymbolFromWatchlistResponse.errors.length > 0) {
    Object.assign(obj, { error: {
      code: action.payload.deleteSymbolFromWatchlistResponse.code,
      message: action.payload.deleteSymbolFromWatchlistResponse.errors[0].message
    }})
  } else {
    obj.data.holdings = action.payload.deleteSymbolFromWatchlistResponse.data.items
  }

  return Object.assign({}, state, {
    deleteSymbolFromWatchlistResponse: obj
  })
}

const receiveWatchlistsHoldings = (state = INITIAL_STATE, action) => {
  let obj = {
    isHoldingFetched: false,
    isXidsFetched: false,
    data: {
      holdings: []
    },
    fetching: false,
    isServerResponse: true
  }

  obj['data']['holdings'] = action.payload
  obj.isHoldingFetched = true
  return Object.assign({}, state, {
    watchlistsHoldings_Platform: obj
  })
}

const receiveMultipleAPICallsResponse = (state = INITIAL_STATE, action) => {
  return Object.assign({}, state, {
    multipleAPICallsResponse: {
      data: action.payload
    }
  })
}

const receiveWatchlistsHoldingDividends = (state = INITIAL_STATE, action) => {
  let obj = {
    fetching: false,
    isHoldingFetched: false,
    isXidsFetched: false,
    data: {
      holdings: [],
      watchlistId: null
    }
  }
  let previousHoldingData = Object.assign({}, state.watchlistHoldings_Platform)
  previousHoldingData.fetching = false
  if ((!action.payload.watchlistHoldingDividends_INDICES && !action.payload.watchlistHoldingDividends_MUTUALFUND && !action.payload.watchlistHoldingDividends_ETF) || ((action.payload.watchlistHoldingDividends_INDICES && !action.payload.watchlistHoldingDividends_INDICES.data) && (action.payload.watchlistHoldingDividends_MUTUALFUND && !action.payload.watchlistHoldingDividends_MUTUALFUND.data) && (action.payload.watchlistHoldingDividends_ETF && !action.payload.watchlistHoldingDividends_ETF.data))) {
    Object.assign(obj, {error: {
      code: 400,
      message: 'Unknown Error'
    }})
  } else {
    previousHoldingData.isXidsFetched = false
    let dividendIndex = (action.payload.watchlistHoldingDividends_INDICES && action.payload.watchlistHoldingDividends_INDICES.data && action.payload.watchlistHoldingDividends_INDICES.data.dividends) ? action.payload.watchlistHoldingDividends_INDICES.data.dividends : []
    let dividendEtf = (action.payload.watchlistHoldingDividends_ETF && action.payload.watchlistHoldingDividends_ETF.data && action.payload.watchlistHoldingDividends_ETF.data.dividends) ? action.payload.watchlistHoldingDividends_ETF.data.dividends : []
    let dividendMf = (action.payload.watchlistHoldingDividends_MUTUALFUND && action.payload.watchlistHoldingDividends_MUTUALFUND.data && action.payload.watchlistHoldingDividends_MUTUALFUND.data.dividends) ? action.payload.watchlistHoldingDividends_MUTUALFUND.data.dividends : []

    // map array
    previousHoldingData.data.holdings = previousHoldingData.data.holdings.map((x) => {
      // for stock
      let stockDividend = action.payload[`watchlistHoldingDividends_${x.xid}`]?.data
      let matchedDividend
      if (stockDividend) {
        matchedDividend = stockDividend
          ? {dividendAmountGross: stockDividend.currentDividend.amount,
            dividendYieldGross: stockDividend.annualizedDividend.yieldGross}
          : {dividendAmountGross: null,
            dividendYieldGross: null}
      } else {
      // for index
        let tempXids = dividendIndex.length > 0 ? dividendIndex.find((item) => (item.InstrumentXID && (parseInt(item.InstrumentXID) === x.xidInstrument))) : null
        matchedDividend = (tempXids && tempXids.dividend)
          ? {dividendAmountGross: tempXids.dividend.DivAmountGross,
            dividendYieldGross: tempXids.dividend.DivYieldGross}
          : {dividendAmountGross: null,
            dividendYieldGross: null}
        // for ETF
        tempXids = dividendEtf.length > 0 ? dividendEtf.find((item) => (item.InstrumentXID && (parseInt(item.InstrumentXID) === x.xidInstrument))) : null
        if (tempXids) {
          matchedDividend = (!matchedDividend.dividendAmountGross && tempXids && tempXids.dividend)
            ? {dividendAmountGross: tempXids.dividend.DivAmountNet,
              dividendYieldGross: tempXids.dividend.DivYieldIADGross}
            : {dividendAmountGross: null,
              dividendYieldGross: null}
        }
        // for mutual fund
        tempXids = dividendMf.length > 0 ? dividendMf.find((item) => (item.InstrumentXID && (parseInt(item.InstrumentXID) === x.xidInstrument))) : null
        if (tempXids) {
          matchedDividend = (!matchedDividend.dividendAmountGross && tempXids && tempXids.dividend)
            ? {dividendAmountGross: tempXids.dividend.DivAmountNet,
              dividendYieldGross: tempXids.dividend.DivYieldIADNet}
            : {dividendAmountGross: null,
              dividendYieldGross: null}
        }
      }
      return ({
        ...x,
        ...matchedDividend
      })
    })
  }
  return Object.assign({}, state, {
    watchlistHoldings_Platform: previousHoldingData
  })
}

const receiveWatchlistsHoldingXidInstruments = (state = INITIAL_STATE, action) => {
  let obj = {
    fetching: false,
    isHoldingFetched: false,
    isXidsFetched: false,
    data: {
      holdings: [],
      watchlistId: null
    }
  }
  let previousHoldingData = Object.assign({}, state.watchlistHoldings_Platform)
  if (!action.payload.watchlistHoldingXidInstruments_Platform || (action.payload.watchlistHoldingXidInstruments_Platform && action.payload.watchlistHoldingXidInstruments_Platform.error)) {
    previousHoldingData.fetching = false
    Object.assign(obj, {error: {
      code: 400,
      message: 'Unknown Error'
    }})
  } else {
    previousHoldingData.isXidsFetched = true
    previousHoldingData.isHoldingFetched = false
    previousHoldingData.data.holdings = previousHoldingData.data.holdings.map((x) => {
      let tempXids = action.payload.watchlistHoldingXidInstruments_Platform.data.items.find((item) => (item.symbol === x.symbol) && item)
      let matchedXid = (tempXids && tempXids.xids && tempXids.xids.instrument) ? {xidInstrument: tempXids.xids.instrument} : {xidInstrument: null}
      return ({
        ...x,
        ...matchedXid
      })
    })
  }
  return Object.assign({}, state, {
    watchlistHoldings_Platform: previousHoldingData
  })
}

const ACTION_HANDLERS = {
  [Types.SET_FETCHING_DATA_FLAG]: setFetchingDataFlag,
  [Types.GET_WATCHLISTS_DATA_API_SUCCESS]: receiveWatchlists,
  [Types.MANAGE_WATCHLISTS_API_SUCCESS]: receiveManageWatchlist,
  [Types.GET_WATCHLISTS_HOLDINGS_DATA_API_SUCCESS]: receiveWatchlistHoldings,
  [Types.ADD_SYMBOL_TO_WATCHLIST_DATA_API_SUCCESS]: receiveAddSymbolsToWatchlist,
  [Types.DELETE_SYMBOL_FROM_WATCHLIST_API_SUCCESS]: receiveDeleteSymbol,
  [Types.REQUEST_WATCHLISTS_HOLDINGS_API_SUCCESS]: receiveWatchlistsHoldings,
  [Types.REQUEST_MULTIPLE_CALLS_API_SUCCESS]: receiveMultipleAPICallsResponse,
  [Types.REQUEST_WATCHLIST_DIVIDEND_API_SUCCESS]: receiveWatchlistsHoldingDividends,
  [Types.REQUEST_WATCHLIST_XID_INSTRUMENT_API_SUCCESS]: receiveWatchlistsHoldingXidInstruments
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
