/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import newsCard from '../../../../styles/snapshot.V2/news/newsCard.scss'
import { Format } from 'stifel-intyce-pattern-library'
import {RenameNewsProvider, CreateMarkupTitle, GetTitleToDisplay} from './Common'

function getNewsCardHtml (news, cardNumber) {
  return <div className={`${newsCard['news-card']}`}
    style={{}}>
    {
      news.image && news.image.Url &&
      <div className={`${newsCard['top-img']}`} style={news.image ? { backgroundImage: 'url(' + news.image.Url + ')' } : {}} />
    }
    <div className={`${newsCard['middle-title']}`}>
      <span dangerouslySetInnerHTML={CreateMarkupTitle(GetTitleToDisplay(news.title), news.image && news.image.Url ? 80 : 150)} />
    </div>
    <div className={`${newsCard['bottom-publisher']}`}>
      <div className={`${newsCard['publisher-info']}`}>
        <div className={`${newsCard['provider']}`}> {RenameNewsProvider(news.provider)} </div>
        <div className={`${newsCard['date']}`}>
          {Format.getFormattedDateTime(news.date, {tzConversion: true})}
        </div>
      </div>
    </div>
  </div>
}

const NewsCardMobile = props => {
  return (
    <div role='presentation'
      className={`${newsCard['news-card-container']}`}
      data-card-no={props.cardNumber}
      data-articleId={props.news.documentKey}
      onClick={props.onClick}>
      {
        getNewsCardHtml(props.news)
      }
    </div>
  )
}

NewsCardMobile.propTypes = {
  news: PropTypes.object.isRequired,
  cardNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
}

export default NewsCardMobile
