import { connect } from 'react-redux'
import Markets from './Markets'
import * as MarketsV2Actions from '../../../actions/markets.V2.Action'

function mapStateToProps (state) {
  return {
    fetching: state.marketsV2Reducer.fetching,
    isMarketOpen: state.marketsV2Reducer.quoteData.marketOpen === 1,
    marketsTodayNews: state.marketsV2Reducer.marketsTodayNews.data.news[0],
    quoteIndiceData: state.marketsV2Reducer.quoteIndiceData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(MarketsV2Actions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestMarketsV2Data: (wsodIssue, loadImage, newsProvider) => {
      return dispatch(MarketsV2Actions.requestMarketsV2Data(wsodIssue, loadImage, newsProvider))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Markets)
