import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../../../styles/watchlist/watchlistCard.scss'

export default class WatchlistCard extends React.Component {
  render () {
    return (
      <div className={styles['watchlist-card']}>
        {this.props.children}
      </div>
    )
  }
}

WatchlistCard.propTypes = {
  children: PropTypes.node
}
