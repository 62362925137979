function getUrlParameterValueByName (name) {
  const url = window.location.href.toLowerCase()
  const cleanedName = name
    .replace(/[[\]]/g, '\\$&')
    .toLowerCase()
  const regex = new RegExp(`[?&]${cleanedName}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results) {
    return null
  }

  return results[2]
    ? decodeURIComponent(results[2].replace(/\+/g, ' '))
    : ''
}

const isDebugInfoOn = () => {
  return getUrlParameterValueByName('..showdebuginfo..') === 'on'
}

export default isDebugInfoOn
