import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MarketMovers from './MarketMovers'
import MarketMoversMobile from './MarketMoversMobile'
import * as MarketsV2Actions from '../../../../actions/markets.V2.Action'
import * as WatchlistActions from '../../../../actions/watchlist.Actions'

class MarketMoversContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  componentDidMount () {
    this.props.requestWatchlists()
  }

  render () {
    if (this.props.isMobileView) {
      return (
        <MarketMoversMobile
          allRankData={this.props.allRankData}
          requestMarketsV2MoversData={this.props.requestMarketsV2MoversData}
          isErrorOccurred={this.props.isErrorOccurred}
          openSnapShot={this.props.openSnapShot} />
      )
    } else {
      return (
        <MarketMovers
          allRankData={this.props.allRankData}
          requestMarketsV2MoversData={this.props.requestMarketsV2MoversData}
          isErrorOccurred={this.props.isErrorOccurred}
          openSnapShot={this.props.openSnapShot}
          watchlists={this.props.watchlists} />
      )
    }
  }
};

MarketMoversContainer.propTypes = {
  isMobileView: PropTypes.bool.isRequired,
  isErrorOccurred: PropTypes.bool,
  allRankData: PropTypes.array,
  requestMarketsV2MoversData: PropTypes.func,
  openSnapShot: PropTypes.func,
  requestWatchlists: PropTypes.func,
  watchlists: PropTypes.array
}

function mapStateToProps (state, ownProps) {
  let obj = {
    fetching: state.marketsV2Reducer.marketMovers.fetching,
    isErrorOccurred: !!state.marketsV2Reducer.marketMovers.error,
    allRankData: [],
    watchlists: state.watchlistReducer.watchlistsData_Platform.data.watchlists
  }

  if (state.marketsV2Reducer.marketMovers.data &&
    Array.isArray(state.marketsV2Reducer.marketMovers.data.allRankData)) {
    Object.assign(obj, {
      allRankData: state.marketsV2Reducer.marketMovers.data.allRankData || []
    })
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    requestMarketsV2MoversData: (exchange, rankingType, rowCount) => {
      return dispatch(MarketsV2Actions.requestMarketsV2MoversData(exchange, rankingType, rowCount))
    },
    requestWatchlists: () => {
      return dispatch(WatchlistActions.requestWatchlists())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketMoversContainer)
