import React from 'react'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
import snapshotNewsArticleStyles from '../../../../styles/snapshot.V2/news/snapshotNewsArticle.scss'
import marketsNewsArticleStyles from '../../../../styles/markets.V2/news/marketNewsArticle.scss'
import applicationStyles from '../../../../styles/main.scss'
import Header from '../header/Header'
import NewsArticle from '../../snapshot.V2/SubComponents/News/NewsArticle'

export default class NewsArticleContainer extends React.Component {
  constructor (props) {
    super(props)

    this.goBackHandler = this.goBackHandler.bind(this)
    this.isMobileView = this.isMobileView.bind(this)
  }

  componentDidMount () {
  }

  goBackHandler () {
    if (this.props.goBackHandler) {
      this.props.goBackHandler()
    } else {
      hashHistory.push('/Navigator/Markets/V2/')
    }
  }

  isMobileView () {
    if (window.innerWidth < 768) {
      return true
    }
    return false
  }

  render () {
    return (
      <div className={`${marketsNewsArticleStyles['news-article-parent-container']}`}>
        <div className={`${applicationStyles['pure-hidden-sm']} ${applicationStyles['pure-hidden-xs']}`}>
          <Header />
        </div>
        <div className={`${marketsNewsArticleStyles['header-container']}`}>
          <button className={`${marketsNewsArticleStyles['back-btn']}`} onClick={this.goBackHandler}>
            <span className={`${marketsNewsArticleStyles['icon-back']}`} />
            <span>
              Back to Markets & News
            </span>
          </button>
        </div>

        <div className={`${snapshotNewsArticleStyles['news-article-parent-container']} ${marketsNewsArticleStyles['markets-news-article-container']}`}>
          <NewsArticle
            isMobileView={this.isMobileView()}
            articleId={this.props.params.articleId}
            useStaticImage={this.props.location.query.usi}
            staticImageUrl={this.props.location.query.siu} />
        </div>
      </div>
    )
  };
};

NewsArticleContainer.propTypes = {
  params: PropTypes.object.isRequired,
  goBackHandler: PropTypes.func.isRequired,
  location: PropTypes.object
}
