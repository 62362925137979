import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import snapshotNewsArticleStyles from '../../../../styles/snapshot.V2/news/snapshotNewsArticle.scss'
import NewsArticle from './NewsArticle'
import { updateParentHeightForMobileView } from '../../../common.v2/CommonMethods'

export default class NewsArticleContainer extends React.Component {
  constructor (props) {
    super(props)

    this.goBackHandler = this.goBackHandler.bind(this)
  }

  componentDidMount () {
    updateParentHeightForMobileView()
  }

  componentDidUpdate (prevProps) {
    updateParentHeightForMobileView()
  }

  goBackHandler () {
    if (this.props.goBackHandler) {
      this.props.goBackHandler()
    } else {
      browserHistory.goBack()
    }
  }

  render () {
    return (
      <div className={`${snapshotNewsArticleStyles['news-article-parent-container']}`}>
        <div className={`${snapshotNewsArticleStyles['header-container']}`}>
          <button className={`${snapshotNewsArticleStyles['back-btn']}`} onClick={this.goBackHandler}>
            <span className={`${snapshotNewsArticleStyles['icon-back']}`} /> Back to Snapshot
          </button>
        </div>

        <NewsArticle articleId={this.props.articleId} />
      </div>
    )
  };
};

NewsArticleContainer.propTypes = {
  articleId: PropTypes.string.isRequired,
  goBackHandler: PropTypes.func.isRequired
}
