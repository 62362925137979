/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Format, ArrowV2, ArrowSVG } from 'stifel-intyce-pattern-library'
import indiceQuotesStyles from '../../../../styles/markets.V2/marketToday/indiceQuotes.scss'

class IndiceQuotes extends React.Component {
  ColorClass (value) {
    const classNameColor = value < 0 && value !== -32768
      ? this.props.style.negative
      : value > 0
        ? this.props.style.positive
        : ''
    return { color: classNameColor }
  }

  RenderArrow (value) {
    if (this.props.style.useSVGArrow) {
      return <ArrowSVG priceChange={value} positiveColor={this.props.style.positive} negativeColor={this.props.style.negative} />
    }
    return <ArrowV2 value={value} />
  }

  RenderPrice (price, priceChangePercent) {
    return (
      <div>
        <span style={this.ColorClass(price)}>{Format.number(price, 2)} </span>
        <span style={this.ColorClass(priceChangePercent)}>{priceChangePercent > 0 ? '+' : ''}{Format.percent(priceChangePercent, 2)} </span>
        {this.RenderArrow(this.props.priceChangeToday)}
      </div>
    )
  }

  render () {
    return (
      <div onClick={this.props.openSnapShot} data-symbol={this.props.symbol} data-wsod-issue={this.props.wsodIssue} className={`${indiceQuotesStyles['indices-container']}`}>
        <div className={`${indiceQuotesStyles['exchange-name']}`}>
          <span>{this.props.symbol}</span>
          <span>{Format.formatNumber(this.props.quotePrice)}</span>
        </div>
        <div className={`${indiceQuotesStyles['today-quote-price']}`}>
          {this.RenderPrice(this.props.priceChangeToday, this.props.priceChangePercentToday)}
        </div>
      </div>
    )
  }
}

IndiceQuotes.propTypes = {
  quotePrice: PropTypes.number.isRequired,
  symbol: PropTypes.string.isRequired,
  wsodIssue: PropTypes.number.isRequired,
  priceChangeToday: PropTypes.number.isRequired,
  priceChangePercentToday: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  openSnapShot: PropTypes.func
}

export default IndiceQuotes
