/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import WatchlistDetails from './WatchlistDetails'
import WatchlistStyles from '../../../../styles/watchlist/watchlists.scss'
import commonStyles from '../../../../styles/common.v2/common.scss'

export default class ManageWatchlists extends React.Component {
  render () {
    return (
      <div className={WatchlistStyles['manage-watchlist-container']}>
        {
          this.props.deletingWatchlist && <div
            className={`${WatchlistStyles['watchlists-parent-container']}`}>
            <div className={`${commonStyles['loader']} ${WatchlistStyles['loader-no-border']}`}>
              <div className={`${commonStyles['rollingloader']}`} />
              <div className={`${WatchlistStyles['deletingText']}`}>Deleting...</div>
            </div>
          </div>
        }
        {
          !this.props.deletingWatchlist && <div className={WatchlistStyles['watchlist-details-container']}>
            {
              this.props.watchlists.map(
                (item) => (
                  <div key={item.id} className={WatchlistStyles['watchlist-row']} data-watchlist-row='true'>
                    <WatchlistDetails watchlistId={item.id} watchlistName={item.name} />
                    <div>
                      <span role='presentation' data-el-identifier='manage-watchlist_delete'
                        data-watchlist-name={item.name}
                        data-watchlist-id={item.id}
                        className={`${WatchlistStyles['sprites-img']} ${WatchlistStyles['del-icon']}`}
                        onClick={this.props.deleteWatchlistMobile} />
                    </div>
                  </div>
                )
              )
            }
          </div>
        }
        <div className={WatchlistStyles['action-button-container']}>
          <button className={WatchlistStyles['cancel-btn']} data-cancel='true'
            onClick={this.props.doneManageWatchlist}>Cancel</button>

          <button data-el-identifier='saved-watchlist_value'
            className={`${WatchlistStyles['done-btn']} ${this.props.deletingWatchlist ? WatchlistStyles['disable'] : ''}`}
            onClick={this.props.doneManageWatchlist}>
            {this.props.deletingWatchlist ? 'Deleting...' : 'Save'}
          </button>
        </div>
      </div>
    )
  }
}

ManageWatchlists.propTypes = {
  watchlists: PropTypes.array.isRequired,
  deleteWatchlistMobile: PropTypes.func.isRequired,
  doneManageWatchlist: PropTypes.func.isRequired,
  deletingWatchlist: PropTypes.bool
}
