import $ from 'jquery'
import { INDICES_SYMBOL, LOCALSTORAGETYPE_CONSTANTS, ISSUE_TYPES_MAP, ISSUE_TYPES } from '../../../utils/appConstants'

export function SetParentHeight (heightToSet) {
  try {
    var contentHeight = heightToSet || $('#root').height()
    var isModalOpen = $('#outerPopUp').length > 0 || $('#outerModalPopUp').length > 0

    // It will remove the height from iFrame
    if (contentHeight === 0 || heightToSet === null) {
      isModalOpen = true
    }

    if (isModalOpen) {
      contentHeight = null
    }

    var objData = {
      'isModalOpen': isModalOpen,
      'contentHeight': contentHeight
    }

    if (window.self !== window.top) {
      document.body.setAttribute('is-in-iframe', 'true')

      if (isModalOpen) {
        document.body.style.setProperty('overflow', 'hidden', 'important')
      } else {
        document.body.style.setProperty('overflow', 'auto', 'important')
      }

      if (isMobileView() && !heightToSet) {
        let deviceHeightKey = 'device-height'
        let deviceHeight = localStorage.getItem(deviceHeightKey)

        if (!deviceHeight) {
          deviceHeight = window.innerHeight
          localStorage.setItem(deviceHeightKey, deviceHeight)
        }

        objData.contentHeight = deviceHeight
      }
    }
    console.log('Executing parent.postMessage(' + JSON.stringify(objData) + ', \'*\')')
    SetandReloadifrotatedagain()
    parent.postMessage(objData, '*')
  } catch (e) {
    console.error(e)
  }
}

export function isMobileView () {
  if (window.innerWidth < 768) {
    return true
  }

  return false
}

export function updateParentHeightForMobileView (reduceDeviceHeightWatchlist) {
  if (isMobileView()) {
    let deviceHeightKey = 'device-height'
    let deviceHeight = localStorage.getItem(deviceHeightKey)

    if (!deviceHeight) {
      deviceHeight = window.innerHeight
      localStorage.setItem(deviceHeightKey, deviceHeight)
    }

    // For iPhone X, XS, XS Max, and R devices
    if ((screen.width === 375 && screen.height === 812) || (screen.width === 414 && screen.height === 896)) {
      if (reduceDeviceHeightWatchlist) {
        SetParentHeight(deviceHeight - reduceDeviceHeightWatchlist)
      } else { SetParentHeight(deviceHeight - 100) }
    } else {
      SetParentHeight(deviceHeight - 64)
    }
  } else SetParentHeight()
}

export function GenRandomNumbers (count = 5, maxNo = 10) {
  var arrNum = []
  while (true) {
    if (arrNum.length === count) {
      break
    }

    let random = Math.floor(Math.random() * maxNo) + 1
    if (arrNum.indexOf(random) === -1) {
      arrNum.push(random)
    }
  }
  return arrNum
}

export function mapIndicesSymbol (xid, symbol) {
  switch (parseInt(xid)) {
    case INDICES_SYMBOL.NASDAQ:
      return 'NASDAQ'
    case INDICES_SYMBOL.DJIA:
      return 'DJIA'
    case INDICES_SYMBOL.NYSE:
      return 'NYSE'
    case INDICES_SYMBOL.SP500:
      return 'S&P 500'
    default:
      return symbol
  }
}

export function IsClientAccess () {
  return (window.location.href && window.location.href.toLowerCase().indexOf('/snapshot/quote/') > -1)
}

export function GetXidSymbIndices (symbol) {
  let xid = ''
  switch (symbol.toUpperCase()) {
    case 'DJIA':
      xid = INDICES_SYMBOL.DJIA
      break
    case 'NASDAQ':
      xid = INDICES_SYMBOL.NASDAQ
      break
    case 'NYSE':
      xid = INDICES_SYMBOL.NYSE
      break
    case 'SP500':
    case 'S&P500':
      xid = INDICES_SYMBOL.SP500
      break
    default:
      break
  }
  return xid ? xid.toString() : ''
}

export function CheckIsSymbIndices (symbol) {
  let indices = ['DJIA', 'NASDAQ', 'NYSE', 'SP500', 'S&P 500']
  if (indices.indexOf(symbol.toUpperCase()) > -1) {
    if (symbol === 'SP500') {
      return 'S&P500'
    } else {
      return symbol
    }
  } else {
    return undefined
  }
}

function IsSpecificVersionOfIos (osVersion, notSupportedVersions) {
  let agent = window.navigator.userAgent
  let start = agent.indexOf('OS ')
  if (start > -1 && ((notSupportedVersions && agent.indexOf('iPhone') > -1 && !notSupportedVersions.includes(window.Number(agent.substr(start + 3, 3).replace('_', '.')))) ||
  (((osVersion && (agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && window.Number(agent.substr(start + 3, 3).replace('_', '.')) === osVersion))))) {
    return true
  }
  return false
}

function SetandReloadifrotatedagain () {
  let iPhone = IsSpecificVersionOfIos(null, [10])
  if (iPhone) {
    let widthOfrotateddevice = sessionStorage.getItem('rotatedWidth') || ''
    if (widthOfrotateddevice && widthOfrotateddevice > window.innerWidth) {
      sessionStorage.removeItem('rotatedWidth')
      location.reload()
    } else if (!widthOfrotateddevice && window.innerWidth > window.innerHeight) {
      sessionStorage.setItem('rotatedWidth', window.innerWidth)
    }
  }
}

export function ClearLocalStorage (keyName) {
  keyName = GetLocalStorageKeyName(keyName)
  sessionStorage.removeItem(keyName)
  if (IsSpecificVersionOfIos()) {
    localStorage.removeItem(keyName)
  }
}

export function ClearSpecificKeysFromLocalStorage (keyName, arrKeys) { // flip
  keyName = GetLocalStorageKeyName(keyName)
  if (arrKeys.length > 0 && keyName) {
    let localStorageObj = GetLocalStorageIfAvailable(keyName)
    if (!localStorageObj) {
      return
    }
    arrKeys.forEach(element => {
      if (localStorageObj.hasOwnProperty(element)) {
        delete localStorageObj[element]
      }
    })
    sessionStorage.setItem(keyName, JSON.stringify(localStorageObj))
    if (IsSpecificVersionOfIos(11)) {
      localStorage.setItem(keyName, JSON.stringify(localStorageObj))
    }
  }
}

export function SetSpecificKeysIntoLocalStorage (keyName, obj) { // flip
  // SF-3040 - excluding companysnapshot page for modal popup state management
  if (IsCompanySnapshotPage()) {
    ClearLocalStorage(LOCALSTORAGETYPE_CONSTANTS.MARKETNEWS)
    ClearLocalStorage(LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS)
    return
  }

  keyName = GetLocalStorageKeyName(keyName)
  if (obj && keyName) {
    let localStorageObj = GetLocalStorageIfAvailable(keyName)
    if (!localStorageObj) {
      localStorageObj = {}
    }
    Object.keys(obj).forEach(key => {
      localStorageObj[key] = obj[key]
    })
    sessionStorage.setItem(keyName, JSON.stringify(localStorageObj))
    if (IsSpecificVersionOfIos(11)) {
      localStorage.setItem(keyName, JSON.stringify(localStorageObj))
    }
  }
}

export function GetLocalStorage (keyName) {
  return sessionStorage.getItem(keyName)
}

export function GetLocalStorageIfAvailable (keyName, speciFicKey) {
  keyName = GetLocalStorageKeyName(keyName)
  let localStorageObj = GetLocalStorage(keyName)
  if (!localStorageObj && IsSpecificVersionOfIos(11)) {
    localStorageObj = localStorage.getItem(keyName)
  }
  if (localStorageObj && localStorageObj.length > 0) {
    localStorageObj = JSON.parse(localStorageObj)
    if (speciFicKey) {
      if (localStorageObj.hasOwnProperty(speciFicKey)) {
        return localStorageObj[speciFicKey]
      }
      return undefined
    }
    return localStorageObj
  }
  return undefined
}

function GetLocalStorageKeyNameFromURL () {
  return location.href.indexOf('Watchlist') !== -1 ? LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS : LOCALSTORAGETYPE_CONSTANTS.MARKETNEWS
}

export function GetLocalStorageKeyName (keyName) {
  return keyName || GetLocalStorageKeyNameFromURL()
}

export function CheckIfItsAnIpad () {
  return window.navigator.userAgent.indexOf('iPad') > -1 || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
}

export function IsCompanySnapshotPage () {
  return window.location.href.toLocaleLowerCase().indexOf('companysnapshot') !== -1
}

export function getLargestDate (holdings) {
  let dates = {
    MUTUALFUNDDATE: [],
    ALLDATES: []
  }
  holdings && holdings.forEach(element => {
    if (ISSUE_TYPES_MAP[element.issueType] === ISSUE_TYPES.MutualFund) {
      if (element.navDateTime) {
        dates.MUTUALFUNDDATE.push(new Date(element.navDateTime))
      }
    } else {
      if (element.quotePriceDateTime) {
        dates.ALLDATES.push(new Date(element.quotePriceDateTime))
      }
    }
  })
  let mfDate = dates.MUTUALFUNDDATE.length > 0 ? new Date(Math.max(...dates.MUTUALFUNDDATE)) : null
  let date = dates.ALLDATES.length > 0 ? new Date(Math.max(...dates.ALLDATES)) : null
  return {dateMf: mfDate, date: date}
}
