/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import WatchlistStyles from '../../../../styles/watchlist/watchlists.scss'

class WatchlistDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      holdings: null
    }
  }

  componentDidMount () {
    this.setState(
      {
        holdings: this.props.holdingDetails.find(item => item.watchlistId === this.props.watchlistId)
      }
    )
  }

  render () {
    return (
      <div className={WatchlistStyles['watchlist-details']}>
        <div className={WatchlistStyles['watchlist-name']}>{this.props.watchlistName}</div>
        <div className={WatchlistStyles['investments']}>
          {this.state.holdings ? this.state.holdings.totalHoldings : '--' } investments
        </div>
      </div>
    )
  }
}

WatchlistDetails.propTypes = {
  watchlistId: PropTypes.string.isRequired,
  watchlistName: PropTypes.string.isRequired,
  holdingDetails: PropTypes.array
}

function mapStateToProps (state) {
  return {
    holdingDetails: state.watchlistReducer.watchlistHoldings_Platform.data.holdingDetails
  }
}

export default connect(mapStateToProps)(WatchlistDetails)
