import React from 'react'
import PropTypes from 'prop-types'
import overviewIndiceStyles from '../../../styles/snapshot.V2/overviewIndices.scss'
import { Format } from 'stifel-intyce-pattern-library'
import Chart from './Chart'
import { DATE_TIME_CONSTANTS, ISSUE_TYPES } from '../../../utils/appConstants'

export default class OverviewIndices extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      summary: ''
    }
  }
  render () {
    return (
      <div className={`${overviewIndiceStyles['indice-overview-container']}`}>
        {/* <RenderTextWithHeading
          heading='Index Summary'
          content={this.state.summary} /> */}
        <div className={`${overviewIndiceStyles['chart-view']}`}>
          <Chart isMobileView={this.props.isMobileView} handleResize={this.props.handleResize} symbol={this.props.symbol} wsodIssue={this.props.wsodIssue} issueType={ISSUE_TYPES.Indices} />
        </div>
        <div className={`${overviewIndiceStyles['bottom-container']}`}>
          <span data-el-identifier='overview-indices-date_footer' className={`${overviewIndiceStyles['date']}`}>
            {Format.getFormattedDateTime(this.props.dateAsOf, {preFix: DATE_TIME_CONSTANTS.AS_OF_PREFIX, tzConversion: true})}
          </span>
        </div>
      </div>
    )
  }
}

OverviewIndices.propTypes = {
  wsodIssue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  symbol: PropTypes.string.isRequired,
  handleResize: PropTypes.func,
  isMobileView: PropTypes.bool,
  dateAsOf: PropTypes.string.isRequired
}
