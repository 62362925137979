import React from 'react'
import PropTypes from 'prop-types'
import WatchlistOverviewStyles from '../../../../styles/watchlist/watchlistOverview.scss'

const WatchlistPerformance = (props) => {
  function calculatePerformanceBar (value, total) {
    if (!value) {
      return null
    }
    return value / total * 100 - 0.5
  }
  let {totalHoldings, totalAdvancers, totalDecliners, totalUnchanged} = {...props.holdingPerformance}
  return (
    <div className={WatchlistOverviewStyles['performance-status-container']}>
      <div className={WatchlistOverviewStyles['total-security']}>
        {totalHoldings} Securities
      </div>
      <div className={WatchlistOverviewStyles['performance-status-bar']}>
        { calculatePerformanceBar(totalAdvancers, totalHoldings) > 0 &&
        <div className={WatchlistOverviewStyles['advancers']} style={{ 'width': calculatePerformanceBar(totalAdvancers, totalHoldings) + '%' }} />
        }
        { calculatePerformanceBar(totalUnchanged, totalHoldings) > 0 &&
        <div className={WatchlistOverviewStyles['unchanged']} style={{ 'width': calculatePerformanceBar(totalUnchanged, totalHoldings) + '%' }} />
        }
        { calculatePerformanceBar(totalDecliners, totalHoldings) > 0 &&
        <div className={WatchlistOverviewStyles['decliners']} style={{ 'width': calculatePerformanceBar(totalDecliners, totalHoldings) + '%' }} />
        }
      </div>
      <div className={WatchlistOverviewStyles['securities-count']}>
        <div className={WatchlistOverviewStyles['advancers']}>
          <span>{totalAdvancers} </span><span>Advancers</span>
        </div>
        <div className={WatchlistOverviewStyles['unchanged']}>
          <span>{totalUnchanged} </span><span>Unchanged</span>
        </div>
        <div className={WatchlistOverviewStyles['decliners']}>
          <span>{totalDecliners} </span><span>Decliners</span>
        </div>
      </div>
    </div>
  )
}

WatchlistPerformance.propTypes = {
  holdingPerformance: PropTypes.object.isRequired
}

export default WatchlistPerformance
