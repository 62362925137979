import React from 'react'
import PropTypes from 'prop-types'
import { Format } from 'stifel-intyce-pattern-library'
import WatchlistStyles from '../../../styles/watchlist/watchlists.scss'
import { DATE_TIME_CONSTANTS } from '../../../utils/appConstants'

const AsOfDates = (props) => {
  return (
    <div className={WatchlistStyles['as-of-date-container']}>
      { props.asOfDate &&
        <div data-el-identifier='as-of-date_value'>
          {`As of ${Format.dateTimeFormatter(props.asOfDate, DATE_TIME_CONSTANTS.AS_OF_DATE_TIME_FORMAT)} ET`}
        </div>
      }
      { props.mfAsOfDate &&
        <div data-el-identifier='as-of-date_mf-value'>
          * Mutual fund data as of {Format.dateTimeFormatter(props.mfAsOfDate, DATE_TIME_CONSTANTS.AS_OF_DATE_FORMAT)}
        </div>
      }
    </div>
  )
}

AsOfDates.propTypes = {
  asOfDate: PropTypes.string,
  mfAsOfDate: PropTypes.string
}

export default AsOfDates
