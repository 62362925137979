/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Format } from 'stifel-intyce-pattern-library'
import IndiceQuotes from '../IndiceQuotes/IndiceQuotes'
import SparkChart from '../sparkChart/SparkChart'
import sparkChartContainerStyles from '../../../styles/markets.V2/marketToday/sparkChartContainer.scss'
import { INDICES_SYMBOL, DATE_TIME_CONSTANTS } from '../../../utils/appConstants'

class SparkChartContainer extends React.Component {
  render () {
    const style = {
      negative: '#FF3333',
      positive: '#9ED930',
      height: 30,
      useSVGArrow: true
    }
    return (
      <div className={`${sparkChartContainerStyles['spark-chart-container']}`}>
        <div className={`${sparkChartContainerStyles['header-container']}`}>
          <div className={`${sparkChartContainerStyles['indices-container']} ${sparkChartContainerStyles['alignRight']}`}>
            <span>Daily Change</span>
          </div>
          <div className={`${sparkChartContainerStyles['spark-chart']}`}>
            <span>30-Day Trend</span>
          </div>
        </div>
        <div onClick={this.props.openSnapShot} data-symbol={'DJIA'} data-wsod-issue={INDICES_SYMBOL.DJIA} className={`${sparkChartContainerStyles['content-container']}`}>
          <div className={`${sparkChartContainerStyles['indices-container']}`}>
            <IndiceQuotes
              symbol='DJIA'
              wsodIssue={INDICES_SYMBOL.DJIA}
              quotePrice={this.props.quoteIndiceData.quoteDJIA}
              priceChangePercentToday={this.props.quoteIndiceData.changePctDJIA}
              priceChangeToday={this.props.quoteIndiceData.changeDJIA}
              style={style} />
          </div>
          <div className={`${sparkChartContainerStyles['spark-chart']}`}>
            <SparkChart
              wsodIssue={INDICES_SYMBOL.DJIA}
              style={style}
              isMarketOpen={this.props.isMarketOpen}
              changeAmount={this.props.quoteIndiceData.changeDJIA} />
          </div>
        </div>

        <div onClick={this.props.openSnapShot} data-symbol={'NASDAQ'} data-wsod-issue={INDICES_SYMBOL.NASDAQ} className={`${sparkChartContainerStyles['content-container']}`}>
          <div className={`${sparkChartContainerStyles['indices-container']}`}>
            <IndiceQuotes
              symbol='NASDAQ'
              wsodIssue={INDICES_SYMBOL.NASDAQ}
              quotePrice={this.props.quoteIndiceData.quoteNASDAQ}
              priceChangePercentToday={this.props.quoteIndiceData.changePctNASDAQ}
              priceChangeToday={this.props.quoteIndiceData.changeNASDAQ}
              style={style} />
          </div>
          <div className={`${sparkChartContainerStyles['spark-chart']}`}>
            <SparkChart
              wsodIssue={INDICES_SYMBOL.NASDAQ}
              style={style}
              isMarketOpen={this.props.isMarketOpen}
              changeAmount={this.props.quoteIndiceData.changeNASDAQ} />
          </div>
        </div>

        <div onClick={this.props.openSnapShot} data-symbol={'NYSE'} data-wsod-issue={INDICES_SYMBOL.NYSE} className={`${sparkChartContainerStyles['content-container']}`}>
          <div className={`${sparkChartContainerStyles['indices-container']}`}>
            <IndiceQuotes
              symbol='NYSE'
              wsodIssue={INDICES_SYMBOL.NYSE}
              quotePrice={this.props.quoteIndiceData.quoteNYSE}
              priceChangePercentToday={this.props.quoteIndiceData.changePctNYSE}
              priceChangeToday={this.props.quoteIndiceData.changeNYSE}
              style={style} />
          </div>
          <div className={`${sparkChartContainerStyles['spark-chart']}`}>
            <SparkChart
              wsodIssue={INDICES_SYMBOL.NYSE}
              style={style}
              isMarketOpen={this.props.isMarketOpen}
              changeAmount={this.props.quoteIndiceData.changeNYSE} />
          </div>
        </div>

        <div onClick={this.props.openSnapShot} data-symbol={'S&P 500'} data-wsod-issue={INDICES_SYMBOL.SP500} className={`${sparkChartContainerStyles['content-container']}`}>
          <div className={`${sparkChartContainerStyles['indices-container']}`}>
            <IndiceQuotes
              symbol='S&P 500'
              wsodIssue={INDICES_SYMBOL.SP500}
              quotePrice={this.props.quoteIndiceData.quoteSP500}
              priceChangePercentToday={this.props.quoteIndiceData.changePctSP500}
              priceChangeToday={this.props.quoteIndiceData.changeSP500}
              style={style} />
          </div>
          <div className={`${sparkChartContainerStyles['spark-chart']}`}>
            <SparkChart
              wsodIssue={INDICES_SYMBOL.SP500}
              style={style}
              isMarketOpen={this.props.isMarketOpen}
              changeAmount={this.props.quoteIndiceData.changeSP500} />
          </div>
        </div>
        <div className={`${sparkChartContainerStyles['today-time']}`}>
          <span>
            {Format.getFormattedDateTime(this.props.quoteIndiceData.dateAsOf, {format: 'hh:mm A', preFix: DATE_TIME_CONSTANTS.AS_OF_PREFIX, timeOnly: true})}
          </span>
        </div>
      </div>
    )
  }
}

SparkChartContainer.propTypes = {
  quoteIndiceData: PropTypes.object.isRequired,
  isMarketOpen: PropTypes.bool.isRequired,
  openSnapShot: PropTypes.func
}

export default SparkChartContainer
