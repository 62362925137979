/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import AccessTokenService from '../../services/access-token-service'
import { detectEnvByDomain, SSO_DOMAIN, STIFEL_SSO_DOMAIN, WEBSITE_DOMAIN } from '../../utils/appConstants'
import Styles from '../../styles/Authentication/auth.scss'
import * as DataRequestMethods from './../../utils/dataRequestMethods'

class Login extends React.Component {
  constructor (props) {
    super(props)
    this.checkSession = this.checkSession.bind(this)
  }

  componentWillMount () {
    if (AccessTokenService.retrieve()) {
      DataRequestMethods.executeDataRequestViaApiPath(
        '/auth/test',
        'GET',
        null,
        (resp) => {
          (resp.data && resp.data.code === 200) ? this.checkSession(true, resp.data) : this.checkSession(false)
        })
    } else {
      this.checkSession(false)
    }
  }

  checkSession (isAuthenticated, data) {
    let returnURL = ''
    if (data && data.userType && data.userType !== 785) {
      returnURL = `${WEBSITE_DOMAIN[detectEnvByDomain]}/Navigator/${this.props.location.query.targetPage || ''}`
    } else {
      returnURL = `${WEBSITE_DOMAIN[detectEnvByDomain]}/${this.props.location.query.targetPage || ''}`
    }
    let symbol = this.props.location.query.symbol

    if (symbol) {
      returnURL += '?symbol=' + symbol
    }
    // redirect to target page
    if (isAuthenticated) {
      location.href = returnURL
    } else {
      // redirect to sso login page
      location.href = `${SSO_DOMAIN[detectEnvByDomain]}/sp/startSSO.ping?PartnerIdpId=http://www.stifel.com/samlassertingparty/Markit&frameAncestor=${this.props.location.query.initiator}&TargetResource=${encodeURIComponent(returnURL)}`
    }
  }

  render () {
    return (
      <div className={`${Styles['container']}`}>
        <div className={`${Styles['login']}`}>
          {/* Signing In... */}
        </div>
      </div>
    )
  }
};

Login.propTypes = {
  location: PropTypes.object
}

export default Login
