/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hashHistory } from 'react-router'
import * as WatchlistActions from '../../../../actions/watchlist.Actions'
import WatchlistStyles from '../../../../styles/watchlist/watchlists.scss'
import editWatchlistStyles from '../../../../styles/watchlist/mobile/editWatchlist.scss'
import { WATCHLIST_MESSAGES } from '../../../../utils/appConstants'

class EditWatchlist extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      deletedHolding: [],
      holdings: [],
      watchlistName: '',
      alreadyExists: false,
      genericError: false,
      editName: false
    }
    this.onDeleteHolding = this.onDeleteHolding.bind(this)
    this.onDone = this.onDone.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onFocusHandler = this.onFocusHandler.bind(this)
    this.toggleEditName = this.toggleEditName.bind(this)
    this.updateWatchlistName = this.updateWatchlistName.bind(this)
    this.onError = this.onError.bind(this)
    this.closeEditWatchlist = this.closeEditWatchlist.bind(this)
  }

  componentDidMount () {
    this.setState({watchlistName: this.props.watchlistName})
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.error && nextProps.error.message) {
      this.onError(nextProps.error.message)
    }
    if (nextProps.response) {
      this.toggleEditName()
      this.updateWatchlistName()
    }
  }

  onDeleteHolding (e) {
    e.preventDefault()
    let el = e.currentTarget
    el.parentElement.style.display = 'none'
    this.state.deletedHolding.push(el.getAttribute('data-holdingid'))
  }

  onDone () {
    if (this.state.deletedHolding.length > 0) {
      this.props.requestDeleteSymbol(this.props.watchlistId, this.state.deletedHolding.join(','))
    }
    this.onSave()
    this.updateWatchlistName()
    this.closeEditWatchlist()
  }

  onCancel () {
    this.updateWatchlistName()
    this.closeEditWatchlist()
  }

  updateWatchlistName () {
    if (this.state.watchlistName && this.props.watchlistName !== this.state.watchlistName) {
      hashHistory.push(`/Watchlist/${this.props.watchlistId}/${this.state.watchlistName}`)
      this.props.requestWatchlists()
    }
  }

  closeEditWatchlist () {
    this.props.toggleEditWatchlist(0, this.state.watchlistName)
  }

  onSave (e) {
    e && e.preventDefault()
    if (this.state.genericError) {
      this.props.notificationHandler({show: true})
    }
    let watchlistName = this.state.watchlistName.trim()
    if (watchlistName) {
      this.props.manageWatchlist(
        {
          actionType: 'PUT',
          watchlistId: this.props.watchlistId,
          name: watchlistName,
          descption: ''
        }
      )
    }
  }

  onError (error) {
    if (error.indexOf('already') !== -1 || error.indexOf('attempting to store the modified container') !== -1) {
      this.setState({alreadyExists: true})
    } else {
      this.setState({genericError: true})
      if (error.indexOf('invalid name length') !== -1) {
        this.props.notificationHandler({show: true, message: error})
      } else {
        this.props.notificationHandler({show: true})
      }
    }
  }

  onChangeHandler (event) {
    this.setState({
      watchlistName: event.target.value,
      alreadyExists: false
    })
  }

  onFocusHandler (e) {
    e.target.select()
  }

  toggleEditName () {
    this.setState((prevState) => ({ editName: !prevState.editName }))
  }

  editWatchlistName () {
    if (this.state.editName) {
      return (
        <div className={editWatchlistStyles['edit-watchlist-name']}>
          <input autoFocus autoComplete='off'
            type='text'
            placeholder='Enter Name (max 50 characters)'
            maxLength='50'
            onFocus={this.onFocusHandler}
            onChange={this.onChangeHandler}
            onBlur={this.onSave}
            value={this.state.watchlistName}
          />
          { this.state.alreadyExists &&
            <div className={editWatchlistStyles['already-exists']}>
              <span>{`"${this.state.name}"`} {WATCHLIST_MESSAGES.DUPLICATE}</span>
            </div>
          }
        </div>
      )
    } else {
      return (
        <div className={editWatchlistStyles['watchlist-name']}>
          <span className={editWatchlistStyles['watchlist-name-1']}>{this.state.watchlistName}</span>
          <span data-el-identifier='edit-watchlist_value' role='presentation'
            onClick={this.toggleEditName}
            data-watchlist-id={this.props.watchlistId}>
            <svg width='16px' height='25px' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='edit' className='svg-inline--fa fa-edit fa-w-18' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
              <path fill='#2472B2' d='M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z' />
            </svg>
          </span>
        </div>

      )
    }
  }

  render () {
    return (
      <div className={editWatchlistStyles['manage-holding-container']}>
        <div className={editWatchlistStyles['watchlist-name-header']}>
          {this.editWatchlistName()}
        </div>

        <div className={editWatchlistStyles['holding-details-container']}>
          {
            this.props.holdings.map(
              (item) => (
                <div key={item.symbol} className={editWatchlistStyles['holding-row']}>
                  <div className={editWatchlistStyles['watchlist-details']}>
                    <div className={editWatchlistStyles['symbol']}>
                      {item.symbol}
                    </div>
                    <div className={editWatchlistStyles['name']}>
                      {item.name}
                    </div>
                  </div>
                  <div role='presentation' data-el-identifier='edit-watchlist_delete'
                    data-holdingid={item.holdingId}
                    onClick={this.onDeleteHolding}
                    className={`${WatchlistStyles['sprites-img']} ${WatchlistStyles['del-icon']}`} />
                </div>
              )
            )
          }
        </div>
        <div className={`${editWatchlistStyles['action-button-container']}`}>
          <button onClick={this.onCancel} className={editWatchlistStyles['cancel-btn']}>Cancel</button>
          <button data-el-identifier='rename-watchlist_value' onClick={this.onDone} className={editWatchlistStyles['done-btn']}>Save</button>
        </div>
      </div>
    )
  }
}
EditWatchlist.propTypes = {
  watchlistId: PropTypes.string.isRequired,
  watchlistName: PropTypes.string.isRequired,
  holdings: PropTypes.array.isRequired,
  toggleEditWatchlist: PropTypes.func.isRequired,
  requestDeleteSymbol: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
  requestWatchlists: PropTypes.func.isRequired,
  manageWatchlist: PropTypes.func,
  response: PropTypes.string,
  error: PropTypes.object
}

function mapStateToProps (state) {
  return {
    response: state.watchlistReducer.manageWatchlistResponse.data.id,
    error: state.watchlistReducer.manageWatchlistResponse.error
  }
}

function mapDispatchToProps (dispatch) {
  return {
    manageWatchlist: (data) => {
      return dispatch(WatchlistActions.manageWatchlist(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWatchlist)
