/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ArrowSVG, Format } from 'stifel-intyce-pattern-library'
import WatchlistHeader from './WatchlistHeader'
import WatchlistPerformance from './WatchlistPerformance'
import EmptyWatchlistCard from './EmptyWatchlistCard'
import * as WatchlistActions from '../../../../actions/watchlist.Actions'
import WatchlistsStyles from '../../../../styles/watchlist/watchlists.scss'
import WatchlistOverviewStyles from '../../../../styles/watchlist/watchlistOverview.scss'
import { mapIndicesSymbol } from '../../../common.v2/CommonMethods'
import commonStyles from '../../../../styles/common.v2/common.scss'

class WatchlistOverview extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      holdingPerformance: null,
      fetching: true,
      advancerPct: null,
      declinerPct: null,
      unchangedPct: null
    }
    this.handleWatchlistClickEvent = this.handleWatchlistClickEvent.bind(this)
    this.handleWatchlistClickEventEnter = this.handleWatchlistClickEventEnter.bind(this)
  }

  componentDidMount () {
    this.props.requestWatchlistHoldings(this.props.watchlistId)
  }

  componentWillReceiveProps () {
    this.setState(
      {
        holdingPerformance: this.props.holdingDetails.find(item => item.watchlistId === this.props.watchlistId),
        fetching: false
      }
    )
  }

  handleWatchlistClickEvent (e) {
    e && e.stopPropagation()

    this.props.handleWatchlistSelection &&
    this.props.handleWatchlistSelection(
      {
        id: this.props.watchlistId,
        name: this.props.watchlistName
      }
    )
  }

  handleWatchlistClickEventEnter (e) {
    if (e.key === 'Enter') {
      this.handleWatchlistClickEvent(e)
    }
  }

  render () {
    if (this.state.fetching || !this.state.holdingPerformance) {
      return (
        <div className={WatchlistOverviewStyles['watchlist-overview-container']}>
          <WatchlistHeader
            watchlistName={this.props.watchlistName}
            watchlistId={this.props.watchlistId}
            deleteWatchlist={this.props.deleteWatchlist}
            deleteWatchlistEnter={this.props.deleteWatchlistEnter}
            handleWatchlistClickEvent={this.handleWatchlistClickEvent}
            handleWatchlistClickEventEnter={this.handleWatchlistClickEventEnter}
            createWatchlist={this.props.createWatchlist}
            createWatchlistEnter={this.props.createWatchlistEnter}
            isMobileView={this.props.isMobileView} />
          <div className={`${commonStyles['loader']} ${WatchlistsStyles['loader-no-border']}`}>
            <div className={`${commonStyles['rollingloader']}`} />
          </div>
        </div>
      )
    }

    if (this.props.isErrorOccurred) {
      return (
        <div className={WatchlistOverviewStyles['watchlist-overview-container']}>
          <div className={`${WatchlistsStyles['error-container']}`}>
            <div>
              Oops! There was an error processing your request.<br />
              <div className={`${WatchlistsStyles['error-info']}`}>
                (Error Code: {this.props.errorInfo.code}, Message: {this.props.errorInfo.message})
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className={WatchlistOverviewStyles['watchlist-overview-container']}>
        <WatchlistHeader
          watchlistName={this.props.watchlistName}
          watchlistId={this.props.watchlistId}
          deleteWatchlist={this.props.deleteWatchlist}
          deleteWatchlistEnter={this.props.deleteWatchlistEnter}
          handleWatchlistClickEvent={this.handleWatchlistClickEvent}
          handleWatchlistClickEventEnter={this.handleWatchlistClickEventEnter}
          createWatchlist={this.props.createWatchlist}
          createWatchlistEnter={this.props.createWatchlistEnter}
          isMobileView={this.props.isMobileView} />
        { this.state.holdingPerformance && this.state.holdingPerformance.totalHoldings > 0 &&
          <div>
            <div className={WatchlistOverviewStyles['watchlist-overview']}>
              <div className={WatchlistOverviewStyles['heading']}>
                Today's Best & Worst
              </div>
              <div className={WatchlistOverviewStyles['best-worst-container']}>
                <div className={WatchlistOverviewStyles['best']}>
                  <div className={WatchlistOverviewStyles['symbol-info']}>
                    <div className={WatchlistOverviewStyles['symbol']}
                      tabIndex='0'
                      data-wsod-issue={this.state.holdingPerformance.bestHolding.xid}
                      data-symbol={mapIndicesSymbol(this.state.holdingPerformance.bestHolding.xid, this.state.holdingPerformance.bestHolding.symbol)}
                      onClick={this.props.onSymbolClick}
                      onKeyPress={this.props.onSymbolClickEnter} >
                      { mapIndicesSymbol(this.state.holdingPerformance.bestHolding.xid, this.state.holdingPerformance.bestHolding.symbol) }
                    </div>
                    <div className={WatchlistOverviewStyles['company-name']}>
                      {this.state.holdingPerformance.bestHolding.name}
                    </div>
                  </div>
                  <div className={WatchlistOverviewStyles['change-info']}>
                    <span>{Format.formatNumber(this.state.holdingPerformance.bestHolding.change, {currencyPreFix: true, negSign: true, color: true})} </span>
                    <span>{Format.formatNumber(this.state.holdingPerformance.bestHolding.changePct, {parentheses: false, postFix: true, negSign: true, color: true}) } </span>
                    <ArrowSVG priceChange={this.state.holdingPerformance.bestHolding.change} positiveColor={'#5C8118'} negativeColor={'#CB223C'} />
                  </div>
                </div>
                <div className={WatchlistOverviewStyles['worst']}>
                  <div className={WatchlistOverviewStyles['symbol-info']}>
                    <div className={WatchlistOverviewStyles['symbol']}
                      tabIndex='0'
                      data-wsod-issue={this.state.holdingPerformance.worstHolding.xid}
                      data-symbol={mapIndicesSymbol(this.state.holdingPerformance.worstHolding.xid, this.state.holdingPerformance.worstHolding.symbol)}
                      onClick={this.props.onSymbolClick}
                      onKeyPress={this.props.onSymbolClickEnter} >
                      { mapIndicesSymbol(this.state.holdingPerformance.worstHolding.xid, this.state.holdingPerformance.worstHolding.symbol) }
                    </div>
                    <div className={WatchlistOverviewStyles['company-name']}>
                      {this.state.holdingPerformance.worstHolding.name}
                    </div>
                  </div>
                  <div className={WatchlistOverviewStyles['change-info']}>
                    <span>{Format.formatNumber(this.state.holdingPerformance.worstHolding.change, {currencyPreFix: true, negSign: true, color: true})} </span>
                    <span>{Format.formatNumber(this.state.holdingPerformance.worstHolding.changePct, {parentheses: false, postFix: true, negSign: true, color: true})} </span>
                    <ArrowSVG priceChange={this.state.holdingPerformance.worstHolding.change} positiveColor={'#5C8118'} negativeColor={'#CB223C'} />
                  </div>
                </div>
              </div>
            </div>
            <WatchlistPerformance holdingPerformance={this.state.holdingPerformance} />
          </div>
        }
        { this.state.holdingPerformance && this.state.holdingPerformance.totalHoldings === 0 &&
          <EmptyWatchlistCard handleWatchlistClickEvent={this.handleWatchlistClickEvent}
            handleWatchlistClickEventEnter={this.handleWatchlistClickEventEnter} />
        }
      </div>
    )
  }
}

WatchlistOverview.propTypes = {
  isErrorOccurred: PropTypes.bool.isRequired,
  errorInfo: PropTypes.object.isRequired,
  watchlistId: PropTypes.string.isRequired,
  watchlistName: PropTypes.string.isRequired,
  deleteWatchlist: PropTypes.func.isRequired,
  deleteWatchlistEnter: PropTypes.func.isRequired,
  requestWatchlistHoldings: PropTypes.func,
  holdingDetails: PropTypes.array,
  onSymbolClick: PropTypes.func.isRequired,
  onSymbolClickEnter: PropTypes.func.isRequired,
  handleWatchlistSelection: PropTypes.func.isRequired,
  createWatchlist: PropTypes.func.isRequired,
  createWatchlistEnter: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool.isRequired
}

function mapStateToProps (state, ownProps) {
  let obj = {
    isErrorOccurred: !!state.watchlistReducer.watchlistHoldings_Platform.error,
    errorInfo: state.watchlistReducer.watchlistHoldings_Platform.error || {},
    holdingDetails: []
  }

  if (!obj.isErrorOccurred) {
    Object.assign(obj, {
      holdingDetails: state.watchlistReducer.watchlistHoldings_Platform.data.holdingDetails
    })
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    requestWatchlistHoldings: (id) => {
      return dispatch(WatchlistActions.requestWatchlistHoldings(id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistOverview)
