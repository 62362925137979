import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { hashHistory, browserHistory } from 'react-router'
import marketsStyles from '../../../styles/markets.V2/markets.scss'
import marketsNewsStyles from '../../../styles/markets.V2/news/news.scss'
import snapshotNewsStyles from '../../../styles/snapshot.V2/news/newsContainer.scss'
import * as SnapshotV2Actions from '../../../actions/snapshot.V2.Actions'
import NewsCard from '../../snapshot.V2/SubComponents/News/NewsCard'
import NewsLinkList from '../../snapshot.V2/SubComponents/News/NewsLinkList'
import { updateParentHeightForMobileView, GenRandomNumbers } from '../../common.v2/CommonMethods'
import commonStyles from '../../../styles/common.v2/common.scss'

class MarketsNews extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      wsodIssue: null,
      showMore: sessionStorage.getItem('markets-v2-expand-collapse') !== null
        ? sessionStorage.getItem('markets-v2-expand-collapse') === 'true' : true,
      articleId: null,
      news: [],
      startRowNumber: props.newsCategory === 'Top News' ? 1 : 0,
      pageSize: 6,
      currentPageNumber: parseInt(sessionStorage.getItem('markets-v2-news-page-no')),
      listRowStartNumber: props.newsCategory === 'Top News' ? 8 : 7
    }

    this.props.setFetchingDataFlag({
      marketsNewsData: {
        fetching: true
      }}
    )

    this.goBackHandler = this.goBackHandler.bind(this)
    this.loadNewsArticle = this.loadNewsArticle.bind(this)
    this.expandCollapseNews = this.expandCollapseNews.bind(this)
    this.loadNews = this.loadNews.bind(this)
  }

  componentDidMount () {
    this.setState({
      wsodIssue: this.props.wsodIssue
    }, () => {
      this.loadNews()
    })
    updateParentHeightForMobileView()
  }

  componentDidUpdate (prevProps) {
    updateParentHeightForMobileView()
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.fetching && nextProps.totalMatches > 0) {
      let tempNews = nextProps.news || []

      // check images if not available then add static images
      if (this.props.newsCategory !== 'Top News') {
        let arrRandumNumber = GenRandomNumbers(6, this.props.newsCategory === 'World' ? 9 : 10)

        tempNews.forEach(news => {
          let randomImageNo = arrRandumNumber.pop()

          if (!news.image && randomImageNo) {
            news.useStaticImage = true
            news.image = {
              Url: `/images/${this.props.newsCategory}/${randomImageNo}.jpg`
            }
          }
        })
      }

      this.setState({
        news: tempNews
      })
    }
  }

  loadNewsArticle (e) {
    if (!e) return

    e.preventDefault()
    let articleId = e.currentTarget.getAttribute('data-articleId')
    let useStaticImage = e.currentTarget.getAttribute('data-use-static-image')
    let staticImageUrl = e.currentTarget.getAttribute('data-static-image-url')
    let txtPageNumber = document.getElementById('txtPageNumber')

    sessionStorage.setItem('markets-v2-news-category', this.props.newsCategory)
    sessionStorage.setItem('markets-v2-expand-collapse', this.state.showMore)
    sessionStorage.setItem('markets-v2-news-page-no', txtPageNumber && txtPageNumber.value.trim() !== '' ? parseInt(txtPageNumber.value) : null)

    this.setState({
      currentPageNumber: sessionStorage.getItem('markets-v2-news-page-no')
    })

    let path = '/Markets/V2/News/Article/' + articleId

    if (useStaticImage === 'true' && staticImageUrl) {
      path += '?usi=true&siu=' + staticImageUrl
    }

    hashHistory.push(path)
  }

  loadNews () {
    this.props.requestSnapshotV2NewsData(
      '',
      '',
      sessionStorage.getItem('markets-v2-news-category') || this.props.newsCategory,
      this.state.startRowNumber,
      this.state.pageSize,
      true,
      'Medium'
    )
  }

  expandCollapseNews () {
    this.setState({
      showMore: !this.state.showMore
    }, () => {
      if (this.state.showMore) {
        sessionStorage.removeItem('markets-v2-news-page-no')
      }
      sessionStorage.setItem('markets-v2-expand-collapse', this.state.showMore)
    })
  }

  goBackHandler (e) {
    e && e.preventDefault()
    browserHistory.goBack()
  }

  render () {
    if (this.props.fetching) {
      return (
        <div className={`${marketsStyles['markets-container']}`}>
          <div className={`${commonStyles['loader']}`}>
            <div className={`${commonStyles['rollingloader']}`} />
          </div>
        </div>
      )
    }

    if (this.props.isErrorOccurred) {
      return (
        <div className={`${marketsStyles['markets-container']} ${marketsStyles['error-container']}`}>
          <div>
            Oops! There was an error processing your request. Please try again.<br />
            <div className={`${marketsStyles['error-info']}`}>
              (Error Code: {this.props.errorInfo.code}, Message: {this.props.errorInfo.message})
            </div>
          </div>
        </div>
      )
    }

    if (this.props.totalMatches === 0) {
      return (
        <div className={`${marketsStyles['markets-container']} ${marketsNewsStyles['markets-news-parent-container']}`}>
          <div className={`${marketsNewsStyles['no-news']}`}>
            <div>
              Oops! We couldn't find related news stories.<br />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={`${marketsNewsStyles['news-container']} ${snapshotNewsStyles['news-container']}`}>
        <div className={`${marketsNewsStyles['top-5-news-container']}`} >
          {
            this.state.news.map(
              (item, index) => {
                return item && <NewsCard
                  news={item}
                  cardNumber={index + 1}
                  key={index}
                  onClick={this.loadNewsArticle} />
              }
            )
          }
        </div>

        {
          this.props.totalMatches > this.state.pageSize &&
          <div className={`${snapshotNewsStyles['show-more-btn-container']}`}>
            {
              this.state.showMore &&
              <button
                className={`${snapshotNewsStyles['show-more-btn']}`}
                onClick={this.expandCollapseNews} id='btnShowMore'>
                Show More <span className={`${snapshotNewsStyles['icon']} ${snapshotNewsStyles['down']}`} />
              </button>
            }
            {
              !this.state.showMore &&
              <button
                className={`${snapshotNewsStyles['show-more-btn']}`}
                onClick={this.expandCollapseNews} id='btnShowMore'>
                Show Less <span className={`${snapshotNewsStyles['icon']} ${snapshotNewsStyles['up']}`} />
              </button>
            }
          </div>
        }

        {
          this.props.totalMatches > this.state.pageSize && !this.state.showMore &&
          <NewsLinkList
            fetching
            currentPageNumber={this.state.currentPageNumber}
            news={[]}
            rowStartNumber={this.state.listRowStartNumber}
            noRecordsToExclude={7}
            securityType={''}
            newsCategory={this.props.newsCategory}
            wsodIssue={this.props.wsodIssue}
            requestSnapshotV2NewsData={this.props.requestSnapshotV2NewsData}
            onClick={this.loadNewsArticle}
            isMarketsNews />
        }
      </div>
    )
  };
};

MarketsNews.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isErrorOccurred: PropTypes.bool.isRequired,
  totalMatches: PropTypes.number.isRequired,
  news: PropTypes.array.isRequired,
  wsodIssue: PropTypes.string,
  setFetchingDataFlag: PropTypes.func.isRequired,
  errorInfo: PropTypes.object.isRequired,
  requestSnapshotV2NewsData: PropTypes.func.isRequired,
  newsCategory: PropTypes.string.isRequired
}

function mapStateToProps (state, ownProps) {
  let obj = {
    fetching: state.snapshotV2Reducer.marketsNewsData.fetching,
    isErrorOccurred: !!state.snapshotV2Reducer.marketsNewsData.error,
    errorInfo: state.snapshotV2Reducer.marketsNewsData.error || {},
    news: [],
    totalMatches: 0
  }

  if (state.snapshotV2Reducer.marketsNewsData.data) {
    let totalMatches = state.snapshotV2Reducer.marketsNewsData.data.totalMatches

    Object.assign(obj, {
      news: totalMatches === 0 ? [] : state.snapshotV2Reducer.marketsNewsData.data.news,
      totalMatches: totalMatches
    })
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(SnapshotV2Actions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestSnapshotV2NewsData: (securityType, wsodIssue, newsProvider, rowNumber,
      articleCount, loadImage, imgSize) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2NewsData(securityType, wsodIssue,
        newsProvider, rowNumber, articleCount, loadImage, imgSize, 'marketsNewsData'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketsNews)
