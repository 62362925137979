/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import marketMoversTable from '../../../styles/common.v2/marketMoversTable.scss'
import { Format, Common } from 'stifel-intyce-pattern-library'

const MarketMoversTableRow = props => {
  return (
    <div className={`${marketMoversTable['row']} ${marketMoversTable['data-row']}`}>
      <div className={`${marketMoversTable['symbol']}`}>
        <div className={`${marketMoversTable['name']}`}
          data-wsod-issue={props.data.xid}
          data-symbol={props.data.symbol}
          onClick={props.onClick} data-el-identifier='market-movers-symbol_value'>
          { props.data.symbol }
        </div>
        <div data-el-identifier='marketmover_company' className={`${marketMoversTable['company']}`} title={props.data.company}>
          { props.data.company }
        </div>
      </div>
      <div className={`${marketMoversTable['last']}`}>
        { Format.formatNumber(props.data.last, {currencyPreFix: true}) }
      </div>
      <div className={`${marketMoversTable['change']}`}>
        <div className={`${marketMoversTable['price-percent']}`}>
          <div>
            { Format.formatNumber(props.data.priceChange, {currencyPreFix: true, negSign: true, color: true}) }
          </div>
          <div>
            { Format.formatNumber(props.data.priceChangePercent, {postFix: true, negSign: true, color: true}) }
          </div>
        </div>
        <div className={`${marketMoversTable['icon']}`}>
          { (Format.formatNumber(props.data.priceChange) !== '--' ||
          Format.formatNumber(props.data.priceChangePercent) !== '--')
            ? Common.RenderImageArrow(props.data.priceChange) : '' }
        </div>
      </div>
      <div className={`${marketMoversTable['range-52week-low-high']}`}>
        <div className={`${marketMoversTable['low']}`}>
          { Format.formatNumber(props.data.low52W, {currencyPreFix: true}) }
        </div>
        <div className={`${marketMoversTable['slider-container']}`}>
          <input type='range' step='any' disabled='true'
            aria-label={`${props.data.symbol}52weekRange`}
            min={props.data.low52W}
            max={props.data.high52W}
            value={props.data.price}
            className={`${marketMoversTable['slider']}`} />
        </div>
        <div className={`${marketMoversTable['high']}`}>
          { Format.formatNumber(props.data.high52W, {currencyPreFix: true}) }
        </div>
      </div>
      <div className={`${marketMoversTable['volume']}`}>
        { Format.formatNumber(props.data.volume, {precision: 0}) }
      </div>
    </div>
  )
}

MarketMoversTableRow.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func
}

export default MarketMoversTableRow
