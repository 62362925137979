
import { IsClientAccess } from '../components/common.v2/CommonMethods'
import { getUrlParameterValueByName } from '../utils/urlMethods'

const retrieve = (localAccessToken) => {
  if (IsClientAccess()) {
    return getUrlParameterValueByName('access_token')
  }
  // token from meta > token from local storage > local access token
  const accessTokenMeta = document.querySelector("meta[name='access_token']")
  const accessTokenFromMeta = accessTokenMeta ? accessTokenMeta.getAttribute('value') : null
  const accessTokenFromLocalStorage = localStorage.getItem('access_token')
  const accessToken = accessTokenFromMeta || (accessTokenFromLocalStorage || localAccessToken || '')

  return accessToken
}

export default {
  retrieve
}
