/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import HoldingsTableStyles from '../../../../../styles/watchlist/mobile/holdingsTable.scss'
import WatchlistsStyles from '../../../../../styles/watchlist/watchlists.scss'
import { Format, Common } from 'stifel-intyce-pattern-library'
import { mapIndicesSymbol } from '../../../../common.v2/CommonMethods'
import { ISSUE_TYPES_MAP, ISSUE_TYPES } from '../../../../../utils/appConstants'

const HoldingsBasicTable = props => {
  if (props.holdings.length === 0) {
    return (
      <div className={`${HoldingsTableStyles['tbl-holdings']}`}>
        <div data-el-identifier='holding-basic_add-security' className={`${HoldingsTableStyles['no-data']}`}>
          Add securities to get started.
        </div>
      </div>
    )
  }

  function renderSortIcon (sortBy) {
    return props.sortBy.columnName === sortBy &&
    <span className={`${WatchlistsStyles[ 'arrow-' + props.sortBy.order ]}`} />
  }

  return (
    <div className={`${HoldingsTableStyles['tbl-holdings']} ${HoldingsTableStyles['tbl-basic']}`}>
      <div className={`${HoldingsTableStyles['header']}
        ${HoldingsTableStyles['header-1']}
        ${HoldingsTableStyles['row']}`} data-sort-by={props.sortBy.columnName}>

        <div className={`${HoldingsTableStyles['symbol']}`}>
          { renderSortIcon('symbol') }
          <span
            data-col-name='symbol'
            data-col-type='String'
            onClick={props.onColHeaderClick}
            className={`${HoldingsTableStyles['col-header']}`}>
            Symbol / Name
          </span>
        </div>

        <div className={`${HoldingsTableStyles['last']}`}>
          { renderSortIcon('quote') }
          <span
            data-col-name='quote'
            data-col-type='Number'
            onClick={props.onColHeaderClick}
            className={`${HoldingsTableStyles['col-header']}`}>
            Last
          </span>
        </div>

        <div className={`${HoldingsTableStyles['change']}`}>
          { renderSortIcon('changePct') }
          <span
            data-col-name='changePct'
            data-col-type='Number'
            onClick={props.onColHeaderClick}
            className={`${HoldingsTableStyles['col-header']}`}>
            Change
          </span>
        </div>
      </div>

      <div data-symbols-container='true'>
        {
          props.holdings.map((item, index) => (
            <div className={`${HoldingsTableStyles['row']} ${HoldingsTableStyles['data-row']}`}
              key={item.xid} data-holding-row={item.holdingId}>
              <div className={`${HoldingsTableStyles['symbol']}`}>
                <div className={`${HoldingsTableStyles['name']}`}
                  data-wsod-issue={item.xid} data-el-identifier='holding-basic-symbol_value'
                  data-symbol={mapIndicesSymbol(item.xid, item.symbol)}
                  data-return-url={'/Navigator/Watchlist/' + props.watchlistInfo.id + '/' + props.watchlistInfo.name + '?tab=Basic'}
                  onClick={props.onSymbolClick}>
                  { mapIndicesSymbol(item.xid, item.symbol) }
                </div>
                <div className={`${HoldingsTableStyles['company']}`} title={item.name}>
                  { item.name }
                </div>
              </div>

              <div className={`${HoldingsTableStyles['last']}`}>
                { Format.formatNumber((ISSUE_TYPES.MutualFund === ISSUE_TYPES_MAP[item.issueType] ? item.nav : item.quote), {currencyPreFix: true}) }
              </div>

              <div className={`${HoldingsTableStyles['change']}`}>
                <div className={`${HoldingsTableStyles['price-percent']}`}>
                  {
                    Format.formatNumber(item.change, {currencyPreFix: true, negSign: true, color: true})
                  }
                  {
                    <br />
                  }
                  {
                    Format.formatNumber(item.changePct, {postFix: true, negSign: true, color: true, parentheses: false})
                  }
                </div>
                <div className={`${HoldingsTableStyles['icon']}`}>
                  { (Format.formatNumber(item.change) !== '--' ||
                        Format.formatNumber(item.changePct) !== '--')
                    ? Common.RenderImageArrow(item.change) : '' }
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

HoldingsBasicTable.propTypes = {
  holdings: PropTypes.array,
  onSymbolClick: PropTypes.func,
  onColHeaderClick: PropTypes.func,
  sortBy: PropTypes.object,
  watchlistInfo: PropTypes.object
}

export default HoldingsBasicTable
