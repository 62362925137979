/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import HoldingsTableStyles from '../../../../styles/watchlist/mobile/holdingsTable.scss'
import WatchlistsStyles from '../../../../styles/watchlist/watchlists.scss'
import { Format } from 'stifel-intyce-pattern-library'
import { mapIndicesSymbol } from '../../../common.v2/CommonMethods'

const HoldingsPerformanceTable = props => {
  if (props.holdings.length === 0) {
    return (
      <div className={`${HoldingsTableStyles['tbl-holdings']}`}>
        <div className={`${HoldingsTableStyles['no-data']}`}>
          Add securities to get started.
        </div>
      </div>
    )
  }

  function renderSortIcon (sortBy) {
    return props.sortBy.columnName === sortBy &&
    <span className={`${WatchlistsStyles[ 'arrow-' + props.sortBy.order ]}`} />
  }

  return (
    <div className={`${HoldingsTableStyles['tbl-holdings']} ${HoldingsTableStyles['tbl-performance']}`}>
      <div className={`${HoldingsTableStyles['header']} ${HoldingsTableStyles['row']}`} data-sort-by={props.sortBy.columnName}>
        <div className={`${HoldingsTableStyles['symbol']}`}>&nbsp;</div>

        <div className={`${HoldingsTableStyles['range-52week']}`}>
          52-Week Range
        </div>

        <div className={`${HoldingsTableStyles['volume']}`}>&nbsp;</div>
      </div>

      <div className={`${HoldingsTableStyles['header']} ${HoldingsTableStyles['header-1']} ${HoldingsTableStyles['row']}`}>
        <div className={`${HoldingsTableStyles['symbol']}`}>
          { renderSortIcon('symbol') }
          <span
            data-col-name='symbol'
            data-col-type='String'
            onClick={props.onColHeaderClick}
            className={`${HoldingsTableStyles['col-header']}`}>
            Symbol
          </span>
        </div>

        <div className={`${HoldingsTableStyles['range-52week-low-high']}`}>
          <div className={`${HoldingsTableStyles['low']}`}>Low</div>
          <div className={`${HoldingsTableStyles['high']}`}>High</div>
        </div>

        <div className={`${HoldingsTableStyles['volume']}`}>
          { renderSortIcon('tradingVolume') }
          <span
            data-col-name='tradingVolume'
            data-col-type='Number'
            onClick={props.onColHeaderClick}
            className={`${HoldingsTableStyles['col-header']}`}>
            Volume
          </span>
        </div>

      </div>

      <div data-symbols-container='true'>
        {
          props.holdings.map((item, index) => (
            <div className={`${HoldingsTableStyles['row']} ${HoldingsTableStyles['data-row']}`}
              key={item.xid} data-holding-row={item.holdingId}>
              <div className={`${HoldingsTableStyles['symbol']}`}>
                <div className={`${HoldingsTableStyles['name']}`}
                  data-wsod-issue={item.xid} data-el-identifier='holding-performance-symbol_value'
                  data-symbol={mapIndicesSymbol(item.xid, item.symbol)}
                  data-return-url={'/Watchlist/' + props.watchlistInfo.id + '/' + props.watchlistInfo.name + '?tab=Performance'}
                  onClick={props.onSymbolClick}>
                  { mapIndicesSymbol(item.xid, item.symbol) }
                </div>
              </div>

              <div className={`${HoldingsTableStyles['range-52week-low-high']}`}>
                <div className={`${WatchlistsStyles['slider-container']} ${HoldingsTableStyles['slider-container']}`}>
                  <input type='range' step='any' disabled
                    min={item.low52W}
                    max={item.high52W}
                    value={item.quote || ''}
                    className={`${WatchlistsStyles['slider']}`} />
                </div>
                <div className={`${HoldingsTableStyles['low-high-container']}`}>
                  <div className={`${HoldingsTableStyles['low']}`}>
                    { Format.formatNumber(item.low52W, {currencyPreFix: true}) }
                  </div>
                  <div className={`${HoldingsTableStyles['high']}`}>
                    { Format.formatNumber(item.high52W, {currencyPreFix: true}) }
                  </div>
                </div>
              </div>

              <div className={`${HoldingsTableStyles['volume']}`}>
                { Format.abbreviateNumberOnlyAboveMillion(item.tradingVolume, 2) }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

HoldingsPerformanceTable.propTypes = {
  holdings: PropTypes.array,
  onSymbolClick: PropTypes.func,
  onColHeaderClick: PropTypes.func,
  sortBy: PropTypes.object,
  watchlistInfo: PropTypes.object
}

export default HoldingsPerformanceTable
