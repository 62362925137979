import 'babel-polyfill'
import React from 'react'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
import WatchlistCardsContainer from './SubComponents/WatchlistCardsContainer'
import WatchlistStyles from '../../styles/watchlist/watchlists.scss'
import Notification from './SubComponents/Notification'
import Header from './SubComponents/Header'
import Footer from './SubComponents/Footer'
import Watchlist from './SubComponents/Watchlist'
import WatchlistMobile from './SubComponents/Mobile/Watchlist'
import CreateWatchlist from './SubComponents/CreateWatchlist'
import DeleteWatchlist from './SubComponents/DeleteWatchlist'
import ManageWatchlists from './SubComponents/Mobile/ManageWatchlists'
import Snapshot from '../snapshot.V2/Container'
import { SnapshotModalPopUp, ModalPopUp, UserTypeIndicator } from 'stifel-intyce-pattern-library'
import { getUrlParameterValueByName } from '../../utils/urlMethods'
import * as UsageReporting from '../../utils/usageReporting'
import { updateParentHeightForMobileView, SetParentHeight, isMobileView, GetLocalStorageIfAvailable, SetSpecificKeysIntoLocalStorage, ClearSpecificKeysFromLocalStorage } from '../common.v2/CommonMethods'
import { WATCHLIST_CONSTANTS, API_END_POINTS, MAX_WIDTH_TO_OPEN_SNAPSHOT_IN_INPAGE, WATCHLISTS_SELECTED_CONSTANTS, SNAPSHOT_WSODISSUE_CONSTANTS, SNAPSHOT_SYMBOL_CONSTANTS, SNAPSHOT_ACTIVETAB_CONSTANTS, SNAPSHOT_NEWS_CONSTANTS, WATCHLISTS_WATCHLISTTAB_CONSTANTS, LOCALSTORAGETYPE_CONSTANTS } from '../../utils/appConstants'
import $ from 'jquery'
import commonStyles from '../../styles/common.v2/common.scss'

export default class Watchlists extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedWatchlist: this.setWatchList(),
      isMobileView: false,
      symbol: null,
      wsodIssue: null,
      isSnaphotModalPopupOpen: 0,
      notification: {
        show: false,
        message: null,
        type: null
      },
      isModalOpen: 0,
      modalComponent: null,
      modalHeight: null,
      showManageWatchlist: false,
      manageWatchlists: {
        deletedWatchlist: [],
        deletingWatchlist: false
      }
    }

    this.props.setFetchingDataFlag({
      watchlistsData_Platform: {
        fetching: true
      }
    })

    this.handleResize = this.handleResize.bind(this)
    this.setIsMobileView = this.setIsMobileView.bind(this)
    this.handleWatchlistSelection = this.handleWatchlistSelection.bind(this)
    this.loadSymbolSnapshot = this.loadSymbolSnapshot.bind(this)
    this.loadSymbolSnapshotEnter = this.loadSymbolSnapshotEnter.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.notificationHandler = this.notificationHandler.bind(this)
    this.openModel = this.openModel.bind(this)
    this.closeModel = this.closeModel.bind(this)
    this.createWatchlist = this.createWatchlist.bind(this)
    this.createWatchlistEnter = this.createWatchlistEnter.bind(this)
    this.deleteWatchlist = this.deleteWatchlist.bind(this)
    this.deleteWatchlistEnter = this.deleteWatchlistEnter.bind(this)
    this.deleteWatchlistMobile = this.deleteWatchlistMobile.bind(this)
    this.toggleManageWatchlist = this.toggleManageWatchlist.bind(this)
    this.doneManageWatchlist = this.doneManageWatchlist.bind(this)
    this.resetManageWatchlistState = this.resetManageWatchlistState.bind(this)
    UsageReporting.trackPage('Tracker Watchlist', '')
  }

  componentDidMount () {
    this.setIsMobileView()
    this.props.requestWatchlists()
    window.addEventListener('resize', this.handleResize)
    updateParentHeightForMobileView(130)
    let localStorage = GetLocalStorageIfAvailable(LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS)
    if (localStorage) {
      this.loadSymbolSnapshot(null, localStorage)
    }
  }

  componentDidUpdate (prevProps) {
    if (!this.state.isMobileView) {
      SetParentHeight()
    } else {
      updateParentHeightForMobileView(130)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.deleteWatchlistsResponse) {
      this.toggleManageWatchlist()
      this.props.requestWatchlists()
      this.resetManageWatchlistState()
      this.props.setFetchingDataFlag({ multipleAPICallsResponse: null })
    }
  }

  setIsMobileView () {
    this.setState({ isMobileView: isMobileView() })
  }

  handleResize () {
    this.setIsMobileView()
  }

  handleWatchlistSelection (watchlistInfo) {
    let ssKeysToRemove = [
      'watchlist-holdings-start-index',
      'watchlist-holdings-page-size',
      'watchlist-holdings-page-number',
      'watchlist-holdings-sorting-order',
      'watchlist-holdings-sorting-column',
      'watchlist-holdings-sorting-dataType'
    ]
    ClearSpecificKeysFromLocalStorage(LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS, [
      SNAPSHOT_WSODISSUE_CONSTANTS,
      SNAPSHOT_SYMBOL_CONSTANTS,
      SNAPSHOT_ACTIVETAB_CONSTANTS,
      SNAPSHOT_NEWS_CONSTANTS,
      WATCHLISTS_WATCHLISTTAB_CONSTANTS
    ])
    ssKeysToRemove.map((item, index) => {
      sessionStorage.removeItem(item)
    })
    let obj = {
      id: watchlistInfo.id,
      name: watchlistInfo.name,
      tab: watchlistInfo.tab
    }
    SetSpecificKeysIntoLocalStorage(LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS, {[WATCHLISTS_SELECTED_CONSTANTS]: obj})
    this.setState({ selectedWatchlist: watchlistInfo })

    if (watchlistInfo.id) {
      hashHistory.push(`/Watchlist/${watchlistInfo.id}/${watchlistInfo.name}`)
    }
  }

  loadSymbolSnapshot (e, localStorage) {
    let wsodIssue
    let symbol
    let el
    if (e) {
      e.preventDefault()
      el = e.currentTarget
      wsodIssue = el.getAttribute('data-wsod-issue')
      symbol = el.getAttribute('data-symbol')
    } else if (localStorage) {
      wsodIssue = localStorage[SNAPSHOT_WSODISSUE_CONSTANTS]
      symbol = localStorage[SNAPSHOT_SYMBOL_CONSTANTS]
      if (!wsodIssue) {
        return
      }
    }
    let returnUrl = el && el.getAttribute('data-return-url') ? el.getAttribute('data-return-url') : '/Watchlists'
    if (window.innerWidth < MAX_WIDTH_TO_OPEN_SNAPSHOT_IN_INPAGE) {
      window.removeEventListener('resize', this.handleResize)
      hashHistory.push(`/Snapshot/V2/${symbol}/${wsodIssue}?returnUrl=${returnUrl}&page=${LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS}`)
    } else {
      this.setState((prevState) => {
        return {
          symbol: symbol,
          wsodIssue: wsodIssue,
          isSnaphotModalPopupOpen: 1
        }
      })
    }
  }

  loadSymbolSnapshotEnter (e) {
    if (e.key === 'Enter') {
      this.loadSymbolSnapshot(e)
    }
  }

  closePopup (e) {
    e.preventDefault()
    if (e.target.getAttribute('id') === 'closeButton' ||
      e.key === 'Escape' || e.keyCode === 27) {
      ClearSpecificKeysFromLocalStorage(LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS, [
        SNAPSHOT_WSODISSUE_CONSTANTS,
        SNAPSHOT_SYMBOL_CONSTANTS,
        SNAPSHOT_ACTIVETAB_CONSTANTS,
        SNAPSHOT_NEWS_CONSTANTS
      ])
      this.setState((prevState) => {
        return {isSnaphotModalPopupOpen: 0}
      })
    }
  }

  notificationHandler (notification) {
    this.setState({ notification: notification })
  }

  openModel () {
    this.setState({
      isModalOpen: 1
    })
  }

  closeModel () {
    this.setState({
      isModalOpen: 0
    })
  }

  createWatchlist (e) {
    e.preventDefault()
    let el = e.currentTarget
    let watchlistName = el.getAttribute('data-watchlist-name')
    let watchlistId = el.getAttribute('data-watchlist-id')
    this.openModel()
    this.setState({
      modalHeight: '192px',
      modalComponent: <CreateWatchlist
        watchlistName={watchlistName}
        watchlistId={watchlistId}
        onCancel={this.closeModel}
        onSuccessHandler={this.props.requestWatchlists}
        notificationHandler={this.notificationHandler} />
    })
  }
  createWatchlistEnter (e) {
    if (e.key === 'Enter') {
      this.createWatchlist(e)
    }
  }
  deleteWatchlist (e) {
    e.preventDefault()
    let el = e.currentTarget
    let watchlistName = el.getAttribute('data-watchlist-name')
    let watchlistId = el.getAttribute('data-watchlist-id')
    this.openModel()
    this.setState({
      modalHeight: '142px',
      modalComponent: <DeleteWatchlist
        watchlistName={watchlistName}
        watchlistId={watchlistId}
        onCancel={this.closeModel}
        requestWatchlists={this.props.requestWatchlists}
        notificationHandler={this.notificationHandler} />
    })
  }

  deleteWatchlistEnter (e) {
    if (e.key === 'Enter') {
      this.deleteWatchlist(e)
    }
  }

  toggleManageWatchlist () {
    this.setState(prevState => ({
      showManageWatchlist: !prevState.showManageWatchlist
    }))
  }

  resetManageWatchlistState () {
    this.setState({
      manageWatchlists: {
        deletedWatchlist: [],
        deletingWatchlist: false
      }
    })
  }

  deleteWatchlistMobile (e) {
    e.preventDefault()
    let el = e.currentTarget
    let watchlistId = el.getAttribute('data-watchlist-id')

    if (this.state.manageWatchlists.deletedWatchlist.indexOf(watchlistId) === -1) {
      this.state.manageWatchlists.deletedWatchlist.push(watchlistId)
    }

    $(el).closest('[data-watchlist-row=true]').remove()
  }

  doneManageWatchlist (e) {
    e.preventDefault()
    let el = e.currentTarget

    if (el.getAttribute('data-cancel')) {
      this.toggleManageWatchlist()
      this.resetManageWatchlistState()
      return
    }

    if (this.state.manageWatchlists.deletedWatchlist.length > 0) {
      let apiInputs = []

      this.state.manageWatchlists.deletedWatchlist.map((wId) => {
        apiInputs.push({
          resultKey: 'deleteWatchlistResponse_' + wId,
          endPoint: `${API_END_POINTS.WATCHLIST_PLATFORM}/${wId}`,
          params: { },
          ajaxType: 'DELETE'
        })
      })
      this.setState({
        manageWatchlists: {
          deletingWatchlist: true
        }
      })
      this.props.requestMultipleAPICalls(apiInputs)
    }
  }
  setWatchList () {
    let obj = {
      id: this.props.params.watchlistId,
      name: this.props.params.watchlistName || WATCHLIST_CONSTANTS.WATCHLIST_OVERVIEW,
      selectedTab: getUrlParameterValueByName('tab') || 'Basic'
    }
    let localStorage = GetLocalStorageIfAvailable(LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS, WATCHLISTS_SELECTED_CONSTANTS)
    if (localStorage) {
      obj.id = localStorage.id
      obj.name = localStorage.name && localStorage.name.length > 0 ? localStorage.name : WATCHLIST_CONSTANTS.WATCHLIST_OVERVIEW
      obj.selectedTab = localStorage.tab && localStorage.tab.length > 0 ? localStorage.tab : 'Basic'
      if (obj.id) {
        hashHistory.push(`/Watchlist/${obj.id}/${obj.name}`)
      }
    }
    return obj
  }
  render () {
    if (this.props.fetching) {
      return (
        <div className={`${WatchlistStyles['watchlists-parent-container']}`}>
          <div className={`${commonStyles['loader']}`}>
            <div className={`${commonStyles['rollingloader']}`} />
          </div>
        </div>
      )
    }

    if (this.props.isErrorOccurred) {
      return (
        <div className={`${WatchlistStyles['watchlists-parent-container']}`}>
          <div className={`${WatchlistStyles['error-container']}`}>
            <div>
              Oops! There was an error processing your request.<br />
              <div className={`${WatchlistStyles['error-info']}`}>
                (Error Code: {this.props.errorInfo.code}, Message: {this.props.errorInfo.message})
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className={`${WatchlistStyles['watchlists-parent-container']}`}>
        <Notification show={this.state.notification.show} message={this.state.notification.message} type={this.state.notification.type} notificationHandler={this.notificationHandler} />
        <Header
          watchlists={this.props.watchlists}
          selectedWatchlist={this.state.selectedWatchlist}
          handleWatchlistSelection={this.handleWatchlistSelection} />

        {
          !this.state.isMobileView && this.state.selectedWatchlist.id &&
            <Watchlist watchlistInfo={this.state.selectedWatchlist}
              onSymbolClick={this.loadSymbolSnapshot}
              onSymbolClickEnter={this.loadSymbolSnapshotEnter}
              isMobileView={this.state.isMobileView}
              notificationHandler={this.notificationHandler} />
        }

        {/* {
          this.state.isMobileView && this.props.watchlists.length === 0 &&
            <div className={`${WatchlistStyles['watchlists-msg']}`}>
              {WATCHLIST_MESSAGES.WATCHLIST_NOT_EXISTS}
            </div>
        } */}
        {
          this.state.isMobileView && this.state.selectedWatchlist.id &&
            <WatchlistMobile
              selectedTab={this.state.selectedWatchlist.selectedTab}
              watchlistInfo={this.state.selectedWatchlist}
              onSymbolClick={this.loadSymbolSnapshot}
              goBackHandler={this.handleWatchlistSelection}
              notificationHandler={this.notificationHandler}
              requestWatchlists={this.props.requestWatchlists} />
        }

        {
          !this.state.selectedWatchlist.id &&
          <WatchlistCardsContainer
            watchlists={this.props.watchlists}
            requestWatchlists={this.props.requestWatchlists}
            onSymbolClick={this.loadSymbolSnapshot}
            onSymbolClickEnter={this.loadSymbolSnapshotEnter}
            handleWatchlistSelection={this.handleWatchlistSelection}
            notificationHandler={this.notificationHandler}
            createWatchlist={this.createWatchlist}
            createWatchlistEnter={this.createWatchlistEnter}
            deleteWatchlist={this.deleteWatchlist}
            deleteWatchlistEnter={this.deleteWatchlistEnter}
            isMobileView={this.state.isMobileView} />
        }

        {
          this.state.isMobileView && !this.state.selectedWatchlist.id &&
          <Footer createWatchlist={this.createWatchlist}
            manageWatchlists={this.toggleManageWatchlist}
            watchlistCount={this.props.watchlists.length} />
        }
        {
          !this.state.selectedWatchlist.id && !this.state.isMobileView &&
          <div className={`${WatchlistStyles['user-type-indicator']}`}>
            <UserTypeIndicator userType='Tracker' />
          </div>
        }

        {
          this.state.isSnaphotModalPopupOpen === 1 &&
          <SnapshotModalPopUp
            onClick={this.closePopup}
            isOpen={this.state.isSnaphotModalPopupOpen}>
            <Snapshot wsodIssue={this.state.wsodIssue} symbol={this.state.symbol} page={LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS} />
          </SnapshotModalPopUp>
        }
        {
          this.state.isModalOpen === 1 &&
          <ModalPopUp
            height={this.state.modalHeight}
            onClick={() => { }}
            isOpen={this.state.isModalOpen}>
            {this.state.modalComponent}
          </ModalPopUp>
        }
        { this.state.showManageWatchlist &&
          <ManageWatchlists
            watchlists={this.props.watchlists}
            deletingWatchlist={this.state.manageWatchlists.deletingWatchlist}
            deleteWatchlistMobile={this.deleteWatchlistMobile}
            doneManageWatchlist={this.doneManageWatchlist} />

        }
      </div>
    )
  }
}

Watchlists.propTypes = {
  fetching: PropTypes.bool.isRequired,
  setFetchingDataFlag: PropTypes.func.isRequired,
  requestWatchlists: PropTypes.func.isRequired,
  isErrorOccurred: PropTypes.bool.isRequired,
  errorInfo: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  watchlists: PropTypes.array.isRequired,
  requestMultipleAPICalls: PropTypes.func,
  deleteWatchlistsResponse: PropTypes.object
}
