import { connect } from 'react-redux'
import Snapshot from './Snapshot'
import * as SnapshotV2Actions from '../../../actions/snapshot.V2.Actions'
import { ISSUE_TYPES_MAP } from '../../../utils/appConstants'

function mapStateToProps (state) {
  return {
    fetching: state.snapshotV2Reducer.fetching,
    quoteData: state.snapshotV2Reducer.quoteData,
    isErrorOccurred: state.snapshotV2Reducer.quoteData_Platform.error.code > 0,
    errorInfo: state.snapshotV2Reducer.quoteData_Platform.error,
    isMarketOpen: state.snapshotV2Reducer.quoteData.marketOpen === 1,
    quoteData_Platform: state.snapshotV2Reducer.quoteData_Platform.data.quotes[0].data,
    securityType: ISSUE_TYPES_MAP[state.snapshotV2Reducer.quoteData_Platform.data.quotes[0].data.instrumentIssueType],
    symbolXREFData: state.snapshotV2Reducer.symbolXREFData_Platform
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(SnapshotV2Actions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestSnapshotV2Data: (wsodIssue, symbol) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2Data(wsodIssue, symbol))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snapshot)
