// This is the root reducer in which all other reducers are registered.
// Naming convention is to use index.js
import {combineReducers} from 'redux'
import snapshotV2Reducer from './snapshot.V2.Reducer'
import marketsV2Reducer from './markets.V2.Reducer'
import watchlistReducer from './watchlist.Reducer'

const rootReducer = combineReducers({
  snapshotV2Reducer,
  marketsV2Reducer,
  watchlistReducer
})

export default rootReducer
