/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'

class SparkChart extends React.Component {
  constructor (props) {
    super(props)
    this.getChart = this.getChart.bind(this)
    this.getColor = this.getColor.bind(this)
  }
  getColor (value) {
    const classNameColor = value < 0 && value !== -32768
      ? this.props.style.negative
      : value > 0
        ? this.props.style.positive
        : ''
    return classNameColor
  }

  getChartWorkUrl (encoded, token) {
    return window.MD.CHARTWORKS_API_URL + '/Chart/sparkLine?inputs=' + encoded + '&access_token=' + token
  }

  getChart () {
    let token = localStorage.getItem('access_token')
    let chartInputs = {
      'xid': this.props.wsodIssue && this.props.wsodIssue.toString(),
      'realTime': false,
      'numDays': 30,
      'height': this.props.style.height,
      'width': 200,
      'priceLineColor': this.getColor(this.props.changeAmount),
      'priceLineWidth': 2,
      'priceNegativeColor': this.props.style.negative,
      'pricePositiveColor': this.props.style.positive,
      'showChange': !this.props.isMarketOpen
    }
    // Stringify & Encode Inputs
    let encoded = encodeURIComponent(JSON.stringify(chartInputs, null))
    // Create API call
    return this.getChartWorkUrl(encoded, token)
  }

  render () {
    return <img data-el-identifier='sparkchart' alt='Spark Chart' src={this.getChart()} />
  }
}

SparkChart.propTypes = {
  wsodIssue: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  isMarketOpen: PropTypes.bool.isRequired,
  changeAmount: PropTypes.number.isRequired
}

export default SparkChart
