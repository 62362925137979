import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import overviewMFStyles from '../../../../styles/snapshot.V2/overviewMF.scss'
import * as SnapshotV2Actions from '../../../../actions/snapshot.V2.Actions'
import { RenderTextWithHeading, Format, ExternalLink } from 'stifel-intyce-pattern-library'
import { DATE_TIME_CONSTANTS } from '../../../../utils/appConstants'
import commonStyles from '../../../../styles/common.v2/common.scss'
import { globalConfig } from '../../../../utils/globalConfig'

class OverviewMF extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      wsodIssue: null,
      selectedChartTimeframe: '1Y'
    }

    this.props.setFetchingDataFlag({
      mfOverviewData: {
        data: {
          fetching: true
        }
      }}
    )
    this.handleChartTimeframeButtonEvent = this.handleChartTimeframeButtonEvent.bind(this)
    this.getChartTimeframeButtonSelectedClass = this.getChartTimeframeButtonSelectedClass.bind(this)
  }

  componentDidMount () {
    let wsodIssue = this.props.wsodIssue ? this.props.wsodIssue : this.props.params.wsodissue

    this.setState({
      wsodIssue
    }, () => {
      this.props.setFetchingDataFlag({
        mfOverviewData: {
          data: {
            mfOverview10KGrowthChart: {
              fetching: true
            }
          }
        }}
      )
      this.props.requestSnapshotV2MFOverviewData(this.state.wsodIssue, this.state.selectedChartTimeframe)
    })
    this.props.handleResize()
  }

  getChartTimeframeButtonSelectedClass (btnValue) {
    return this.state.selectedChartTimeframe === btnValue ? overviewMFStyles['selected'] : ''
  }

  handleChartTimeframeButtonEvent (e) {
    e.preventDefault()
    let selectedChartTimeframe = e.target.getAttribute('data-value')

    this.setState({
      selectedChartTimeframe
    }, () => {
      this.props.setFetchingDataFlag({
        mfOverviewData: {
          data: {
            mfOverview10KGrowthChart: {
              fetching: true
            }
          }
        }}
      )
      this.props.requestSnapshotV2MFGrowth10KChartData(this.state.wsodIssue, this.state.selectedChartTimeframe)
    })
  }

  render () {
    if (this.props.fetching) {
      return (
        <div className={`${overviewMFStyles['mf-overview-container']}`}>
          <div className={`${commonStyles['loader']} ${commonStyles['loader-no-border']}`}>
            <div className={`${commonStyles['rollingloader']} ${overviewMFStyles['mf-loader']} ${commonStyles['rollingloader-pos']}`} />
          </div>
        </div>
      )
    }

    return (
      <div className={`${overviewMFStyles['mf-overview-container']}`}>
        {this.props.fundObjective &&
          <div className={`${overviewMFStyles['fund-objective-container']}`}>
            <RenderTextWithHeading
              heading='Fund Objective'
              content={this.props.fundObjective} />
          </div>
        }
        <div className={`${overviewMFStyles['growth10k-chart-parent-container']}`}>
          <div className={`${overviewMFStyles['chart-filter-buttons']}`}>
            <ul>
              <li>
                <button id='btn-90' data-value='3M' name='timeframe-option'
                  className={this.getChartTimeframeButtonSelectedClass('3M')}
                  onClick={this.handleChartTimeframeButtonEvent}>
                  3M
                </button>
              </li>
              <li>
                <button id='btn-180' data-value='6M' name='timeframe-option'
                  className={this.getChartTimeframeButtonSelectedClass('6M')}
                  onClick={this.handleChartTimeframeButtonEvent}>
                  6M
                </button>
              </li>
              <li>
                <button id='btn-365' data-value='1Y' name='timeframe-option'
                  className={this.getChartTimeframeButtonSelectedClass('1Y')}
                  onClick={this.handleChartTimeframeButtonEvent}>
                  1Y
                </button>
              </li>
              <li>
                <button id='btn-1095' data-value='3Y' name='timeframe-option'
                  className={this.getChartTimeframeButtonSelectedClass('3Y')}
                  onClick={this.handleChartTimeframeButtonEvent}>
                  3Y
                </button>
              </li>
              <li>
                <button id='btn-1825' data-value='5Y' name='timeframe-option'
                  className={this.getChartTimeframeButtonSelectedClass('5Y')}
                  onClick={this.handleChartTimeframeButtonEvent}>
                  5Y
                </button>
              </li>
              <li className={`${overviewMFStyles['lbl-growth-of10k']}`}>
                Growth of $10,000
              </li>
            </ul>
          </div>
          <div className={`${overviewMFStyles['growth10k-chart-container']}`}>
            {
              this.props.fetchingChart &&
                <div className={`${overviewMFStyles['mf-overview-container']}`}>
                  <div className={`${commonStyles['loader']} ${commonStyles['loader-no-border']}`}>
                    <div className={`${commonStyles['rollingloader']} ${overviewMFStyles['mf-loader']}`} />
                  </div>
                </div>
            }

            {
              !this.props.fetchingChart &&
                <div>
                  <img
                    alt='Price timeline chart' data-type='desktop'
                    className={`${overviewMFStyles['growth10k-chart-desktop']}`}
                    src={this.props.mfOverview10KGrowthChart.chartUrl} />
                  <img
                    alt='Price timeline chart' data-type='ipad'
                    className={`${overviewMFStyles['growth10k-chart-ipad']}`}
                    src={this.props.mfOverview10KGrowthChart.chartUrlIpad} />
                  <img
                    alt='Price timeline chart' data-type='mobile'
                    className={`${overviewMFStyles['growth10k-chart-mobile']}`}
                    src={this.props.mfOverview10KGrowthChart.chartUrlMobile} />
                </div>
            }
          </div>
          <div className={`${overviewMFStyles['bottom-container']}`}>
            <span data-el-identifier='overview-mf-date_footer' className={`${overviewMFStyles['date']}`}>
              {Format.getFormattedDateTime(this.props.dateAsOf, {preFix: DATE_TIME_CONSTANTS.AS_OF_PREFIX, tzConversion: true})}
            </span>
            <span className={`${overviewMFStyles['disclosures']}`}>
              <ExternalLink text='Important Disclosures' url={`${globalConfig.disclosureDocumentsLink_Navigator_10K}`} />
            </span>
          </div>
        </div>
      </div>
    )
  };
};

OverviewMF.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fetchingChart: PropTypes.bool.isRequired,
  wsodIssue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  fundObjective: PropTypes.string.isRequired,
  params: PropTypes.object,
  mfOverview10KGrowthChart: PropTypes.object.isRequired,
  requestSnapshotV2MFOverviewData: PropTypes.func.isRequired,
  requestSnapshotV2MFGrowth10KChartData: PropTypes.func.isRequired,
  setFetchingDataFlag: PropTypes.func.isRequired,
  handleResize: PropTypes.func,
  dateAsOf: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  return {
    fetching: state.snapshotV2Reducer.mfOverviewData.data.fetching,
    fetchingChart: state.snapshotV2Reducer.mfOverviewData.data.mfOverview10KGrowthChart.fetching,
    fundObjective: state.snapshotV2Reducer.mfOverviewData.data.fundObjective,
    mfOverview10KGrowthChart: state.snapshotV2Reducer.mfOverviewData.data.mfOverview10KGrowthChart
  }
}

function mapDispatchToProps (dispatch) {
  return {
    requestSnapshotV2MFOverviewData: (wsodIssue, chartTimeFrame) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2MFOverviewData(wsodIssue, chartTimeFrame))
    },
    requestSnapshotV2MFGrowth10KChartData: (wsodIssue, chartTimeFrame) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2MFGrowth10KChartData(wsodIssue, chartTimeFrame))
    },
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(SnapshotV2Actions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewMF)
