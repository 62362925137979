import React from 'react'
import PropTypes from 'prop-types'
import MarketMoversTableRow from './MarketMoversTableRow'
import MarketMoversTableHeader from './MarketMoversTableHeader'
import marketMoversTable from '../../styles/common.v2/marketMoversTable.scss'

const MarketMoversTable = props => {
  if (props.marketMovers.length === 0) {
    return (
      <div className={`${marketMoversTable['tbl-market-movers']}`}>
        <div className={`${marketMoversTable['no-data']}`}>
          Oops! We couldn't find related data.
        </div>
      </div>
    )
  }

  return (
    <div className={`${marketMoversTable['tbl-market-movers']}`}>
      <div className={`${marketMoversTable['header']} ${marketMoversTable['row']}`}>
        <div className={`${marketMoversTable['symbol']}`}>&nbsp;</div>
        <div className={`${marketMoversTable['last']}`}>&nbsp;</div>
        <div className={`${marketMoversTable['change']}`}>&nbsp;</div>
        <div className={`${marketMoversTable['range-52week']}`}>
          52-Week Range
        </div>
        <div className={`${marketMoversTable['volume']}`}>&nbsp;</div>
      </div>

      <MarketMoversTableHeader rankType={props.rankType} />
      {
        props.marketMovers.map((item, index) => (
          <MarketMoversTableRow key={index} data={item} onClick={props.onClick} />
        ))
      }
    </div>
  )
}

MarketMoversTable.propTypes = {
  marketMovers: PropTypes.array,
  rankType: PropTypes.string,
  onClick: PropTypes.func
}

export default MarketMoversTable
