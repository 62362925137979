import { detectEnvByDomain } from './appConstants'
const dev = {
  topmover: true,
  addtowatchlists: true,
  disclosureDocumentsLink_Tracker_10K: 'https://qa.stifel.com/tracker/Disclosures?hideheaderfooter=true#10000GrowthChartDisclosures',
  disclosureDocumentsLink_Tracker_PricingAndFees: 'https://qa.stifel.com/Tracker/Disclosures?hideheaderfooter=true#PricingAndFeesDisclosures',
  disclosureDocumentsLink_Navigator_10K: 'https://qa.stifel.com/navigator/Disclosures?hideheaderfooter=true#10000GrowthChartDisclosures',
  disclosureDocumentsLink_Navigator_PricingAndFees: 'https://qa.stifel.com/navigator/Disclosures?hideheaderfooter=true#PricingAndFeesDisclosures'
}
const acc = {
  topmover: true,
  addtowatchlists: true,
  disclosureDocumentsLink_Tracker_10K: 'https://qa.stifel.com/tracker/Disclosures?hideheaderfooter=true#10000GrowthChartDisclosures',
  disclosureDocumentsLink_Tracker_PricingAndFees: 'https://qa.stifel.com/Tracker/Disclosures?hideheaderfooter=true#PricingAndFeesDisclosures',
  disclosureDocumentsLink_Navigator_10K: 'https://qa.stifel.com/navigator/Disclosures?hideheaderfooter=true#10000GrowthChartDisclosures',
  disclosureDocumentsLink_Navigator_PricingAndFees: 'https://qa.stifel.com/navigator/Disclosures?hideheaderfooter=true#PricingAndFeesDisclosures'
}
const acc2 = {
  topmover: true,
  addtowatchlists: true,
  disclosureDocumentsLink_Tracker_10K: 'https://qa.stifel.com/tracker/Disclosures?hideheaderfooter=true#10000GrowthChartDisclosures',
  disclosureDocumentsLink_Tracker_PricingAndFees: 'https://qa.stifel.com/Tracker/Disclosures?hideheaderfooter=true#PricingAndFeesDisclosures',
  disclosureDocumentsLink_Navigator_10K: 'https://qa.stifel.com/navigator/Disclosures?hideheaderfooter=true#10000GrowthChartDisclosures',
  disclosureDocumentsLink_Navigator_PricingAndFees: 'https://qa.stifel.com/navigator/Disclosures?hideheaderfooter=true#PricingAndFeesDisclosures'
}
const prod = {
  topmover: true,
  addtowatchlists: true,
  disclosureDocumentsLink_Tracker_10K: 'https://www.stifel.com/tracker/disclosures?hideheaderfooter=true#10000GrowthChartDisclosures',
  disclosureDocumentsLink_Tracker_PricingAndFees: 'https://www.stifel.com/Tracker/Disclosures?hideheaderfooter=true#PricingAndFeesDisclosures',
  disclosureDocumentsLink_Navigator_10K: 'https://www.stifel.com/navigator/disclosures?hideheaderfooter=true#10000GrowthChartDisclosures',
  disclosureDocumentsLink_Navigator_PricingAndFees: 'https://www.stifel.com/navigator/disclosures?hideheaderfooter=true#PricingAndFeesDisclosures'
}

export const globalConfig = (() => {
  const env = detectEnvByDomain
  switch (env) {
    case 'DEV':
      return dev
    case 'ACC':
      return acc
    case 'ACC2':
      return acc2
    case 'PROD':
      return prod
    default:
      return prod
  }
})()
