/**
 * appMethods.js defines common methods used throughout the react app
 */

import Axios from '../components/common.v2/AxiosWrapper'
import * as DataRequestConstants from './dataRequestConstants'
import AccessTokenService from '../services/access-token-service'

/**
* Method used to get a common data request default state
* @return {Object} [DataRequestConstants.STATE_COMMON_DATA_REQUEST_DEFAULT]
*/
export const getStateCommonDataRequestDefault = () => {
  return JSON.parse(JSON.stringify(DataRequestConstants.STATE_COMMON_DATA_REQUEST_DEFAULT))
}

/**
 * Method used to get a data request error state
 * @param {Object} error [Error object]
 * @return {Object} [Error state properties for component making data requests]
 */
export const getStateDataRequestError = (error) => {
  let state = getStateCommonDataRequestDefault()

  state.dataRequestError = error || {}
  state.isDataRequestComplete = true

  return state
}

/**
 * Method used to get a data request valid data state
 * @param {Object} data [Data returned in an API data request]
 * @param {Object} url [URL used to make an API data request]
 * @return {Object} [Valid data state properties for a component making data requests]
 */
export const getStateDataRequestValidData = (data, url) => {
  let state = getStateCommonDataRequestDefault()

  state.data = data || {}
  state.isDataRequestComplete = true
  state.dataRequestUrl = url

  return state
}

/**
* Method used to get a data request no data state
* @return {Object} [No data state properties for a component making data requests]
*/
export const getStateDataRequestNoData = () => {
  let state = getStateCommonDataRequestDefault()

  state.isDataRequestComplete = true

  return state
}

/**
 * Method used to execute a data request
 * @param {String} url [Fully qualified URL for a data request]
 * @param {String} method [Request method, defaults to 'GET']
 * @param {Function} callback [Callback to which a response will be passed]
 * @param {Object} params [URL parameters as key value pairs]
 */
export const executeDataRequest = (url, method, cancelToken, callback, params) => {
  if (!callback) {
    return null
  }

  if (!url) {
    return callback(getStateDataRequestError(DataRequestConstants.RESPONSE_BAD_REQUEST))
  }

  let urlWithParams = url

  if (params && Object.keys(params).length) {
    const urlParamsStartChar = url.indexOf('?') > -1 ? '&' : '?'
    urlWithParams += urlParamsStartChar

    // Iterate over params and add to URL
    Object.keys(params).forEach(key => {
      urlWithParams += key + '=' + params[key] + '&'
    })

    // Remove unecessary '&' from end of URL
    urlWithParams = urlWithParams.replace(/&$/, '')
  }

  const config = {
    method: method || 'GET',
    url: urlWithParams
  }
  const localAccessToken = ''
  let accessToken = AccessTokenService.retrieve(localAccessToken)
  // Add Authorization header if access token exists
  if (accessToken) {
    config.headers = {
      Authorization: 'Bearer ' + accessToken
    }
  }

  if (cancelToken && cancelToken.token) config.cancelToken = cancelToken.token

  Axios
    .request(config)
    .then(response => {
      const containsData =
                response &&
                response.data &&
                response.data.data

      if (containsData) {
        callback(getStateDataRequestValidData(response.data.data, url))
      } else {
        callback(getStateDataRequestNoData())
      }
    })
    .catch(error => {
      // Only exceute callback if request was not cancelled
      if (!Axios.isCancel(error)) callback(getStateDataRequestError(error.response))
    })
}

/**
* Method used to get a data request URL
* @param {String} endpoint [Data API endpoint with prepended '/']
* @return {String} [Fully qualified data request URL]
*/
export const getDataRequestUrl = (endpoint) => {
  if (window.MD.API_URL && endpoint) {
    let dataRequestUrl = window.MD.API_URL + endpoint
    const showDebugInfo = localStorage.getItem('showDebugInfo')

    if (dataRequestUrl && showDebugInfo === 'true') {
      const urlParamsStartChar = dataRequestUrl.indexOf('?') > -1 ? '&' : '?'
      // Data request URL must contain parameter ..showdebugdata..=on to get application/json response (showdebuginfo)
      dataRequestUrl = dataRequestUrl + urlParamsStartChar + '..showdebugdata..=on'
    }

    return dataRequestUrl
  }

  return null
}

/**
* Method used to execute a data request via an API path
* @param {String} path [API path]
* @param {String} method [Request method, defaults to 'GET']
* @param {Function} callback [Callback to which a response will be passed]
* @param {Object} params [URL parameters as key value pairs]
*/
export const executeDataRequestViaApiPath = (path, method, cancelToken, callback, params) => {
  if (!callback) {
    return null
  }

  if (!path) {
    return callback(getStateDataRequestError(DataRequestConstants.RESPONSE_BAD_REQUEST))
  }
  let responseUrl = getDataRequestUrl(path)
  if (responseUrl) {
    executeDataRequest(responseUrl, method, cancelToken, callback, params)
  } else {
    callback(getStateDataRequestError(DataRequestConstants.RESPONSE_BAD_REQUEST))
  }
}

/**
 * Method used to retrieve a cancel token
 * Components can request more than one token
 * @return {Object} [Contains a token that identifies the request and a cancel method]
 */
export const getCancelToken = () => Axios.CancelToken.source()

/**
 * Method used to get a common data request default content config
 * @return {Object} [DataRequestConstants.CONFIG_CONTENT_COMMON_DATA_REQUEST_DEFAULT]
 */
export const getConfigContentCommonDataRequestDefault = () => {
  return JSON.parse(JSON.stringify(DataRequestConstants.CONFIG_CONTENT_COMMON_DATA_REQUEST_DEFAULT))
}
