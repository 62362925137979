/* eslint-disable no-unexpected-multiline */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import WatchlistStyles from '../../../../styles/watchlist/watchlists.scss'
import applicationStyles from '../../../../styles/main.scss'
import $ from 'jquery'
import { WATCHLIST_CONSTANTS } from '../../../../utils/appConstants'

export default class Header extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isListOpen: false,
      selectedWatchlist: {
        id: null,
        name: WATCHLIST_CONSTANTS.WATCHLIST_OVERVIEW
      }
    }

    this.openCloseMenu = this.openCloseMenu.bind(this)
    this.openCloseMenuEnter = this.openCloseMenuEnter.bind(this)
    this.handleWatchlistClickEvent = this.handleWatchlistClickEvent.bind(this)
    this.handleWatchlistClickEventEnter = this.handleWatchlistClickEventEnter.bind(this)
  }

  componentDidMount () {
    let self = this

    $(document).on('click', function (event) {
      var $elDdlWatchlists = $('#ddlWatchlists')

      if ($elDdlWatchlists !== event.target &&
        !$elDdlWatchlists.has(event.target).length &&
        self.state.isListOpen) {
        self.setState({
          isListOpen: false
        })
      }
    })
  }

  openCloseMenu (e) {
    e && e.stopPropagation()

    this.setState({
      isListOpen: !this.state.isListOpen
    })
  }

  openCloseMenuEnter (e) {
    if (e.key === 'Enter') {
      this.openCloseMenu(e)
    }
  }

  componentWillReceiveProps () {
    this.setState({
      selectedWatchlist: this.props.selectedWatchlist
    })
  }

  handleWatchlistClickEvent (e) {
    e && e.stopPropagation()

    let objWatchlist = {
      id: e.currentTarget.getAttribute('data-watchlist-id'),
      name: e.currentTarget.getAttribute('data-watchlist-name')
    }
    this.setState({
      selectedWatchlist: objWatchlist
    }, () => {
      this.openCloseMenu()
      this.props.handleWatchlistSelection && this.props.handleWatchlistSelection(objWatchlist)
    })
  }

  handleWatchlistClickEventEnter (e) {
    if (e.key === 'Enter') {
      this.handleWatchlistClickEvent(e)
    }
  }

  render () {
    return (
      <div className={`${WatchlistStyles['header-parent-container']}`}>
        <div className={`${WatchlistStyles['header-container']} ${this.state.selectedWatchlist.id ? WatchlistStyles['watchlist-holdings'] : ''}`}>
          <div className={`${WatchlistStyles['watchlist-overview-header']} ${applicationStyles['pure-hidden-md']} ${applicationStyles['pure-hidden-lg']} ${applicationStyles['pure-hidden-xl']}`}>
            <span>{WATCHLIST_CONSTANTS.WATCHLIST_OVERVIEW}</span>
          </div>
          <div className={`${applicationStyles['pure-hidden-xs']} ${applicationStyles['pure-hidden-sm']}`}>
            <h1>Watchlist</h1>
            <ul tabIndex='0' id='ddlWatchlists' className={`${WatchlistStyles['ddl-watchlists']}
            ${this.state.isListOpen ? WatchlistStyles['open'] : ''}`}>

              <li tabIndex='0' onClick={this.openCloseMenu} onKeyDown={this.openCloseMenuEnter}>
                <span className={`${WatchlistStyles['name']}`}>
                  {this.state.selectedWatchlist.name}
                </span>
                <span
                  className={`
                  ${WatchlistStyles['icon']}                
                  ${this.state.isListOpen ? WatchlistStyles['icon-up'] : WatchlistStyles['icon-down']}
                  ${WatchlistStyles['float-right']}`} />
              </li>

              <li
                tabIndex='0'
                data-watchlist-id={''}
                data-watchlist-name={WATCHLIST_CONSTANTS.WATCHLIST_OVERVIEW}
                onClick={this.handleWatchlistClickEvent}
                onKeyDown={this.handleWatchlistClickEventEnter}
                className={`
                ${this.state.isListOpen ? '' : WatchlistStyles['hide']}
                ${this.state.selectedWatchlist.name === WATCHLIST_CONSTANTS.WATCHLIST_OVERVIEW ? WatchlistStyles['selected'] : ''}
              `}>
                <span className={`${WatchlistStyles['name']}`} data-el-identifier='header-watchlist_overview'>
                  {WATCHLIST_CONSTANTS.WATCHLIST_OVERVIEW}
                </span>
                <span
                  className={`
                  ${WatchlistStyles['icon']}
                  ${this.state.selectedWatchlist.name === WATCHLIST_CONSTANTS.WATCHLIST_OVERVIEW ? WatchlistStyles['icon-ok'] : ''}
                  ${WatchlistStyles['float-right']}`} />
              </li>

              {
                this.props.watchlists.map(
                  (item, index) => (
                    <li
                      tabIndex='0'
                      key={index}
                      data-watchlist-id={item.id}
                      data-watchlist-name={item.name}
                      onClick={this.handleWatchlistClickEvent}
                      onKeyDown={this.handleWatchlistClickEventEnter}
                      className={`
                      ${this.state.isListOpen ? '' : WatchlistStyles['hide']}
                      ${this.state.selectedWatchlist.name === item.name ? WatchlistStyles['selected'] : ''}
                    `}>
                      <span className={`${WatchlistStyles['name']}`} data-el-identifier='header-watchlist_name' >
                        {item.name}
                      </span>
                      <span
                        className={`
                        ${WatchlistStyles['icon']}
                        ${this.state.selectedWatchlist.name === item.name ? WatchlistStyles['icon-ok'] : ''}
                        ${WatchlistStyles['float-right']}`} />
                    </li>
                  )
                )
              }
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  watchlists: PropTypes.array.isRequired,
  selectedWatchlist: PropTypes.object,
  handleWatchlistSelection: PropTypes.func.isRequired
}
