/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Format } from 'stifel-intyce-pattern-library'
import {RenameNewsProvider, RemvoveHTMLTags, GetTitleToDisplay} from './Common'
import newsLinkListItemStyles from '../../../../styles/snapshot.V2/news/newsLinkListItem.scss'
import marketsNewsMobileStyles from '../../../../styles/markets.V2/news/news.scss'

const NewsLinkListItem = props => {
  return (
    /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
    <div tabIndex='0' className={`${newsLinkListItemStyles['news-list-item-container']} ${marketsNewsMobileStyles['news-list-item-container']}`}>
      {
        props.news.hotStory &&
        <div className={`${newsLinkListItemStyles['hotstory-container']}`}>
          <span>Trending</span>
        </div>
      }
      <div className={`${newsLinkListItemStyles['news-container']}`}>
        {
          props.news.image && props.news.image.Url &&
          <div className={`${newsLinkListItemStyles['img-container']}`}
            style={{ backgroundImage: 'url(' + props.news.image.Url + ')' }} />
        }

        <div className={`${newsLinkListItemStyles['news-info']}`}>
          <div className={`${newsLinkListItemStyles['publisher-info']} ${marketsNewsMobileStyles['publisher-info']}`}>
            <span className={`${newsLinkListItemStyles['provider']}`}>
              {RenameNewsProvider(props.news.provider)}
            </span>
            <span data-el-identifier='news-article_timestamp' className={`${newsLinkListItemStyles['date']}`}>
              {Format.getFormattedDateTime(props.news.date, {tzConversion: true})}
            </span>
          </div>
          <h3 onClick={props.onClick} data-testid={'newslinklistItem_' + props.news.documentKey}
            data-articleId={props.news.documentKey}
            data-use-static-image={props.news.useStaticImage}
            data-static-image-url={
              props.news.useStaticImage &&
              props.news.image &&
              props.news.image.Url.replace('_thumbnail', '')
            }>
            {GetTitleToDisplay(props.news.title)}
          </h3>
          <div className={`${newsLinkListItemStyles['teaser']}`}>
            <span dangerouslySetInnerHTML={RemvoveHTMLTags(props.news.teaser, 540)} />
          </div>
        </div>
      </div>
    </div>
  )
}

NewsLinkListItem.propTypes = {
  news: PropTypes.object,
  onClick: PropTypes.func.isRequired
}

export default NewsLinkListItem
