/* eslint-disable */ 
import 'babel-polyfill'
import React from 'react'
import { PropTypes } from 'prop-types'
import './styles/css-modules/main.scss'
import './styles/fonts/fonts.scss'
import { getUrlParameterValueByName } from './utils/urlMethods'
import { detectEnvByDomain, WEBSITE_DOMAIN } from './utils/appConstants'
import * as UsageReporting from './utils/usageReporting'

export default class App extends React.Component {
  constructor(props) {
    super(props)
    let redirectTo = ''
    let path = window.location.pathname.toLowerCase()

    switch(true) {
      case path.indexOf('/watchlists') === 0:
        redirectTo = '/Watchlists'
        break;
      case path.indexOf('/navigator/watchlists') === 0:
        redirectTo = '/Navigator/Watchlists'
        break;
      case path.indexOf("/companysnapshot") === 0:
        redirectTo = '/CompanySnapshot/' + (getUrlParameterValueByName('symbol') || '')
        break;
      case path.indexOf("/navigator/companysnapshot") === 0:
        redirectTo = '/Navigator/CompanySnapshot/' + (getUrlParameterValueByName('symbol') || '')
        break;
      case path.indexOf("/navigator") === 0:
        redirectTo = '/Navigator'
        break;
    }

    UsageReporting.initiaizeGoogleAnalytics()

    if(redirectTo != '') {
      redirectTo = `${WEBSITE_DOMAIN[detectEnvByDomain]}/#${redirectTo}`
      location.href = redirectTo
    }
  }

  render () {
    return (
      <div>
        {this.props.children}
      </div>
    )
  };
};
