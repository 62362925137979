/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import WatchlistsStyles from '../../../styles/watchlist/watchlists.scss'
import WatchlistHeaderStyles from '../../../styles/watchlist/watchlistHeader.scss'

class WatchlistHeader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: ''
    }
  }

  render () {
    return (
      <div className={WatchlistHeaderStyles['watchlist-header']}
        role='presentation'
        onClick={this.props.isMobileView ? this.props.handleWatchlistClickEvent : ''}>
        <div tabIndex='0' className={WatchlistHeaderStyles['watchlist-name']}
          role='presentation'
          data-el-identifier='watchlist-header_name'
          onClick={this.props.isMobileView ? '' : this.props.handleWatchlistClickEvent}
          onKeyPress={this.props.isMobileView ? '' : this.props.handleWatchlistClickEventEnter}>
          {this.props.watchlistName}
        </div>
        <div className={WatchlistHeaderStyles['watchlist-action-icons']}>
          <span role='button'
            tabIndex='0'
            data-el-identifier='watchlist-header_edit'
            data-watchlist-name={this.props.watchlistName}
            data-watchlist-id={this.props.watchlistId}
            onClick={this.props.createWatchlist}
            onKeyPress={this.props.createWatchlistEnter}
            className={WatchlistHeaderStyles['watchlist-edit-icon']}>
            <svg width='16px' height='25px' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='edit' className='svg-inline--fa fa-edit fa-w-18' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
              <path fill='#2472B2' d='M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z' />
            </svg>
          </span>
          <span role='button'
            tabIndex='0'
            data-el-identifier='watchlist-header_delete'
            data-watchlist-name={this.props.watchlistName}
            data-watchlist-id={this.props.watchlistId}
            className={`${WatchlistsStyles['sprites-img']} ${WatchlistHeaderStyles['watchlist-delete-icon']}`}
            onClick={this.props.deleteWatchlist}
            onKeyPress={this.props.deleteWatchlistEnter} />
        </div>
        <span className={WatchlistHeaderStyles['arrow-forward']} />
      </div>
    )
  }
}

WatchlistHeader.propTypes = {
  watchlistName: PropTypes.string.isRequired,
  watchlistId: PropTypes.string.isRequired,
  deleteWatchlist: PropTypes.func,
  deleteWatchlistEnter: PropTypes.func,
  handleWatchlistClickEvent: PropTypes.func,
  handleWatchlistClickEventEnter: PropTypes.func,
  createWatchlist: PropTypes.func.isRequired,
  createWatchlistEnter: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool.isRequired
}

export default WatchlistHeader
