import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Format, ArrowSVG } from 'stifel-intyce-pattern-library'
import SparkChart from '../sparkChart/SparkChart'
import * as MarketsV2Actions from '../../../actions/markets.V2.Action'
import treasureYieldsStyles from '../../../styles/markets.V2/treasuryYields/treasuryYields.scss'

class TreasuryYields extends React.Component {
  componentDidMount () {
    this.props.requestMarketsTreasuryYield()
  }
  ColorClass (value) {
    const classNameColor = value < 0 && value !== -32768
      ? 'negative'
      : value > 0
        ? 'positive'
        : 'label-values'
    return classNameColor
  }

  RenderArrow (value) {
    return <ArrowSVG priceChange={value} positiveColor={'#9ED930'} negativeColor={'#FF3333'} />
  }

  RenderPrice (price, priceChangePercent) {
    return (
      <span className={`${treasureYieldsStyles['values']}`}>
        <span className={`${treasureYieldsStyles['label-values']}`}>{Format.formatNumber(price)} </span>
        <span data-el-identifier='treasuryyields_value' className={`${treasureYieldsStyles[this.ColorClass(priceChangePercent)]}`}>
          {Format.formatNumber(priceChangePercent, {postFix: true, negSign: true})} </span>
        {this.RenderArrow(priceChangePercent)}
      </span>
    )
  }

  render () {
    const style = {
      negative: '#FF3333',
      positive: '#9ED930',
      height: 55
    }
    return (
      <div className={`${treasureYieldsStyles['treasury-yields-container']}`}>
        <div className={`${treasureYieldsStyles['header']}`}>
          <h2 className={`${treasureYieldsStyles['Treasury-Yields-header']}`} data-el-identifier='treasuryyield_heading'>Treasury Yields</h2>
        </div>
        <div className={`${treasureYieldsStyles['mobile-container']}`}>
          <div className={`${treasureYieldsStyles['container']}`}>
            <div className={`${treasureYieldsStyles['content-container']}`}>
              <span className={`${treasureYieldsStyles['label']}`}>3 Month T-Bill</span>
              {this.RenderPrice(this.props.marketsTreasuryYield.interestRate3M, this.props.marketsTreasuryYield.change3M)}
            </div>
            {!this.props.isMobileView &&
              <div className={`${treasureYieldsStyles['spark-chart']}`}>
                <SparkChart
                  wsodIssue={9696613}
                  style={style}
                  isMarketOpen={this.props.isMarketOpen}
                  changeAmount={this.props.marketsTreasuryYield.change3M} />
              </div>
            }
          </div>
          <div className={`${treasureYieldsStyles['container']}`}>
            <div className={`${treasureYieldsStyles['content-container']}`}>
              <span className={`${treasureYieldsStyles['label']}`}>10 Year T-Note</span>
              {this.RenderPrice(this.props.marketsTreasuryYield.interestRate10Y, this.props.marketsTreasuryYield.change10Y)}
            </div>
            {!this.props.isMobileView &&
              <div className={`${treasureYieldsStyles['spark-chart']}`}>
                <SparkChart
                  wsodIssue={11523680}
                  style={style}
                  isMarketOpen={this.props.isMarketOpen}
                  changeAmount={this.props.marketsTreasuryYield.change10Y} />
              </div>
            }
          </div>
          <div className={`${treasureYieldsStyles['container']}`}>
            <div className={`${treasureYieldsStyles['content-container']} ${treasureYieldsStyles['no-border']}`}>
              <span className={`${treasureYieldsStyles['label']}`}>30 Year T-Bond</span>
              {this.RenderPrice(this.props.marketsTreasuryYield.interestRate30Y, this.props.marketsTreasuryYield.change30Y)}
            </div>
            {!this.props.isMobileView &&
              <div className={`${treasureYieldsStyles['spark-chart']}`}>
                <SparkChart
                  wsodIssue={11523681}
                  style={style}
                  isMarketOpen={this.props.isMarketOpen}
                  changeAmount={this.props.marketsTreasuryYield.change30Y} />
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
TreasuryYields.propTypes = {
  isMobileView: PropTypes.bool.isRequired,
  requestMarketsTreasuryYield: PropTypes.func.isRequired,
  marketsTreasuryYield: PropTypes.object.isRequired,
  isMarketOpen: PropTypes.bool.isRequired
}

function mapStateToProps (state) {
  return {
    marketsTreasuryYield: state.marketsV2Reducer.marketsTreasuryYield
  }
}

function mapDispatchToProps (dispatch) {
  return {
    requestMarketsTreasuryYield: () => {
      return dispatch(MarketsV2Actions.requestMarketsTreasuryYield())
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TreasuryYields)
