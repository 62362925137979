/* eslint-disable camelcase */
import React from 'react'
import { Route, IndexRoute } from 'react-router'
import MarketsV2 from './components/markets.V2/Container'
import NavMarketsV2 from './components/navigator/markets.V2/Container'
import SnapshotV2 from './components/snapshot.V2/Container'
import MarketsNewsArticleContainer from './components/markets.V2/news/NewsArticleContainer'
import NavigatorMarketsNewsArticleContainer from './components/navigator/markets.V2/news/NewsArticleContainer'
import SnapshotV2_Navigator from './components/navigator/snapshot.V2/Container'
import NewsArticlePageContainer from './components/snapshot.V2/SubComponents/News/NewsArticlePageContainer'
import NavigatorNewsArticlePageContainer from './components/navigator/snapshot.V2/SubComponents/News/NewsArticlePageContainer'
import SnapshotV2_0 from './components/snapshot.V2/Snapshot.V2.0'
import SnapshotV2_0_Navigator from './components/navigator/snapshot.V2/Snapshot.V2.0'
import CompanySnapshot from './components/snapshot.V2/CompanySnapshot'
import CompanySnapshot_Navigator from './components/navigator/snapshot.V2/CompanySnapshot'
import Watchlists from './components/watchlist/Container'
import Watchlists_Navigator from './components/navigator/watchlist/Container'
import Login from './components/Authentication/Login'
import Logout from './components/Authentication/Logout'
import App from './App'

export default(
  <Route exact path='/' component={App}>
    <IndexRoute component={MarketsV2} />
    <Route path='/Navigator' component={NavMarketsV2} />
    <Route exact path='/Snapshot/V2/News/Article/:viewType/:articleId' component={NewsArticlePageContainer} />
    <Route exact path='/Navigator/Snapshot/V2/News/Article/:viewType/:articleId' component={NavigatorNewsArticlePageContainer} />
    <Route exact path='/Snapshot/V2/:symbol/:wsodissue' component={SnapshotV2} />
    <Route exact path='/Navigator/Snapshot/V2/:symbol/:wsodissue' component={SnapshotV2_Navigator} />
    <Route exact path='/Snapshot/quote/' component={SnapshotV2_0} />
    <Route exact path='/Markets/V2' component={MarketsV2} />
    <Route exact path='/Navigator/Markets/V2' component={NavMarketsV2} />
    <Route exact path='/Markets/V2/News/Article/:articleId' component={MarketsNewsArticleContainer} />
    <Route exact path='/Navigator/Markets/V2/News/Article/:articleId' component={NavigatorMarketsNewsArticleContainer} />
    <Route exact path='/Navigator/Snapshot/quote/' component={SnapshotV2_0_Navigator} />
    <Route exact path='/Watchlists' component={Watchlists} />
    <Route exact path='/Navigator/Watchlists' component={Watchlists_Navigator} />
    <Route exact path='/Watchlist/:watchlistId/:watchlistName' component={Watchlists} />
    <Route exact path='/Navigator/Watchlist/:watchlistId/:watchlistName' component={Watchlists_Navigator} />
    <Route exact path='/Login' component={Login} />
    <Route exact path='/SecureAccess' component={Login} />
    <Route exact path='/Logout' component={Logout} />
    <Route exact path='/CompanySnapshot/:symbol' component={CompanySnapshot} />
    <Route exact path='/Navigator/CompanySnapshot/:symbol' component={CompanySnapshot_Navigator} />
  </Route>
)
