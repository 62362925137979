/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'babel-polyfill'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MAX_SECURITIES, WATCHLIST_MESSAGES, WATCHLISTS_WATCHLISTTAB_CONSTANTS, LOCALSTORAGETYPE_CONSTANTS } from '../../../../utils/appConstants'
import WatchlistStyles from '../../../../styles/watchlist/watchlist.scss'
import WatchlistsStyles from '../../../../styles/watchlist/watchlists.scss'
import WatchlistHoldings from './WatchlistHoldings'
import * as WatchlistActions from '../../../../actions/watchlist.Actions'
import { Tabs } from 'stifel-intyce-pattern-library'
import { hashHistory } from 'react-router'
import SearchSymbol from './SearchSymbol'
import EditWatchlist from './EditWatchlist'
import AsOfDate from '../AsOfDate'
import { GetLocalStorageIfAvailable, SetSpecificKeysIntoLocalStorage, getLargestDate } from '../../../common.v2/CommonMethods'
class Watchlist extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      symbol: '',
      watchlistInfo: this.props.watchlistInfo,
      toggle: false,
      editWatchlist: false,
      asOfDate: null,
      mfAsOfDate: null,
      activeTab: this.setActiveTab()
    }
    this.addToWatchlist = this.addToWatchlist.bind(this)
    this.goBackHandler = this.goBackHandler.bind(this)
    this.showSearchSymbol = this.showSearchSymbol.bind(this)
    this.closeSearchSymbol = this.closeSearchSymbol.bind(this)
    this.toggleEditWatchlist = this.toggleEditWatchlist.bind(this)
    this.onTabClick = this.onTabClick.bind(this)
  }

  componentDidMount () {
  }

  componentWillReceiveProps (nextProps) {
    if ((this.state.symbol && nextProps.symbolsToWatchlistResponse) || (nextProps.deleteSymbolResponse !== this.props.deleteSymbolResponse)) {
      let error = nextProps.symbolsToWatchlistResponse && nextProps.symbolsToWatchlistResponse.error
      if (error) {
        if (error.message.indexOf('already exists') !== -1) {
          this.props.notificationHandler({show: true, message: `Error: ${this.state.symbol} is already part of "${this.state.watchlistInfo.name}" Watchlist.`})
        } else {
          this.props.notificationHandler({show: true})
        }
      } else {
        // display message while adding symbol only
        if (this.state.symbol && nextProps.symbolsToWatchlistResponse) {
          this.props.notificationHandler({show: true, type: 'SUCCESS', message: WATCHLIST_MESSAGES.ADDED_SYMBOL(this.state.symbol)})
        }

        // start loading on watchlist holdings
        this.props.setFetchingDataFlag({
          watchlistHoldings_Platform: {
            fetching: true
          }
        })
        this.props.requestWatchlistHoldings(this.props.watchlistInfo.id, this.state.activeTabname === 'Fundamentals')
      }

      // Reset isServerResponse
      this.props.setFetchingDataFlag({
        symbolsToWatchlist_Platform: {
          isServerResponse: false,
          data: {
            holdings: []
          }
        }
      })
    }
    if (nextProps.holdings) {
      this.setState({
        asOfDate: null,
        mfAsOfDate: null
      })
    }
    if (nextProps.holdings && nextProps.holdings.length > 0) {
      let dates = getLargestDate(nextProps.holdings)
      if (dates.dateMf) {
        this.setState({mfAsOfDate: `${dates.dateMf}`})
      }
      if (dates.date) {
        this.setState({asOfDate: `${dates.date}`})
      }
    }
  }

  goBackHandler () {
    this.props.goBackHandler({id: '', name: ''})
    hashHistory.push('/Watchlists')
  }

  addToWatchlist (xid, symbol) {
    if (this.props.totalSymbols >= MAX_SECURITIES) {
      this.props.notificationHandler({show: true, message: WATCHLIST_MESSAGES.MAX_SECURITIES})
      return
    }
    this.setState({symbol: symbol})
    this.props.requestAddSymbolToWatchlist(this.state.watchlistInfo.id, xid)
  }

  showSearchSymbol () {
    this.setState({toggle: true})
  }
  closeSearchSymbol () {
    this.setState({toggle: false})
  }

  toggleEditWatchlist (e, watchlistName) {
    this.setState(prevState => ({
      editWatchlist: !prevState.editWatchlist
    }))

    if (watchlistName) {
      this.setState(prevState => ({
        watchlistInfo: {
          id: prevState.watchlistInfo.id,
          name: watchlistName
        }
      }))
    }
  }

  getAsOfDateHtml () {
    return <div className={WatchlistStyles['as-of-date-container']}>
      <AsOfDate asOfDate={this.state.asOfDate} mfAsOfDate={this.state.mfAsOfDate} />
    </div>
  }
  onTabClick (tabLabel) {
    this.state.activeTabname = tabLabel
    SetSpecificKeysIntoLocalStorage(LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS, {
      [WATCHLISTS_WATCHLISTTAB_CONSTANTS]: tabLabel
    })
  }
  setActiveTab () {
    let activeTab = GetLocalStorageIfAvailable(LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS, WATCHLISTS_WATCHLISTTAB_CONSTANTS)
    return activeTab || ''
  }
  render () {
    return (
      <div className={`${WatchlistStyles['watchlist-container']}`}>
        <div className={`${WatchlistStyles['back-to-watchlist-btn-container']}`}>
          <button data-el-identifier='watchlist-overview_back' onClick={this.goBackHandler}>
            <span className={`${WatchlistStyles['icon-back']}`} /> Watchlist Overview
          </button>
        </div>
        <div className={`${WatchlistStyles['watchlist-detail-container']}`}>
          <div className={`${WatchlistStyles['name-and-search-container']}`}>
            <div className={`${WatchlistStyles['name']}`}>
              {this.state.watchlistInfo.name}
            </div>
            <div className={`${WatchlistStyles['search-symbol-container']}`}>
              <span data-el-identifier='watchlist-mobile_search' className={`${WatchlistsStyles['sprites-img']} ${WatchlistsStyles['search-icon']}`}
                role='presentation' onClick={this.showSearchSymbol} />
            </div>

          </div>

          <div className={`${WatchlistStyles['tabs-container']}`}>
            <Tabs activeTab={this.state.activeTab && this.state.activeTab.length > 0 ? this.state.activeTab : this.props.selectedTab} onClick={this.onTabClick}>
              <div label={'Basic'} key={'Basic'}>
                <div className={`${WatchlistStyles['tab']}`}>
                  { this.getAsOfDateHtml() }
                  <div className={`${WatchlistStyles['tab-content-container']}`}>
                    <WatchlistHoldings
                      watchlistInfo={this.state.watchlistInfo}
                      onSymbolClick={this.props.onSymbolClick}
                      holdingsBy='Basic' />
                  </div>
                </div>
              </div>
              <div label={'Performance'} key={'Performance'}>
                <div className={`${WatchlistStyles['tab']}`}>
                  { this.getAsOfDateHtml() }
                  <div className={`${WatchlistStyles['tab-content-container']}`}>
                    <WatchlistHoldings
                      watchlistInfo={this.state.watchlistInfo}
                      onSymbolClick={this.props.onSymbolClick}
                      holdingsBy='Performance' />
                  </div>
                </div>
              </div>
              <div label={'Fundamentals'} key={'Fundamentals'}>
                <div className={`${WatchlistStyles['tab']}`}>
                  { this.getAsOfDateHtml() }
                  <div className={`${WatchlistStyles['tab-content-container']}`}>
                    <WatchlistHoldings
                      watchlistInfo={this.state.watchlistInfo}
                      onSymbolClick={this.props.onSymbolClick}
                      holdingsBy='Fundamentals' />
                  </div>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
        <div className={`${WatchlistStyles['footer-container']}`}>
          <button data-el-identifier='watchlist_edit' onClick={this.toggleEditWatchlist} className={`${WatchlistsStyles['button']} ${WatchlistsStyles['white']}`}>
            Edit Watchlist
          </button>
        </div>
        { this.state.toggle &&
          <SearchSymbol addToWatchlist={this.addToWatchlist} closeSearchSymbol={this.closeSearchSymbol} />
        }
        { this.state.editWatchlist &&
          <EditWatchlist watchlistName={this.state.watchlistInfo.name}
            watchlistId={this.state.watchlistInfo.id}
            holdings={this.props.holdings}
            toggleEditWatchlist={this.toggleEditWatchlist}
            requestDeleteSymbol={this.props.requestDeleteSymbol}
            requestWatchlists={this.props.requestWatchlists}
            notificationHandler={this.props.notificationHandler} />
        }
      </div>
    )
  }
}

Watchlist.propTypes = {
  watchlistInfo: PropTypes.object.isRequired,
  setFetchingDataFlag: PropTypes.func.isRequired,
  symbolsToWatchlistResponse: PropTypes.object,
  requestAddSymbolToWatchlist: PropTypes.func.isRequired,
  requestWatchlistHoldings: PropTypes.func.isRequired,
  onSymbolClick: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
  totalSymbols: PropTypes.number,
  selectedTab: PropTypes.string,
  goBackHandler: PropTypes.func.isRequired,
  requestWatchlists: PropTypes.func.isRequired,
  requestDeleteSymbol: PropTypes.func.isRequired,
  holdings: PropTypes.array.isRequired,
  deleteSymbolResponse: PropTypes.string
}

function mapStateToProps (state, ownProps) {
  let obj = {
  }

  if (state.watchlistReducer.symbolsToWatchlist_Platform.isServerResponse) {
    obj.symbolsToWatchlistResponse = {}

    if (state.watchlistReducer.symbolsToWatchlist_Platform.error) {
      obj.symbolsToWatchlistResponse['error'] = state.watchlistReducer.symbolsToWatchlist_Platform.error
    } else {
      // TODO
    }
  }

  if (state.watchlistReducer.watchlistHoldings_Platform.data && state.watchlistReducer.watchlistHoldings_Platform.data.holdings) {
    obj.totalSymbols = state.watchlistReducer.watchlistHoldings_Platform.data.holdings.length
    obj.holdings = state.watchlistReducer.watchlistHoldings_Platform.data.holdings
  }
  if (state.watchlistReducer.deleteSymbolFromWatchlistResponse.data.holdings) {
    obj.deleteSymbolResponse = state.watchlistReducer.deleteSymbolFromWatchlistResponse.data.holdings.length > 0
      ? state.watchlistReducer.deleteSymbolFromWatchlistResponse.data.holdings[0].id : ''
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(WatchlistActions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestAddSymbolToWatchlist: (id, xid) => {
      return dispatch(WatchlistActions.requestAddSymbolToWatchlist(id, xid))
    },
    requestWatchlistHoldings: (id, updateFetchBool) => {
      return dispatch(WatchlistActions.requestWatchlistHoldings(id, false, updateFetchBool))
    },
    requestDeleteSymbol: (watchlistId, holdingId) => {
      return dispatch(WatchlistActions.requestDeleteSymbol(watchlistId, holdingId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Watchlist)
