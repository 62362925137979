/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import WatchlistsStyles from '../../../styles/watchlist/notification.scss'
import { WATCHLIST_MESSAGES } from '../../../utils/appConstants'

class Notification extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false,
      message: WATCHLIST_MESSAGES.GENERIC,
      type: null
    }
    this.animationListener = this.animationListener.bind(this)
    this.getIcon = this.getIcon.bind(this)
  }

  componentDidMount () {
    var element = document.getElementById('animateOpen')
    if (element) {
      element.removeEventListener('animationend', this.animationListener, true)
      element.addEventListener('animationend', this.animationListener, true)
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      show: nextProps.show,
      message: nextProps.message || this.state.message,
      type: nextProps.type
    })
  }

  animationListener (event) {
    switch (event.type) {
      case 'animationend':
        this.props.notificationHandler({show: false, message: null})
        // reset state after close
        this.setState({show: false, message: WATCHLIST_MESSAGES.GENERIC})
        break
    }
  }

  getIcon () {
    if (this.state.type === 'SUCCESS') {
      return (<svg width='25px' height='25px' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check-circle' className='svg-inline--fa fa-check-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <path fill='#5C8118' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z' />
      </svg>)
    } else {
      return (<svg width='25px' height='25px' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='exclamation-triangle' className='svg-inline--fa fa-exclamation-triangle fa-w-18' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
        <path fill='#CB223C' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z' />
      </svg>)
    }
  }

  render () {
    return (
      <div className={`${WatchlistsStyles['notification-box']} ${this.state.show ? WatchlistsStyles['notification-box-show'] : ''}`}>
        <div id='animateOpen' className={`${WatchlistsStyles['notification']} ${WatchlistsStyles['animateOpen']}`}>
          {this.getIcon()}
          <span className={WatchlistsStyles['message']}>{this.state.message}</span>
        </div>
      </div>
    )
  }
}

Notification.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool.isRequired,
  type: PropTypes.string,
  notificationHandler: PropTypes.func.isRequired
}

export default Notification
