/**
 * Method used to get query string parameters
 * @param {A query string to parse} query
 */
export const getQueryStringParameters = query => {
  if (!query) return {}
  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=')
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
      return params
    }, {})
}

/**
 *
 *
 * Method used to get a query string parameter value
 * @param {A parameter key} key
 */
export const getUrlParameter = key => {
  if (!window.location.search) return undefined

  const params = getQueryStringParameters(window.location.search)

  if (!params || Object.keys(params).length < 1) return undefined

  return params[key]
}

/**
 * Method used to determine whether debug is on
 */
export const isDebugOn = () => getUrlParameter('..showdebuginfo..') === 'on'

export const getUrlParameterValueByName = key => {
  const url = window.location.href
  const cleanedName = key.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${cleanedName}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results) {
    return null
  }

  return results[2]
    ? decodeURIComponent(results[2].replace(/\+/g, ' '))
    : ''
}
