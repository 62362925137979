export function RenameNewsProvider (provider) {
  if (provider === 'DJMW') {
    return 'MarketWatch'
  }
  return 'Dow Jones'
}

export function CreateMarkupTitle (text, charLimit) {
  return { __html: text != null ? (charLimit ? TruncateText(text, charLimit) : text) : '' }
}

export function CreateMarkup (fullText = '') {
  var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim
  fullText = fullText.replace(new RegExp('<br/>', 'g'), '<div style=\'padding-top:3px\'></div>')
  fullText = fullText.replace(new RegExp('<br>', 'g'), '<div style=\'padding-top:3px\'></div>')
  fullText = fullText.replace(urlPattern, '<a id="$&" href="#" target="_blank">$&</a>')
  return { __html: fullText != null ? fullText.replace(/\n/g, '<div style=\'padding-top:1px\'></div>') : '' }
}

export function RemvoveHTMLTags (fullText = '', charLimit) {
  fullText = fullText.replace(new RegExp('<br/>', 'g'), '')
  fullText = fullText.replace(new RegExp('<br>', 'g'), '')
  fullText = fullText.replace(/\n/g, '')
  return { __html: (charLimit ? TruncateText(fullText, charLimit) : fullText) }
}

export function TruncateText (text, charLimit, endingText = '...') {
  if (!text || !charLimit || text.length <= charLimit) return text

  if (text.length > charLimit) {
    return text.substring(0, charLimit - endingText.length) + endingText
  }
}

export function GetTitleToDisplay (title) {
  if (title && title.indexOf('MW ') === 0) {
    return title.replace('MW ', '')
  }

  return title
}
