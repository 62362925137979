const INITIAL_STATE = {
  fetching: true,
  securityType: '--',
  quoteData: {
    symbol: '--',
    exchange: '--',
    name: '--',
    isMarketOpen: false
  },
  quoteData_Platform: {
    data: {
      quotes: [
        {
          data: {
            'asOfDate': '00/00/0000',
            'ask': {
              'date': '--',
              'price': '--',
              'size': '--'
            },
            'bid': {
              'date': '--',
              'price': '--',
              'size': '--'
            },
            'bidAsk': {
              'midPoint': '--',
              'spread': '--'
            },
            'changePercent': {
              'today': '--',
              'oneWeek': '--',
              'oneMonth': '--',
              'threeMonth': '--',
              'sixMonth': '--',
              'oneYear': '--',
              'twoYear': '--',
              'threeYear': '--'
            },
            'changeAmount': {
              'today': '--',
              'oneWeek': '--',
              'oneMonth': '--',
              'threeMonth': '--',
              'sixMonth': '--',
              'oneYear': '--',
              'twoYear': '--',
              'threeYear': '--'
            },
            Historical: {
              Price: {
                Chg: {
                  '1M': '--',
                  '3M': '--',
                  '6M': '--',
                  '12M': '--',
                  '2Y': '--'
                }
              }
            },
            'currency': {
              'isoCode': '--',
              'name': '--',
              'symbol': '--'
            },
            'exchange': {
              'isHoliday': '--',
              'name': '--',
              'sessionClose': '--',
              'sessionOpen': '--',
              'timezoneOffset': '--'
            },
            'lastTrade': {
              'date': '--',
              'close': '--',
              'last': '--',
              'change': '--'
            },
            'price52Week': {
              'high': '--',
              'highDate': '--',
              'low': '--',
              'lowDate': '--'
            },
            'volume': {
              'average10Day': '--',
              'average90Day': '--',
              'last': '--',
              'pace': '--'
            },
            'financialStatusIndicator': '--',
            'realTimeQuoteIndicator': '--',
            'suspendedStatus': '--',
            'instrumentIssueType': '--',
            'marketCap': '--',
            'quoteDelayMinutes': '--',
            'sharesOutstanding': '--',
            'nonZero': {
              'bid': {
                'value': '--',
                'date': '--',
                'size': '--'
              },
              'ask': {
                'value': '--',
                'date': '--',
                'size': '--'
              }
            },
            'nav': {
              last: '--',
              date: '--',
              close: '--',
              change: '--',
              changePercent: '--'
            }
          }
        }
      ]
    },
    error: {
      code: null,
      message: null
    }
  },
  symbolXREFData_Platform: {
  },
  quoteIndiceData: {
    quoteNASDAQ: null,
    changePctNASDAQ: null,
    changeNASDAQ: null,
    changeNASDAQ3M: null,
    changePctNASDAQ3M: null,
    changeNASDAQ1Y: null,
    changePctNASDAQ1Y: null,
    quoteDJIA: null,
    changePctDJIA: null,
    changeDJIA: null,
    changeDJIA3M: null,
    changePctDJIA3M: null,
    changeDJIA1Y: null,
    changePctDJIA1Y: null,
    quoteSP500: null,
    changePctSP500: null,
    changeSP500: null,
    changeSP5003M: null,
    changePctSP5003M: null,
    changeSP5001Y: null,
    changePctSP5001Y: null,
    quoteNYSE: null,
    changePctNYSE: null,
    changeNYSE: null,
    changeNYSE3M: null,
    changePctNYSE3M: null,
    changeNYSE1Y: null,
    changePctNYSE1Y: null,
    dateAsOf: '--',
    delay: '--'
  },
  optionChainExpirationDates_Platform: {
    data: {
      fetching: true,
      dates: [
        {
          'contactTerm': '--',
          'contractTerm': '--',
          'expirationDate': '--',
          'isAdjusted': false
        }
      ]
    }
  },
  performanceSummary: {
    data: {
      fetching: true,
      prospectusFees_Platform: {
        data: {
          actual: {
            '12b1': '-',
            frontLoad: '-',
            managementFee: '-'
          },
          currency: '-',
          distributionFee: '-',
          expense: {
            projection: {
              year1: '-',
              year10: '-',
              year2: '-',
              year3: '-',
              year35: '-',
              year5: '-'
            },
            waiverCapNote: '-'
          },
          feeNegotiable: false,
          incomeManagementFees: '-',
          institutionalInvestorEtf: {
            creation: {
              feeMaximum: '-',
              feeMinimum: '-',
              unit: '-'
            },
            redemption: {
              feeMaximum: '-',
              feeMinimum: '-',
              unit: '-'
            }
          },
          managementFeeDeductionFrequency: '-',
          max: {
            administrativeFee: '-',
            deferLoad: '-',
            frontLoad: '-',
            groupFee: '-',
            managementFee: '-',
            transferFee: '-'
          },
          minimumChargeAmount: '-',
          otherExpenses: '-',
          performanceFee: {
            value: '-',
            clawBack: '-',
            deductionFrequency: '-',
            highWatermark: '-',
            hurdleRate: '-',
            index: '-',
            indexId: '-',
            note: '-'
          },
          prospectus: {
            acquiredExpenseRatio: '-',
            dividendExpense: '-',
            grossExpenseRatio: '-',
            interestExpense: '-',
            netExpenseRatio: '-',
            otherFee: '-',
            otherFeeNote: '-',
            deferredTaxExpenseRatio: '-',
            date: '00/00/0000'
          },
          separateAccountGrossOfManagementFee: '-',
          switchingFee: '-',
          totalExpenses: '-',
          transactionFee: '-',
          trusteeFee: '-',
          uit: {
            creationAndDevelopmentFee: '-',
            maximumSalesCharge: '-',
            organizationCosts: '-',
            totalDeferredSalesCharge: '-'
          }
        }
      },
      topHoldings_Platform: {
        data: {
          items: [
            {
              market: {
                country: '-'
              },
              currency: {
                label: '-'
              },
              detailHoldingTypeId: '-',
              dateFirstBought: '00/00/0000',
              industryId: '-',
              marketValue: '-',
              sector: {
                label: '-'
              },
              securityIdentifiers: {
                name: '-',
                ticker: '-',
                holdingDetailId: '-'
              },
              shareDetails: {
                number: '-',
                change: '-',
                percentage: '-',
                weighting: '-'
              }
            }
          ]
        }
      },
      shareClassInformation_Platform: {
        data: {
          capitalGainDistributionFrequency: '-',
          currency: '-',
          dataReadiness: '-',
          dividendDistributionFrequency: '-',
          domicileCountry: '-',
          fiscalYearEndMonth: '-',
          fundLegalName: '-',
          fundNetAssets: '-',
          fundShareClassNetAssets: '-',
          investmentVehicleName: '-',
          legalName: '-',
          legalStructure: '-',
          mediaStubName: '-',
          oldestShareClassVenueXid: '-',
          oldestShareClassName: '-',
          previousName: '-',
          pricingFrequency: '-',
          shareClassVenueXid: '-',
          shareClassType: '-',
          dates: [
            {
              'type': '--',
              'value': '--'
            }
          ]
        }
      },
      trailingMonthEnd_Platform: {
        data: {
          items: [
            {
              dataPoint: '-',
              label: '-',
              values: [
                '--',
                '--',
                '--',
                '--'
              ]
            },
            {
              dataPoint: '-',
              label: '-',
              values: [
                '--',
                '--',
                '--',
                '--'
              ]
            }
          ],
          timePeriods: [
            {
              periodType: '-',
              period: 1
            },
            {
              periodType: '-',
              period: 2
            },
            {
              periodType: '-',
              period: 5
            },
            {
              periodType: '-',
              period: 10
            }
          ],
          isExtendedPerformance: [
            '-',
            '-'
          ],
          metaData: {
            performance: {
              id: '-',
              currencyId: '-',
              currency: '-',
              dateAsOf: '00/00/0000'
            },
            closePrice: '-',
            dateAsOfMarketPerformance: '00/00/0000',
            potentialCapitalGain: 0.98,
            dateProcessed: '00/00/0000'
          }
        }
      }
    }
  },
  revenueChart: {
    data: {
      fetching: true,
      chartUrl: null,
      chartWidth: null,
      chartHeight: null
    }
  },
  dividendChart: {
    data: {
      fetching: true,
      chartUrl: null,
      chartWidth: null,
      chartHeight: null,
      dataCoordinates: [],
      isMobileView: false,
      stockDividendEvents: []
    }
  },
  optionChain_Platform: {
    data: {
      fetching: true,
      items: [
        {
          expirationDate: '0000-00-00',
          options: [
            {
              call: {
                calculation: {
                  inOutMoney: '--',
                  intrinsicValue: '--',
                  timeValue: '--',
                  timeValuePercent: '--'
                },
                exerciseStyle: '--',
                isAdjusted: false,
                lotSize: '--',
                optionRoot: '--',
                periodicity: '--',
                quote: {
                  ask: {
                    date: '0000-00-00',
                    price: '--',
                    size: '--'
                  },
                  bid: {
                    date: '--',
                    price: '--',
                    size: '--'
                  },
                  bidAsk: {
                    midPoint: '--',
                    spread: '--'
                  },
                  changePercent: {
                    today: '--'
                  },
                  lastTrade: {
                    chang: '--',
                    close: '--',
                    date: '0000-00-00',
                    high: '--',
                    last: '--',
                    low: '--',
                    open: '--'
                  },
                  openInterest: '--',
                  volume: {
                    last: '--'
                  }
                },
                xid: '--'
              },
              put: {
                calculation: {
                  inOutMoney: '--',
                  intrinsicValue: '--',
                  timeValue: '--',
                  timeValuePercent: '--'
                },
                exerciseStyle: '--',
                isAdjusted: false,
                lotSize: '--',
                optionRoot: '--',
                periodicity: '--',
                quote: {
                  ask: {
                    date: '0000-00-00',
                    price: '--',
                    size: '--'
                  },
                  bid: {
                    date: '--',
                    price: '--',
                    size: '--'
                  },
                  bidAsk: {
                    midPoint: '--',
                    spread: '--'
                  },
                  changePercent: {
                    today: '--'
                  },
                  lastTrade: {
                    chang: '--',
                    close: '--',
                    date: '0000-00-00',
                    high: '--',
                    last: '--',
                    low: '--',
                    open: '--'
                  },
                  openInterest: '--',
                  volume: {
                    last: '--'
                  }
                },
                xid: '--'
              },
              strikePrice: '--'
            }
          ]
        }
      ],
      underlyingQuote: {
        ask: {
          date: '0000-00-00',
          price: '--',
          size: '--'
        },
        bid: {
          date: '--',
          price: '--',
          size: '--'
        },
        bidAsk: {
          midPoint: '--',
          spread: '--'
        },
        changePercent: {
          fiveYear: '--',
          oneMonth: '--',
          oneWeek: '--',
          oneYear: '--',
          sixMonth: '--',
          tenYear: '--',
          threeMonth: '--',
          threeYear: '--',
          today: '--',
          twoYear: '--'
        },
        lastTrade: {
          change: '--',
          close: '--',
          date: '0000-00-00',
          high: '--',
          last: '--',
          low: '--',
          open: '--'
        },
        price52Week: {
          high: '--',
          highDate: '0000-00-00',
          low: '--',
          lowDate: '0000-00-00'
        },
        volume: {
          average10Day: '--',
          average90Day: '--',
          last: '--'
        }
      }
    }
  },
  mfOverviewData: {
    data: {
      fetching: true,
      fundObjective: '--',
      mfOverview10KGrowthChart: {
        fetching: true,
        chartUrl: '',
        chartUrlIpad: '',
        chartUrlMobile: ''
      }
    }
  },
  etfOverviewData: {
    data: {
      fetching: true,
      fundObjective: '--'
    }
  },
  xidFromSymbol: {
    fetching: true,
    data: {
      xid: '',
      symbol: ''
    }
  },
  newsData: {
    fetching: true,
    data: {
      news: [],
      totalMatches: 0,
      totalPages: 0
    }
  },
  marketsNewsData: {
    fetching: true,
    data: {
      news: [],
      totalMatches: 0,
      totalPages: 0
    }
  },
  marketsTodayNews: {
    data: {
      news: [
        {
          documentKey: '--',
          title: '--',
          date: '0000-00-00',
          teaser: '--',
          provider: '--',
          hotStory: false
        }
      ],
      totalMatches: '--',
      totalPages: '--'
    }
  },
  newsArticle: {
    fetching: true,
    data: {}
  },
  generalInfo: {
    fetching: true,
    data: {
      companySummary: '--'
    },
    error: {
      code: 0,
      message: '--'
    }
  },
  newsImage: {
    image: {
      Url: null
    }
  },
  marketsTreasuryYield: {
    interestRate3M: null,
    change3M: null,
    interestRate10Y: null,
    change10Y: null,
    interestRate30Y: null,
    change30Y: null,
    dateAsOf: '--'
  },
  marketMovers: {
    fetching: true,
    data: {
      allRankData: [],
      dateAsOf: null
    }
  },
  symbolSearchResults: {
    stockResults: [
      {
        'key': null,
        'securityType': null,
        'exchange': null,
        'name': null,
        'symbol': null
      }
    ],
    etfResults: [
      {
        'key': null,
        'securityType': null,
        'exchange': null,
        'name': null,
        'symbol': null
      }
    ]
  },
  xid: null,
  fundIndicator_Platform: {
    fetching: true,
    data: {}
  },
  equityDividend_Platform: {
    fetching: true,
    data: {
      annualizedDividend: {
        currencyGross: '--',
        gross: '--',
        method: '--',
        yieldGross: '--'
      },
      currentDividend: {
        amount: '--',
        amountAdjusted: '--',
        announcementDate: '--',
        currency: '--',
        currencyAdjusted: '--',
        exDate: '--',
        frequencyValue: '--',
        frequency: '--',
        paymentDate: '--',
        registeredDate: '--',
        typeDescription: '--'
      },
      trailingDividend: {
        regularDividendTTM: '--',
        regularDividendTTMYield: '--'
      },
      yieldPrice: {
        latestClose: '--',
        latestCloseDate: '--'
      },
      metaData: {
        venueXid: '--',
        dividendRecentlyDiscontinued: '--'
      }
    }
  }
}
export default INITIAL_STATE
