import React from 'react'
import { connect } from 'react-redux'
import * as MarketsV2Actions from '../../../actions/markets.V2.Action'
import headerStyles from '../../../styles/markets.V2/header/Header.scss'
import CompanySearch from '../../common.v2/header/headerSubComponents/Container'
import IndiceQuotes from '../IndiceQuotes/IndiceQuotes'
import PropTypes from 'prop-types'
import { INDICES_SYMBOL } from '../../../utils/appConstants'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.props.setFetchingDataFlag({ fetching: true })
  }
  componentDidMount () {
    this.props.requestIndicesV2Data(INDICES_SYMBOL.DJIA)
  }

  render () {
    const style = {
      negative: '#CB223C',
      positive: '#5C8118'
    }
    if (this.props.fetching) {
      return null
    }
    return (
      <div className={`${headerStyles['header-container-top']}`}>
        <div className={`${headerStyles['header-container']}`}>
          {!this.props.isMobileView &&
            <div data-el-identifier='header_indice-quotes' className={`${headerStyles['indice-quotes']}`}>
              <IndiceQuotes
                symbol='DJIA'
                wsodIssue={INDICES_SYMBOL.DJIA}
                quotePrice={this.props.quoteIndiceData.quoteDJIA}
                priceChangePercentToday={this.props.quoteIndiceData.changePctDJIA}
                priceChangeToday={this.props.quoteIndiceData.changeDJIA}
                isMarketOpen={this.props.isMarketOpen}
                style={style}
                openSnapShot={this.props.openSnapShot} />

              <IndiceQuotes
                symbol='NASDAQ'
                wsodIssue={INDICES_SYMBOL.NASDAQ}
                quotePrice={this.props.quoteIndiceData.quoteNASDAQ}
                priceChangePercentToday={this.props.quoteIndiceData.changePctNASDAQ}
                priceChangeToday={this.props.quoteIndiceData.changeNASDAQ}
                isMarketOpen={this.props.isMarketOpen}
                style={style}
                openSnapShot={this.props.openSnapShot} />

              <IndiceQuotes
                symbol='NYSE'
                wsodIssue={INDICES_SYMBOL.NYSE}
                quotePrice={this.props.quoteIndiceData.quoteNYSE}
                priceChangePercentToday={this.props.quoteIndiceData.changePctNYSE}
                priceChangeToday={this.props.quoteIndiceData.changeNYSE}
                isMarketOpen={this.props.isMarketOpen}
                style={style}
                openSnapShot={this.props.openSnapShot} />

              <IndiceQuotes
                symbol='S&P 500'
                wsodIssue={INDICES_SYMBOL.SP500}
                quotePrice={this.props.quoteIndiceData.quoteSP500}
                priceChangePercentToday={this.props.quoteIndiceData.changePctSP500}
                priceChangeToday={this.props.quoteIndiceData.changeSP500}
                isMarketOpen={this.props.isMarketOpen}
                style={style}
                openSnapShot={this.props.openSnapShot} />
            </div>
          }
          <div className={`${headerStyles['search-symbol']}`}>
            <CompanySearch page={this.props.page || ''} />
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  fetching: PropTypes.bool.isRequired,
  setFetchingDataFlag: PropTypes.func.isRequired,
  quoteIndiceData: PropTypes.object.isRequired,
  requestIndicesV2Data: PropTypes.func.isRequired,
  isMarketOpen: PropTypes.bool,
  isMobileView: PropTypes.bool.isRequired,
  openSnapShot: PropTypes.func,
  page: PropTypes.string
}
function mapStateToProps (state) {
  return {
    fetching: state.marketsV2Reducer.fetching,
    isMarketOpen: state.marketsV2Reducer.quoteData.marketOpen === 1,
    quoteIndiceData: state.marketsV2Reducer.quoteIndiceData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(MarketsV2Actions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestIndicesV2Data: (wsodIssue) => {
      return dispatch(MarketsV2Actions.requestIndicesV2Data(wsodIssue))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
