/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import HoldingsFundamentalsTableStyles
  from '../../../styles/watchlist/holdingsFundamentalsTable.scss'
import WatchlistsStyles from '../../../styles/watchlist/watchlists.scss'
import { Format } from 'stifel-intyce-pattern-library'
import { mapIndicesSymbol } from '../../common.v2/CommonMethods'
import commonStyles from '../../../styles/common.v2/common.scss'
import { SHOW_DIVIDEND_DATA } from '../../../utils/appConstants'

const HoldingsFundamentalsTable = props => {
  if (props.holdings.length === 0) {
    return (
      <div className={`${HoldingsFundamentalsTableStyles['tbl-holdings']}`}>
        <div className={`${HoldingsFundamentalsTableStyles['no-data']}`}>
          Add securities to get started.
        </div>
      </div>
    )
  }

  function renderSortIcon (sortBy) {
    return props.sortBy.columnName === sortBy &&
    <span className={`${WatchlistsStyles[ 'arrow-' + props.sortBy.order ]}`} />
  }

  return (
    <div className={`${HoldingsFundamentalsTableStyles['tbl-holdings']}`}>
      <div className={`${HoldingsFundamentalsTableStyles['header']}
        ${HoldingsFundamentalsTableStyles['header-1']}
        ${HoldingsFundamentalsTableStyles['row']}`} data-sort-by={props.sortBy.columnName}>

        <div className={`${HoldingsFundamentalsTableStyles['symbol']}`}>
          { renderSortIcon('symbol') }
          <span
            tabIndex='0'
            data-col-name='symbol'
            data-col-type='String'
            onClick={props.onColHeaderClick}
            onKeyPress={props.onColHeaderClickEnter}
            className={`${HoldingsFundamentalsTableStyles['col-header']}`}>
            Symbol / Name
          </span>
        </div>

        <div className={`${HoldingsFundamentalsTableStyles['ratioPE']}`}>
          { renderSortIcon('ratioPE') }
          <span
            tabIndex='0'
            data-col-name='ratioPE'
            data-col-type='Number'
            onClick={props.onColHeaderClick}
            onKeyPress={props.onColHeaderClickEnter}
            className={`${HoldingsFundamentalsTableStyles['col-header']}`}>
            Price/Earnings
          </span>
        </div>

        <div className={`${HoldingsFundamentalsTableStyles['eps']}`}>
          { renderSortIcon('earningsEstimate12M') }
          <span
            tabIndex='0'
            data-col-name='earningsEstimate12M'
            data-col-type='Number'
            onClick={props.onColHeaderClick}
            onKeyPress={props.onColHeaderClickEnter}
            className={`${HoldingsFundamentalsTableStyles['col-header']}`}>
            Earnings Per Share
          </span>
        </div>

        {
          SHOW_DIVIDEND_DATA &&
          <div className={`${HoldingsFundamentalsTableStyles['dividend']}`}>
            { renderSortIcon('dividendAmountGross') }
            <span
              data-col-name='dividendAmountGross'
              data-col-type='Number'
              onClick={props.onColHeaderClick}
              className={`${HoldingsFundamentalsTableStyles['col-header']}`}>
              Dividend
            </span>
          </div>
        }

        {
          SHOW_DIVIDEND_DATA &&
          <div className={`${HoldingsFundamentalsTableStyles['dividend-yield']}`}>
            { renderSortIcon('dividendYieldGross') }
            <span
              data-col-name='dividendYieldGross'
              data-col-type='Number'
              onClick={props.onColHeaderClick}
              className={`${HoldingsFundamentalsTableStyles['col-header']}`}>
              Dividend Yield
            </span>
          </div>
        }

        <div className={`${HoldingsFundamentalsTableStyles['action']}`}>
          &nbsp;
        </div>
      </div>

      <div data-symbols-container='true'>
        {
          props.holdings.map((item, index) => (
            <div className={`${HoldingsFundamentalsTableStyles['row']} ${HoldingsFundamentalsTableStyles['data-row']}`}
              key={item.xid} data-holding-row={item.holdingId}>
              <div tabIndex='0' className={`${HoldingsFundamentalsTableStyles['symbol']}`}
                data-wsod-issue={item.xid}
                data-symbol={mapIndicesSymbol(item.xid, item.symbol)}
                onClick={props.onSymbolClick}
                onKeyDown={props.onSymbolClickEnter}>
                <div className={`${HoldingsFundamentalsTableStyles['name']}`}>
                  { mapIndicesSymbol(item.xid, item.symbol) }
                </div>
                <div className={`${HoldingsFundamentalsTableStyles['company']}`} title={item.name}>
                  { item.name }
                </div>
              </div>

              <div className={`${HoldingsFundamentalsTableStyles['ratioPE']}`}>
                { Format.formatNumber(item.ratioPE, {negSign: true}) }
              </div>

              <div className={`${HoldingsFundamentalsTableStyles['eps']}`}>
                { Format.formatNumber(item.earningsEstimate12M,
                  {currencyPreFix: true, negSign: true, precision: 3}) }
              </div>

              {
                SHOW_DIVIDEND_DATA &&
                <div className={`${HoldingsFundamentalsTableStyles['dividend']}`}>
                  { /* This may need to be changed to the abreviateNumber function depending on how big these numbers get, will know more once data is pulled */ }
                  { Format.formatNumber(item.dividendAmountGross, {currencyPreFix: true}) }
                </div>
              }

              {
                SHOW_DIVIDEND_DATA &&
                <div className={`${HoldingsFundamentalsTableStyles['dividend-yield']}`}>
                  { Format.formatNumber(item.dividendYieldGross, {postFix: true}) }
                </div>
              }

              <div data-holdingid={item.holdingId} data-symbol={item.symbol}
                onClick={props.onDeleteSymbolClick}
                onKeyDown={props.onDeleteSymbolClickEnter}
                tabIndex='0'
                className={`${HoldingsFundamentalsTableStyles['action']}`}>
                <span className={`${WatchlistsStyles['sprites-img']} ${WatchlistsStyles['del-icon']}  ${HoldingsFundamentalsTableStyles['del-icon']}`}>
                  &nbsp;
                </span>
              </div>
              {
                props.deletingSymbolInfo.holdingId === item.holdingId && props.deletingSymbolInfo.deleting &&
                <div className={`${commonStyles['loader']} ${WatchlistsStyles['deleting-symbol']}`}>
                  <div className={`${commonStyles['rollingloader']}`}>
                    &nbsp;
                  </div>
                  <div className={`${WatchlistsStyles['deleting-symbol-text']}`}>
                    Deleting...
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

HoldingsFundamentalsTable.propTypes = {
  holdings: PropTypes.array,
  onSymbolClick: PropTypes.func,
  onSymbolClickEnter: PropTypes.func,
  onDeleteSymbolClick: PropTypes.func,
  onDeleteSymbolClickEnter: PropTypes.func,
  deletingSymbolInfo: PropTypes.object,
  onColHeaderClick: PropTypes.func,
  onColHeaderClickEnter: PropTypes.func,
  sortBy: PropTypes.object
}

export default HoldingsFundamentalsTable
