/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import 'babel-polyfill'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MAX_SECURITIES, WATCHLIST_MESSAGES, WATCHLISTS_WATCHLISTTAB_CONSTANTS, LOCALSTORAGETYPE_CONSTANTS } from '../../../../utils/appConstants'
import WatchlistStyles from '../../../../styles/watchlist/watchlist.scss'
import CompanySearch from '../../common.v2/header/headerSubComponents/Container'
import WatchlistHoldings from './WatchlistHoldings'
import * as WatchlistActions from '../../../../actions/watchlist.Actions'
import { Tabs } from 'stifel-intyce-pattern-library'
import AsOfDate from './AsOfDate'
import { GetLocalStorageIfAvailable, SetSpecificKeysIntoLocalStorage, getLargestDate } from '../../../common.v2/CommonMethods'

class Watchlist extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      symbol: '',
      asOfDate: null,
      mfAsOfDate: null,
      activeTab: this.setActiveTab()
    }
    this.addToWatchlist = this.addToWatchlist.bind(this)
    this.onTabClick = this.onTabClick.bind(this)
  }

  componentDidMount () {
  }

  componentWillReceiveProps (nextProps) {
    if (this.state.symbol && nextProps.symbolsToWatchlistResponse) {
      let error = nextProps.symbolsToWatchlistResponse.error
      if (error) {
        if (error.message.indexOf('already exists') !== -1) {
          this.props.notificationHandler({show: true, message: `Error: ${this.state.symbol} is already part of "${this.props.watchlistInfo.name}" Watchlist.`})
        } else {
          this.props.notificationHandler({show: true})
        }
      } else {
        this.props.notificationHandler({show: true, type: 'SUCCESS', message: WATCHLIST_MESSAGES.ADDED_SYMBOL(this.state.symbol)})
        // start loading on watchlist holdings
        this.props.setFetchingDataFlag({
          watchlistHoldings_Platform: {
            fetching: true
          }
        })
        this.props.requestWatchlistHoldings(this.props.watchlistInfo.id, this.state.activeTabname === 'Fundamentals')
      }

      // Reset isServerResponse
      this.props.setFetchingDataFlag({
        symbolsToWatchlist_Platform: {
          isServerResponse: false,
          data: {
            holdings: []
          }
        }
      })
    }
    if (nextProps.holdings) {
      this.setState({
        asOfDate: null,
        mfAsOfDate: null
      })
    }
    if (nextProps.holdings && nextProps.holdings.length > 0) {
      let dates = getLargestDate(nextProps.holdings)
      if (dates.dateMf) {
        this.setState({mfAsOfDate: `${dates.dateMf}`})
      }
      if (dates.date) {
        this.setState({asOfDate: `${dates.date}`})
      }
    }
  }

  addToWatchlist (xid, symbol) {
    if (this.props.totalSymbols >= MAX_SECURITIES) {
      this.props.notificationHandler({show: true, message: WATCHLIST_MESSAGES.MAX_SECURITIES})
      return
    }
    this.setState({symbol: symbol})
    this.props.requestAddSymbolToWatchlist(this.props.watchlistInfo.id, xid)
  }

  getAsOfDateHtml () {
    return !this.props.isMobileView && <div className={WatchlistStyles['as-of-date-container']}>
      <AsOfDate asOfDate={this.state.asOfDate} mfAsOfDate={this.state.mfAsOfDate} />
    </div>
  }
  onTabClick (tabLabel) {
    this.state.activeTabname = tabLabel
    SetSpecificKeysIntoLocalStorage(LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS, {
      [WATCHLISTS_WATCHLISTTAB_CONSTANTS]: tabLabel
    })
  }
  setActiveTab () {
    let activeTab = GetLocalStorageIfAvailable(LOCALSTORAGETYPE_CONSTANTS.WATCHLISTS, WATCHLISTS_WATCHLISTTAB_CONSTANTS)
    return activeTab || ''
  }

  render () {
    return (
      <div className={`${WatchlistStyles['watchlist-container']}`}>
        <div className={`${WatchlistStyles['search-symbol-container']}`}>
          <CompanySearch
            placeHolderText='Add symbol'
            searchForWatchlist
            onClick={this.addToWatchlist} />
        </div>

        <div className={`${WatchlistStyles['tabs-container']}`}>
          <Tabs tabIndex='0' activeTab={!(this.state.activeTab && this.state.activeTab.length > 0) ? 'Performance' : this.state.activeTab} onClick={this.onTabClick}>
            <div label={'Performance'} key={'Performance'}>
              <div className={`${WatchlistStyles['tab']}`}>
                { this.getAsOfDateHtml() }
                <div className={`${WatchlistStyles['tab-content-container']}`}>
                  <WatchlistHoldings
                    watchlistId={this.props.watchlistInfo.id}
                    onSymbolClick={this.props.onSymbolClick}
                    onSymbolClickEnter={this.props.onSymbolClickEnter}
                    holdingsBy='Performance'
                    notificationHandler={this.props.notificationHandler}
                    isMobileView={this.props.isMobileView} />
                </div>
              </div>
            </div>
            <div label={'Fundamentals'} key={'Fundamentals'}>
              <div className={`${WatchlistStyles['tab']}`}>
                { this.getAsOfDateHtml() }
                <div className={`${WatchlistStyles['tab-content-container']}`}>
                  <WatchlistHoldings
                    watchlistId={this.props.watchlistInfo.id}
                    onSymbolClick={this.props.onSymbolClick}
                    onSymbolClickEnter={this.props.onSymbolClickEnter}
                    holdingsBy='Fundamentals'
                    notificationHandler={this.props.notificationHandler}
                    isMobileView={this.props.isMobileView} />
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    )
  }
}

Watchlist.propTypes = {
  watchlistInfo: PropTypes.object.isRequired,
  setFetchingDataFlag: PropTypes.func.isRequired,
  symbolsToWatchlistResponse: PropTypes.object,
  requestAddSymbolToWatchlist: PropTypes.func.isRequired,
  requestWatchlistHoldings: PropTypes.func.isRequired,
  onSymbolClick: PropTypes.func.isRequired,
  onSymbolClickEnter: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
  totalSymbols: PropTypes.number,
  isMobileView: PropTypes.bool,
  holdings: PropTypes.array
}

function mapStateToProps (state, ownProps) {
  let obj = {
  }

  if (state.watchlistReducer.symbolsToWatchlist_Platform.isServerResponse) {
    obj.symbolsToWatchlistResponse = {}

    if (state.watchlistReducer.symbolsToWatchlist_Platform.error) {
      obj.symbolsToWatchlistResponse['error'] = state.watchlistReducer.symbolsToWatchlist_Platform.error
    } else {
      //
    }
  }

  if (state.watchlistReducer.watchlistHoldings_Platform.data && state.watchlistReducer.watchlistHoldings_Platform.data.holdings) {
    obj.holdings = state.watchlistReducer.watchlistHoldings_Platform.data.holdings
    obj.totalSymbols = state.watchlistReducer.watchlistHoldings_Platform.data.holdings.length
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(WatchlistActions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestAddSymbolToWatchlist: (id, xid) => {
      return dispatch(WatchlistActions.requestAddSymbolToWatchlist(id, xid))
    },
    requestWatchlistHoldings: (id, updateFetchBool) => {
      return dispatch(WatchlistActions.requestWatchlistHoldings(id, false, updateFetchBool))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Watchlist)
