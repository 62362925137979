/* eslint-disable jsx-a11y/no-onchange */
import React from 'react'
import PropTypes from 'prop-types'
import MarketMoversTable from '../../common.v2/MarketMoversTable'
import TopMover from '../topMover/TopMover'
import marketsStyles from '../../../../styles/markets.V2/markets.scss'
import marketMoversStyles from '../../../../styles/markets.V2/marketMovers/marketMovers.scss'
import { globalConfig } from '../../../../utils/globalConfig'
import commonStyles from '../../../../styles/common.v2/common.scss'

export default class MarketMovers extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      exchange: 'NYSE',
      rankingType: 'MostActive',
      rowCount: 10,
      fetching: true
    }

    this.loadMarketMoversData = this.loadMarketMoversData.bind(this)
    this.handleSelectionChange = this.handleSelectionChange.bind(this)
  }

  componentDidMount () {
    this.loadMarketMoversData()
  }

  handleSelectionChange (e) {
    e.preventDefault()

    this.setState({
      fetching: true,
      exchange: e.currentTarget.getAttribute('data-filter-type') === 'exchange'
        ? e.currentTarget.value : this.state.exchange,
      rankingType: e.currentTarget.getAttribute('data-filter-type') === 'rank'
        ? e.currentTarget.value : this.state.rankingType
    }, () => {
      this.loadMarketMoversData()
    })
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      fetching: false
    })
  }

  loadMarketMoversData () {
    this.props.requestMarketsV2MoversData(this.state.exchange, this.state.rankingType, this.state.rowCount)
  }

  render () {
    return (
      <div className={`${marketMoversStyles['market-movers-parent-container']}`}>
        <div className={`${marketMoversStyles['top-header-container']}`}>
          <h2>Company Movers</h2>
        </div>

        <div className={`${marketMoversStyles['market-movers-container']}`}>
          <div className={`${marketMoversStyles['market-movers']}`}>
            <label htmlFor='mostActiveSecurities' aria-label='Most Active Securities' className={`${marketMoversStyles['lbl-hidden']}`}>Most Active Securities</label>
            <select id='mostActiveSecurities' data-el-identifier='company-movers_actives'
              className={`${marketMoversStyles['ddl-actives']}`}
              onChange={this.handleSelectionChange}
              data-filter-type='rank'>
              <option value='MostActive'>Most Actives</option>
              <option value='Gainers'>Gainers</option>
              <option value='Losers'>Losers</option>
            </select>

            <label htmlFor='marketType' aria-label='Market Type' className={`${marketMoversStyles['lbl-hidden']}`}>Market Type</label>
            <select id='marketType' data-el-identifier='company-movers_exchange'
              className={`${marketMoversStyles['ddl-exchanges']}`}
              onChange={this.handleSelectionChange}
              data-filter-type='exchange'>
              <option value='NYSE'>NYSE</option>
              <option value='NASDAQ'>NASDAQ</option>
            </select>

            <div className={`${marketMoversStyles['tbl-market-movers-container']}`}>
              {
                // Fetching records
                this.state.fetching &&
                  <div className={`${commonStyles['loader']} ${marketsStyles['loader-no-border']}`}>
                    <div className={`${commonStyles['rollingloader']}`} />
                  </div>
              }

              {
                // Error occurred
                !this.state.fetching && this.props.isErrorOccurred &&
                  <div className={`${marketMoversStyles['error-container']}`}>
                    Oops! There was an error processing your request. Please try again.<br />
                  </div>
              }

              {
                // Render markets movers data
                !this.state.fetching && !this.props.isErrorOccurred &&
                  <MarketMoversTable
                    marketMovers={this.props.allRankData}
                    rankType={this.state.rankingType}
                    onClick={this.props.openSnapShot} />
              }

            </div>
          </div>
          { this.props.watchlists && this.props.watchlists.length > 0 && globalConfig.topmover &&
            <TopMover watchlists={this.props.watchlists} onClick={this.props.openSnapShot} />
          }
        </div>
      </div>
    )
  };
};

MarketMovers.propTypes = {
  requestMarketsV2MoversData: PropTypes.func.isRequired,
  isErrorOccurred: PropTypes.bool.isRequired,
  allRankData: PropTypes.array.isRequired,
  openSnapShot: PropTypes.func,
  watchlists: PropTypes.array
}
