/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ISSUE_TYPES } from '../../../../utils/appConstants'
import snapshotStyles from '../../../../styles/snapshot.V2/snapshot.scss'
import newsStyles from '../../../../styles/snapshot.V2/news/newsContainer.scss'
import marketsV2NewsStyles from '../../../../styles/markets.V2/news/news.scss'
import newsLinkListStyles from '../../../../styles/snapshot.V2/news/newsLinkList.scss'
import NewsLinkListItem from './NewsLinkListItem'
import * as SnapshotV2Actions from '../../../../actions/snapshot.V2.Actions'
import { Format } from 'stifel-intyce-pattern-library'
import commonStyles from '../../../../styles/common.v2/common.scss'

class NewsLinkList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      wsodIssue: null,
      articleId: null,
      totalPages: null,
      pageNumber: null,
      pageNumberTextBoxValue: null,
      pageSize: 10,
      fetching: true,
      showNewsArticle: false,
      fetchingImage: false
    }

    this.expandCollapseNews = this.expandCollapseNews.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.loadNews = this.loadNews.bind(this)
    this.loadPrevious = this.loadPrevious.bind(this)
    this.loadNext = this.loadNext.bind(this)
    this.handleFocusOut = this.handleFocusOut.bind(this)
    this.getLeftArrow = this.getLeftArrow.bind(this)
    this.getRightArrow = this.getRightArrow.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidMount () {
    this.setState({
      wsodIssue: this.props.wsodIssue,
      pageNumber: 1,
      pageNumberTextBoxValue: 1
    }, () => {
      if (this.props.currentPageNumber) {
        this.setState({
          pageNumber: this.props.currentPageNumber,
          pageNumberTextBoxValue: this.props.currentPageNumber
        })

        let inputValue = this.props.currentPageNumber
        let index = ((inputValue - 1) * this.state.pageSize)
        this.loadNews(index + this.props.rowStartNumber)
      } else {
        this.loadNews(this.props.rowStartNumber)
      }
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.props.isMobileView) {
      // let btnShowMore = document.getElementById('btnShowMore')

      // if (btnShowMore) {
      //   btnShowMore.scrollIntoView({behavior: 'smooth'})
      // }
    }
  }

  componentWillReceiveProps (nextProps) {
    if (!this.state.fetchingImage && nextProps.news.length > 0) {
      nextProps.news.map((item, index) => {
        if (item.documentKey.indexOf('2993-') === 0 || item.documentKey.indexOf('1-') === 0) {
          this.props.requestSnapshotV2NewsImageData(item.documentKey)
        }
      })
    }

    this.setState({
      fetching: false,
      fetchingImage: true,
      totalPages: Math.ceil(nextProps.totalMatches / this.state.pageSize)
    })

    if (nextProps.newsImage && nextProps.newsImage.image && nextProps.newsImage.image.Url) {
      this.props.news.map((news, index) => {
        if (news.documentKey === nextProps.newsImage.image.documentKey) {
          news.image = {
            Url: nextProps.newsImage.image.Url
          }
        }
      })
    }
  }

  loadNews (pageNumber, noOfRecords) {
    this.setState({
      fetching: true
    })

    if (this.props.isMarketsNews) {
      let btnShowMore = document.getElementById('btnShowMore')
      if (btnShowMore) {
        btnShowMore.scrollIntoView()
      }
    }

    let xid = ''
    let newsProvider

    switch (this.props.securityType) {
      case ISSUE_TYPES.Stock:
        newsProvider = 'Stock'
        xid = this.state.wsodIssue
        break
      case ISSUE_TYPES.Etf:
        newsProvider = 'ETF'
        break
      case ISSUE_TYPES.Indices:
        newsProvider = 'Top News'
        break
      case ISSUE_TYPES.MutualFund:
        xid = this.state.wsodIssue
        break
      default:
        newsProvider = this.props.newsCategory
        break
    }

    this.props.requestSnapshotV2NewsData(
      this.props.securityType,
      xid,
      newsProvider,
      pageNumber - 1,
      noOfRecords || this.state.pageSize,
      true,
      'Small'
    )
  }

  expandCollapseNews () {
    this.setState({
      showMore: !this.state.showMore
    })
  }

  loadPrevious () {
    if (this.state.pageNumber !== 1) {
      let index = (parseInt(this.state.pageNumber) - 2) * this.state.pageSize

      this.setState((prevState) => {
        return {
          pageNumber: prevState.pageNumber - 1,
          pageNumberTextBoxValue: prevState.pageNumber - 1
        }
      })

      this.loadNews(index + this.props.rowStartNumber)
    }
  }

  loadNext () {
    if (this.state.pageNumber !== this.state.totalPages) {
      let index = 0

      if (this.state.pageNumber) {
        index = parseInt(this.state.pageNumber) * this.state.pageSize
      }

      if (!this.state.pageNumber) {
        this.setState({
          pageNumber: 1,
          pageNumberTextBoxValue: 1
        })
      } else {
        this.setState((prevState) => {
          return {
            pageNumber: (prevState.pageNumber + 1),
            pageNumberTextBoxValue: (prevState.pageNumber + 1)
          }
        })
      }

      this.loadNews(index + this.props.rowStartNumber)
    }
  }

  handleChange (event) {
    let inputValue = event.target.value.trim()

    if (isNaN(inputValue) || inputValue.indexOf('.') !== -1) {
      return false
    } else if (inputValue === '') {
      this.setState({
        pageNumberTextBoxValue: inputValue
      })
      return false
    }

    inputValue = parseInt(inputValue)

    if (inputValue > this.state.totalPages) {
      inputValue = parseInt(this.state.totalPages)
    } else if (inputValue < 1) {
      inputValue = 1
    }

    this.setState({
      pageNumberTextBoxValue: inputValue
    })
  }

  handleFocusOut (event) {
    let inputValue = event.target.value.trim()

    if (inputValue === '') {
      this.setState({
        pageNumber: 1,
        pageNumberTextBoxValue: 1
      })
      this.loadNews(this.props.rowStartNumber)
    } else {
      this.setState({
        pageNumber: this.state.pageNumberTextBoxValue
      })
      let index = ((inputValue - 1) * this.state.pageSize)
      this.loadNews(index + this.props.rowStartNumber)
    }
  }

  handleKeyPress (event) {
    if (event.charCode === 13) {
      this.setState({
        pageNumber: this.state.pageNumberTextBoxValue
      })
      let inputValue = event.target.value.trim()
      let index = ((inputValue - 1) * this.state.pageSize)
      this.loadNews(index + this.props.rowStartNumber)
    }
  }

  getLeftArrow () {
    if (this.state.pageNumber <= 1 ||
      this.props.totalMatches === 0 ||
      this.state.pageNumber > this.state.totalPages) {
      return (
        <span className={`${newsLinkListStyles['icon']} ${newsLinkListStyles['icon-left-disabled']}`} />
      )
    }
    return (
      <span className={`${newsLinkListStyles['icon']} ${newsLinkListStyles['icon-left-active']}`} />
    )
  }

  getRightArrow () {
    if (this.state.pageNumber < 1 ||
      this.props.totalMatches === 0 ||
      this.state.pageNumber >= this.state.totalPages) {
      return (
        <span className={`${newsLinkListStyles['icon']} ${newsLinkListStyles['icon-right-disabled']}`} />
      )
    }
    return (
      <span className={`${newsLinkListStyles['icon']} ${newsLinkListStyles['icon-right-active']}`} />
    )
  }

  getPaginationLabel () {
    let temp = this.state.pageSize * this.state.pageNumber
    let current = (temp - (this.state.pageSize - 1)) + '-' + (temp > this.props.totalMatches ? this.props.totalMatches : temp)

    return 'Showing ' + current + ' of ' + Format.numberComma(this.props.totalMatches) + ' Articles'
  }

  render () {
    if (this.state.fetching) {
      return (
        <div className={`${newsLinkListStyles['news-list-container']} ${newsLinkListStyles['loading']} ${marketsV2NewsStyles['news-list-container']}`}>
          <div className={`${commonStyles['loader']}`}>
            <div className={`${commonStyles['rollingloader']}`} />
          </div>
        </div>
      )
    }

    if (this.props.isErrorOccurred) {
      return (
        <div className={`${snapshotStyles['error-container']}`}>
          <div>
            Oops! There was an error processing your request. Please try again.<br />
            <div className={`${snapshotStyles['error-info']}`}>
              (Error Code: {this.props.errorInfo.code}, Message: {this.props.errorInfo.message})
            </div>
          </div>
        </div>
      )
    }

    if (this.props.totalMatches === 0) {
      return (
        <div className={`${newsStyles['news-container']}`}>
          <div className={`${newsStyles['no-news']}`}>
            <div>
              Oops! We couldn't find related news stories.<br />
            </div>
          </div>
        </div>
      )
    }

    return (
      this.props.news && this.props.news.length > 0 && !this.state.showMore &&
      <div className={`${newsLinkListStyles['news-list-container']} ${marketsV2NewsStyles['news-list-container']}`}>
        {
          this.props.news.map(
            (item, index) => (
              <NewsLinkListItem key={index} news={item} onClick={this.props.onClick} />
            )
          )
        }

        {
          this.state.totalPages > 0 &&
          <div className={`${newsLinkListStyles['pagination']} ${marketsV2NewsStyles['pagination']}`}>
            <div data-el-identifier='news-list_count' className={`${newsLinkListStyles['lbl-records-count']}`}>
              {this.getPaginationLabel()}
            </div>
            <div data-el-identifier='news-list_pagination' className={`${newsLinkListStyles['action-buttons']}`}>
              <span onClick={this.loadPrevious}>{this.getLeftArrow()}</span>
              Page <input type='text' id='txtPageNumber' autoComplete='off' value={this.state.pageNumberTextBoxValue}
                onChange={this.handleChange} onKeyPress={this.handleKeyPress} onBlur={this.handleFocusOut} /> of {this.state.totalPages}
              <span onClick={this.loadNext}>{this.getRightArrow()}</span>
            </div>
          </div>
        }
      </div>
    )
  };
};

NewsLinkList.propTypes = {
  isErrorOccurred: PropTypes.bool.isRequired,
  isMobileView: PropTypes.bool,
  totalMatches: PropTypes.number.isRequired,
  news: PropTypes.array.isRequired,
  wsodIssue: PropTypes.string,
  securityType: PropTypes.string.isRequired,
  errorInfo: PropTypes.object.isRequired,
  requestSnapshotV2NewsData: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  rowStartNumber: PropTypes.number.isRequired,
  currentPageNumber: PropTypes.number,
  newsCategory: PropTypes.string,
  requestSnapshotV2NewsImageData: PropTypes.func,
  newsImage: PropTypes.object,
  isMarketsNews: PropTypes.bool
}

function mapStateToProps (state, ownProps) {
  let dataStoreKeyName = 'newsData'

  if (ownProps.isMarketsNews) {
    dataStoreKeyName = 'marketsNewsData'
  }

  let obj = {
    fetching: state.snapshotV2Reducer[dataStoreKeyName].fetching,
    isErrorOccurred: !!state.snapshotV2Reducer[dataStoreKeyName].error,
    errorInfo: state.snapshotV2Reducer[dataStoreKeyName].error || {},
    news: [],
    totalMatches: 0
  }

  if (state.snapshotV2Reducer[dataStoreKeyName].data) {
    let totalMatches = state.snapshotV2Reducer[dataStoreKeyName].data.totalMatches

    let noRecordsToExclude = ownProps.noRecordsToExclude

    Object.assign(obj, {
      news: state.snapshotV2Reducer[dataStoreKeyName].data.totalMatches === 0
        ? [] : state.snapshotV2Reducer[dataStoreKeyName].data.news,
      totalMatches: totalMatches > noRecordsToExclude ? totalMatches - noRecordsToExclude : totalMatches
    })
  }

  if (state.snapshotV2Reducer.newsImage &&
    state.snapshotV2Reducer.newsImage.length > 0) {
    obj.newsImage = {
      image: {
        Url: state.snapshotV2Reducer.newsImage[0].imageBase64Data,
        documentKey: state.snapshotV2Reducer.newsImage[0].documentKey
      }
    }
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    requestSnapshotV2NewsImageData: (documentKey) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2NewsImageData(documentKey, 'Small'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsLinkList)
