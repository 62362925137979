/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
import { Format } from 'stifel-intyce-pattern-library'
import { RemvoveHTMLTags, GetTitleToDisplay } from '../../snapshot.V2/SubComponents/News/Common'
import SparkChartContainer from './SparkChartContainer'
import pureGridStyles from '../../../styles/pure-grid.scss'
import marketTodayStyles from '../../../styles/markets.V2/marketToday/marketToday.scss'

class MarketToday extends React.Component {
  constructor (props) {
    super(props)
    this.loadNewsArticle = this.loadNewsArticle.bind(this)
  }
  loadNewsArticle (e) {
    if (!e) return
    e.preventDefault()

    let path = '/Markets/V2/News/Article/' + this.props.marketsTodayNews.documentKey
    hashHistory.push(path)
  }
  render () {
    const newsMiddleContainer = <div className={`${marketTodayStyles['news-middle-container']}`}>
      <div className={`${marketTodayStyles['news-image-container']}`}>
        {
          this.props.marketsTodayNews.image && this.props.marketsTodayNews.image.Url &&
          <div className={`${marketTodayStyles['small-news-img']}`}>
            <img alt='News' src={this.props.marketsTodayNews.image.Url} />
          </div>
        }
        <div className={`${marketTodayStyles['news-head-line']}`}>
          <span>
            {GetTitleToDisplay(this.props.marketsTodayNews.title)}
          </span>
        </div>
      </div>
      <div className={`${marketTodayStyles['exchange-name-container']}`}>
        <span className={`${marketTodayStyles['exchange-name']}`}>Dow Jones</span>
        <span>
          {Format.getFormattedDateTime(this.props.marketsTodayNews.date, {tzConversion: true})}
        </span>
      </div>
      <div className={`${marketTodayStyles['bottom-news-disc']}`}>
        <span dangerouslySetInnerHTML={RemvoveHTMLTags(this.props.marketsTodayNews.teaser, 250)} />
      </div>
      <div className={`${marketTodayStyles['read-full-story']}`} onClick={this.loadNewsArticle}>
        <span>Read Full Story</span>
        <span className={`${marketTodayStyles['back-arrow']}`} />
      </div>
    </div>

    return (
      <div className={`${marketTodayStyles['market-today']}`}>
        { !this.props.isMobileView &&
          <div className={`${marketTodayStyles['head-line-container']}`}>
            <h1 data-el-identifier='marketstoday_heading' className={`${marketTodayStyles['head-line-text']}`}>Markets Today</h1>
          </div>
        }
        <div className={`${pureGridStyles['pure-g']} ${marketTodayStyles['container']}`}>
          <div className={`${pureGridStyles['pure-u-1']} ${pureGridStyles['pure-u-lg-13-24']} ${pureGridStyles['pure-u-md-13-24']} ${pureGridStyles['pure-u-sm-1']}`}>
            <div className={`${marketTodayStyles['market-new-container']}`}>
              <div className={`${marketTodayStyles['today-date']}`}>
                <div>{Format.todayDayName()},</div>
                <div>{Format.marketTodayDate()}</div>
              </div>
              { !this.props.isMobileView &&
                newsMiddleContainer
              }
            </div>
          </div>
          <div className={`${pureGridStyles['pure-u-1']} ${pureGridStyles['pure-u-lg-11-24']} ${pureGridStyles['pure-u-md-11-24']} ${pureGridStyles['pure-u-sm-1']}`}>
            <SparkChartContainer
              isMarketOpen={this.props.isMarketOpen}
              quoteIndiceData={this.props.quoteIndiceData}
              openSnapShot={this.props.openSnapShot} />
          </div>
        </div>
      </div>
    )
  }
}

MarketToday.propTypes = {
  quoteIndiceData: PropTypes.object.isRequired,
  marketsTodayNews: PropTypes.object.isRequired,
  isMarketOpen: PropTypes.bool.isRequired,
  isMobileView: PropTypes.bool.isRequired,
  openSnapShot: PropTypes.func
}

export default MarketToday
