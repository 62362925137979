import React from 'react'
import PropTypes from 'prop-types'
import { MAX_WATCHLISTS, WATCHLIST_MESSAGES } from '../../../../utils/appConstants'
import WatchlistStyles from '../../../../styles/watchlist/watchlists.scss'
import { UserTypeIndicator } from 'stifel-intyce-pattern-library'

const Footer = (props) => {
  let maxLimit = props.watchlistCount >= MAX_WATCHLISTS
  return (
    <div className={WatchlistStyles['footer-container']}>
      <div className={WatchlistStyles['footer-buttons']}>
        <button data-el-identifier='manage-watchlist_value' className={`${WatchlistStyles['button']} ${WatchlistStyles['white']} ${props.watchlistCount === 0 ? WatchlistStyles['no-watchlist'] : ''}`}
          onClick={props.manageWatchlists} disabled={props.watchlistCount === 0}>
          Manage Watchlists
        </button>
        <button data-el-identifier='create-watchlist_value' className={`${WatchlistStyles['button']}
          ${WatchlistStyles['blue']}
          ${maxLimit ? WatchlistStyles['max-limit-watchlist'] : ''}`} onClick={maxLimit ? '' : props.createWatchlist}>
          { !maxLimit ? 'Create Watchlist' : WATCHLIST_MESSAGES.MAX_WATCHLISTS }
        </button>
      </div>
      <div className={`${WatchlistStyles['mob-user-type-indicator']}`}>
        <UserTypeIndicator userType='Navigator' />
      </div>
    </div>
  )
}

Footer.propTypes = {
  createWatchlist: PropTypes.func.isRequired,
  watchlistCount: PropTypes.number.isRequired,
  manageWatchlists: PropTypes.func.isRequired
}

export default Footer
