import Types from '../actions/types'
import { deepMerge } from '../../src/utils/deepMerge'
import { createReducer } from 'reduxsauce'
import INITIAL_STATE from './initial-state/snapshot.V2'

const receiveMarketsV2Data = (state = INITIAL_STATE, action) => {
  return Object.assign({}, state, {
    fetching: false,
    quoteData: Object.assign({}, state.quoteData, action.payload.quoteData.data),
    quoteIndiceData: Object.assign({}, state.quoteIndiceData, action.payload.quoteIndiceData.data),
    marketsTodayNews: deepMerge(state.marketsTodayNews, action.payload.marketsTodayNews)
  })
}

const receiveMarketsTreasuryYield = (state = INITIAL_STATE, action) => {
  return Object.assign({}, state, {
    marketsTreasuryYield: Object.assign({}, state.marketsTreasuryYield, action.data.marketsTreasuryYield.data)
  })
}

const receiveIndicesV2Data = (state = INITIAL_STATE, action) => {
  return Object.assign({}, state, {
    fetching: false,
    quoteData: Object.assign({}, state.quoteData, action.payload.quoteData.data),
    quoteIndiceData: Object.assign({}, state.quoteIndiceData, action.payload.quoteIndiceData.data)
  })
}

const receiveMarketsV2MoversData = (state = INITIAL_STATE, action) => {
  let objMoversResponse = {
    fetching: false
  }

  if (action.payload.marketsRankAll && action.payload.marketsRankAll.error) {
    Object.assign(objMoversResponse, action.payload.marketsRankAll)
  } else {
    objMoversResponse['data'] = action.payload.marketsRankAll.data
  }

  return Object.assign({}, state, {
    marketMovers: objMoversResponse
  })
}

const receiveSymbolSearchApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    symbolSearchResults: action.response.data
  })

const receiveDecodeApi = (state, action) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    xid: action.response.data
  })

const ACTION_HANDLERS = {
  [Types.GET_MARKETS_V2_DATA_API_SUCCESS]: receiveMarketsV2Data,
  [Types.GET_TREASURYYIELD_API_SUCCESS]: receiveMarketsTreasuryYield,
  [Types.GET_INDICES_V2_DATA_API_SUCCESS]: receiveIndicesV2Data,
  [Types.GET_MARKETS_V2_MOVERS_DATA_API_SUCCESS]: receiveMarketsV2MoversData,
  [Types.RECIEVE_SYMBOLSEARCH]: receiveSymbolSearchApi,
  [Types.RECIEVE_DECODE]: receiveDecodeApi
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
