/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import snapshotStyles from '../../../../styles/snapshot.V2/snapshot.scss'
import AddToWatchlists from './AddToWatchlists'
import AddToWatchlistsMobile from './AddToWatchlistsMobile'
import { Format } from 'stifel-intyce-pattern-library'
import { globalConfig } from '../../../../utils/globalConfig'

const TopHeader = props => {
  return <div className={`${snapshotStyles['top-header-content-container']}`}>
    <div className={`${snapshotStyles['complany-info']}`}>
      <div data-el-identifier='top-header_company-name' className={snapshotStyles['company-name']} title={props.quoteData.name}>
        { window.innerWidth < 424 ? (props.quoteData.name.length > 18 ? props.quoteData.name.substring(0, 17) + '...' : props.quoteData.name) : props.quoteData.name }
      </div>
      <div className={`${snapshotStyles['symbol-exchange-info']}`}>
        {props.quoteData.isStifelResearchAvailable === 1 &&
        <span className={`${snapshotStyles['stifel-research-icon']}`} />}
        <span data-el-identifier='top-header_symbol' className={`${snapshotStyles['symbol']}`}>{props.symbol}</span>
        <span data-el-identifier='top-header_exchange'>{props.quoteData.exchange}</span>
        <span data-el-identifier='top-header_date' className={`${snapshotStyles['snapshotDate']}`}>
          {Format.getFormattedDateTime(props.date, {format: 'MM/DD/YYYY', postFix: ' ', tzConversion: true})}
        </span>
      </div>
    </div>
    {
      !props.isMobileView && globalConfig.addtowatchlists && <AddToWatchlists symbol={props.symbol} wsodIssue={props.wsodIssue}
        notificationHandler={props.notificationHandler} />
    }

    {
      props.isMobileView && globalConfig.addtowatchlists && <AddToWatchlistsMobile
        symbol={props.symbol}
        wsodIssue={props.wsodIssue}
        notificationHandler={props.notificationHandler} />
    }
  </div>
}

TopHeader.propTypes = {
  symbol: PropTypes.string.isRequired,
  quoteData: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  wsodIssue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  notificationHandler: PropTypes.func,
  isMobileView: PropTypes.bool
}

export default TopHeader
