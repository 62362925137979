import Types from '../actions/types'
import AccessTokenService from '../services/access-token-service'
import MarkitApiService from '../services/markit-api-service'
import isDebugInfoOn from '../utils/isDebugInfoOn'
import writeToDebug from '../utils/writeToDebug'
import { setAccessToken, onGenericApiFailure } from '../actions/markets.V2.Action'
import { put, fork, call, take } from 'redux-saga/effects'
import { getApiTypes } from './getApiTypes'
import { IsClientAccess } from '../components/common.v2/CommonMethods'

// Use a 'watcher/worker' pattern to listen for and handle redux Actions
export default () => {
  let api = null
  let apiV2 = null
  let url = null

  function getApiFunction (httpMethod, apiCaller = api) {
    let apiType

    switch (httpMethod) {
      case 'POST':
        apiType = apiCaller.postData
        break
      case 'PUT':
        apiType = apiCaller.putData
        break
      case 'DELETE':
        apiType = apiCaller.deleteData
        break
      case 'GET':
      default:
        apiType = apiCaller.getData
    }

    return apiType
  }

  function getInputs (config, response) {
    if (config.resultKey === 'watchlistHoldings') {
      return {
        wsodIssues: response.data.data.items.map((h) => h.xid).join(','),
        watchlistId: config.params.watchlistId
      }
    }
  }

  function getResponse (config, resData, depData, parentConfig) {
    if (!resData.data) {
      return {
        resultKey: config.resultKey || config.endPoint,
        response: {
          error: {
            code: 400,
            message: 'Unknown Error'
          }
        }
      }
    }

    if (config.resultKey === 'watchlistHoldings') {
      if (depData.data && depData.data.data && depData.data.data.items &&
        resData.data && resData.data.data && resData.data.data.holdingsDetails) {
        for (let holding of depData.data.data.items) { // holdings
          for (let symbol of resData.data.data.holdingsDetails) { // symbol detail
            if (parseInt(holding.xid) === parseInt(symbol.xid)) {
              resData.data.data.holdingsDetails.find(
                item => parseInt(item.xid) === parseInt(holding.xid)).holdingId = holding.id
            }
          }
        }
      }

      return {
        inputs: parentConfig.params,
        resultKey: config.resultKey || config.endPoint,
        response: resData
      }
    }
  }

  function dependentAPICalls (config, depData, parentConfig) {
    const fn2 = getApiFunction(config.ajaxType, config.apiType === 'PLATFORM' ? apiV2 : api)
    return fn2(config.endPoint, getInputs(config, depData))
      .then(res => {
        return getResponse(config, res, depData, parentConfig)
      })
  }

  function loadImage (config, response, news, imgSize) {
    const fn2 = getApiFunction(config.ajaxType, api)

    let newsWithImg = news.filter(news => (
      news.documentKey.indexOf('2993-') === 0 ||
      news.documentKey.indexOf('1-') === 0
    )) || []

    if (newsWithImg.length === 0) {
      return {
        resultKey: config.resultKey || config.endPoint,
        response
      }
    }

    let imageParams = {
      documentKey: newsWithImg.map(item => {
        if (item.documentKey.indexOf('2993-') === 0 || item.documentKey.indexOf('1-') === 0) {
          return item.documentKey
        }
      }).join(','),
      size: imgSize
    }

    return fn2(config.imageEndpoint, imageParams)
      .then(imgResponse => {
        if (imgResponse.data && imgResponse.data.data) {
          imgResponse.data.data.map(imgRes => {
            news.map(news => {
              if (news.documentKey === imgRes.documentKey) {
                if (imgRes.imageBase64Data) {
                  news.image = {
                    Url: imgRes.imageBase64Data
                  }
                } else {
                  news.image = null
                }
              }
            })
          })
        }

        return {
          resultKey: config.resultKey || config.endPoint,
          response
        }
      })
  }

  function * multiWorkerSnapshotV2 (action) {
    const apiCalls = action.apiCalls

    const requestFn = apiCalls => {
      return Promise.all(
        apiCalls.map(config => {
          if (isDebugInfoOn()) {
            config.endPoint += (/\?/.test(config.endPoint) ? '&' : '?') + '..showdebugdata..=on';
          }
          const fn = getApiFunction(config.ajaxType, config.apiType === 'PLATFORM' ? apiV2 : api)

          return fn(config.endPoint, config.params)
            .then(response => {
              if (isDebugInfoOn()) {
                writeToDebug(response)
              }

              if (config.dependentAPICalls && response.data && response.data.data) {
                return dependentAPICalls(config.dependentAPICalls, response, config)
              }

              if (config.loadImage && response.data && response.data.data) {
                let news = []

                if (config.endPoint === '/NewsArticle') {
                  news.push(Object.assign(response.data.data, {documentKey: config.params.articleId}))
                } else if (response.data.data.news && response.data.data.news.length > 0) {
                  news = response.data.data.news
                }

                return loadImage(config, response, news, config.imgSize)
              }
              return {
                inputs: config.params,
                resultKey: config.resultKey || config.endPoint,
                response
              }
            })
        })
      )
    }

    const apiResults = yield call(requestFn, apiCalls)
    const result = {}

    apiResults
      .forEach(apiResult => {
        result[apiResult.resultKey] = apiResult.response.data
        result[apiResult.resultKey + '_Inputs'] = apiResult.inputs || {}
      })
    if (Object.getOwnPropertyNames(result).length > 0) {
      yield put(action.onSuccess({ data: result }))
    } else {
      yield put(onGenericApiFailure({error: {code: 500, message: 'Error!'}}))
    }
  }

  function getQueryString (name) {
    var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href)
    if (results == null) {
      return null
    } else {
      return decodeURI(results[1]) || 0
    }
  }

  // Worker
  function * worker (action) {
    const localAccessToken = getQueryString('access_token') || window.MD.ACCESS_TOKEN
    let accessToken = AccessTokenService.retrieve(localAccessToken)
    if (IsClientAccess()) {
      localStorage.setItem('c_access_token', accessToken)
    } else {
      localStorage.setItem('access_token', accessToken)
    }
    // used to make platform api calls
    apiV2 = MarkitApiService.create(window.MD.PLATFORM_API_URL, accessToken)
    // used to make stifel custom api calls
    if (!api) {
      api = MarkitApiService.create(window.MD.API_URL, accessToken)
      yield put(setAccessToken(accessToken))
    }

    if ([
      'GET_SNAPSHOT_V2_DATA_API',
      'GET_SNAPSHOT_V2_MF_OVERVIEW_DATA_API',
      'GET_SNAPSHOT_V2_10K_GROWTH_CHART_DATA_API',
      'GET_SNAPSHOT_V2_OPTION_CHAIN_DATA_API',
      'GET_SNAPSHOT_V2_OPTION_CHAIN_EXPIRATION_DATES_DATA_API',
      'GET_SNAPSHOT_V2_ETF_OVERVIEW_DATA_API',
      'GET_SNAPSHOT_V2_XID_FROM_SYMBOL_API',
      'GET_SNAPSHOT_V2_NEWS_API',
      'GET_SNAPSHOT_V2_GENERALINFO_API',
      'GET_SNAPSHOT_V2_NEWS_ARTICLE_API',
      'GET_SNAPSHOT_V2_PERFORMANCE_SUMMARY_API',
      'GET_MARKETS_V2_DATA_API',
      'GET_TREASURYYIELD_API',
      'GET_INDICES_V2_DATA_API',
      'GET_SNAPSHOT_V2_NEWS_IMAGE_API',
      'GET_TREASURYYIELD_API',
      'GET_MARKETS_V2_MOVERS_DATA_API',
      'GET_WATCHLISTS_DATA_API',
      'MANAGE_WATCHLIST_API',
      'GET_WATCHLISTS_HOLDINGS_DATA_API',
      'ADD_SYMBOL_TO_WATCHLIST_DATA_API',
      'REQUEST_REVENUE_CHART_API',
      'REQUEST_DIVIDEND_CHART_API',
      'DELETE_SYMBOL_FROM_WATCHLIST_API',
      'REQUEST_WATCHLISTS_HOLDINGS_API',
      'REQUEST_MULTIPLE_CALLS_API',
      'GET_SNAPSHOT_V2_FUND_INDICATOR_DATA_API',
      'REQUEST_WATCHLIST_DIVIDEND_API',
      'REQUEST_WATCHLIST_XID_INSTRUMENT_API',
      'GET_MARKETS_EQUITY_DIVIDENT_DATA_API'
    ].indexOf(action.type) !== -1) {
      yield multiWorkerSnapshotV2(action)
    } else {
      let endPoint = action.endPoint
      let parameters = action.params
      let successAction = action.onSuccess
      let failureAction = action.onFailure || onGenericApiFailure
      let httpMethod = action.ajaxType

      let apiType = getApiFunction(httpMethod)

      try {
        let response

        if (action.type === Types.GET_URLBASE_API) {
          response = {data: {url: url}, ok: true}
        } else {
          response = yield call(apiType, endPoint, parameters)

          if (isDebugInfoOn()) {
            writeToDebug(response)
          }
        }

        if (response.ok) {
          // 'data' keys the entire response object... always.
          yield put(successAction(response.data))
        } else {
          yield put(failureAction(response))
        }
      } catch (error) {
        yield put(failureAction(error))
      }
    }
  }

  // Watcher
  function * watcher () {
    const typesArray = getApiTypes()
    while (true) {
      const action = yield take(typesArray)
      yield fork(worker, action)
    }
  }

  return {
    watcher
  }
}
