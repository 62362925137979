/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import { connect } from 'react-redux'
import ShowMoreText from 'react-show-more-text'
import PropTypes from 'prop-types'
import snapshotStyles from '../../../../../styles/snapshot.V2/snapshot.scss'
import snapshotNewsArticleStyles from '../../../../../styles/snapshot.V2/news/snapshotNewsArticle.scss'
import * as SnapshotV2Actions from '../../../../../actions/snapshot.V2.Actions'
import { CreateMarkup, GetTitleToDisplay, CreateMarkupTitle } from './Common'
import { Format } from 'stifel-intyce-pattern-library'
import { updateParentHeightForMobileView, SetParentHeight } from '../../../common.v2/CommonMethods'
import commonStyles from '../../../../../styles/common.v2/common.scss'
import { Carousel } from 'react-responsive-carousel'
class NewsArticle extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      articleId: null
    }

    this.props.setFetchingDataFlag({
      newsArticle: {
        fetching: true
      }
    }
    )

    this.onLinkClick = this.onLinkClick.bind(this)
    this.onImageLoadHandler = this.onImageLoadHandler.bind(this)
    this.getCarouselData = this.getCarouselData.bind(this)
  }

  componentDidMount () {
    let self = this
    window.setTimeout(function () {
      SetParentHeight(null)
    }, 50)
    this.setState({
      articleId: this.props.articleId ? this.props.articleId : this.props.params.articleId
    }, () => {
      window.setTimeout(function () {
        self.props.requestSnapshotV2NewsArticleData(self.state.articleId)
      }, 0)
    })
  }

  componentDidUpdate (prevProps) {
    updateParentHeightForMobileView()
  }
  onImageLoadHandler (e) {
    updateParentHeightForMobileView()
  }
  onLinkClick (e) {
    if (!e) { return }

    e.preventDefault()
    var url = e.target.getAttribute('id')

    if (e.target.tagName === 'A' && url) {
      window.open(url, '_blank')
      return false
    }
  }

  getCarouselData () {
    return (
      <Carousel showThumbs={false}>
        {this.props.newsArticle.fullText && this.props.newsArticle.fullText.image && this.props.newsArticle.fullText.image.map((item) => {
          return (
            <div>
              <img src={item.URL} alt='News Images' />
              <p className='legend'>{item.CREDIT}</p>
            </div>
          )
        })
        }
      </Carousel>
    )
  }
  render () {
    if (this.props.fetching) {
      return (
        <div className={`${commonStyles['loader']}`}>
          <div className={`${commonStyles['rollingloader']}`} />
        </div>
      )
    }
    return (
      <div ref={this.myRef} className={`${snapshotNewsArticleStyles['news-article-container']}`} data-news-article-container='true'>
        {
          this.props.isErrorOccurred && <div className={`${snapshotStyles['error-container']}`}>
            <div>
              Oops! This story is no longer available.<br />
              {/* <div className={`${snapshotStyles['error-info']}`}>
                (Error Code: {this.props.errorInfo.code}, Message: {this.props.errorInfo.message})
              </div> */}
            </div>
          </div>
        }

        {
          !this.props.isErrorOccurred && <div className={`${snapshotNewsArticleStyles['content-container']}`} data-articleid={this.state.articleId}>
            {
              this.props.newsArticle &&
              this.props.newsArticle.image &&
              this.props.newsArticle.image.Url &&
              <div className={`${snapshotNewsArticleStyles['image-container']}`}>
                <img
                  alt='News Article' data-type='desktop'
                  onLoad={this.onImageLoadHandler}
                  src={this.props.newsArticle.image.Url} />
                { this.state.articleId && (this.state.articleId.indexOf('2993-') !== -1 || this.state.articleId.indexOf('1-') !== -1) &&
                  <div className={`${snapshotNewsArticleStyles['image-banner-top-text']}`}
                    data-image-credit={this.props.newsArticle.imageCredit ? 'true' : 'false'} data-articleid={this.state.articleId}>
                    <div className={`${snapshotNewsArticleStyles['provider-and-date-time']}`}>
                      {this.props.newsArticle.provider} {Format.getFormattedDateTime(this.props.newsArticle.date, { tzConversion: true })}
                    </div>
                    <div className={`${snapshotNewsArticleStyles['author']}`}>
                      {this.props.newsArticle.author}
                    </div>
                    <div className={`${snapshotNewsArticleStyles['title']}`}>
                      <ShowMoreText
                        lines={this.props.isMobileView ? 3 : 2}
                        more=''
                        less=''
                        expanded={false}>
                        {GetTitleToDisplay(this.props.newsArticle.title)}
                      </ShowMoreText>
                    </div>
                  </div>
                }

                { this.state.articleId && (this.state.articleId.indexOf('2993-') === -1 || this.state.articleId.indexOf('1-') === -1) &&
                  this.props.newsArticle.imageCredit &&
                    <div className={`${snapshotNewsArticleStyles['image-credit-container']}`}>
                      {this.props.newsArticle.imageCredit}
                    </div>
                }
              </div>
            }
            { this.state.articleId && (this.state.articleId.indexOf('2993-') === -1) &&
              <React.Fragment>
                {
                  this.props.isMobileView && <div
                    dangerouslySetInnerHTML={CreateMarkupTitle(GetTitleToDisplay(this.props.newsArticle.title))}
                    className={`${snapshotNewsArticleStyles['title']}
                    ${this.props.newsArticle.image && this.props.newsArticle.image.Url ? '' : snapshotNewsArticleStyles['no-image']}`} />
                }

                <div className={`${snapshotNewsArticleStyles['publisher-info']}`}>
                  <span className={`${snapshotNewsArticleStyles['provider']}`}>
                    {this.props.newsArticle.provider}
                  </span>
                  <span className={`${snapshotNewsArticleStyles['date']}`}>
                    {Format.getFormattedDateTime(this.props.newsArticle.date, {tzConversion: true})}
                  </span>
                </div>

                {
                  !this.props.isMobileView && <div
                    dangerouslySetInnerHTML={CreateMarkupTitle(GetTitleToDisplay(this.props.newsArticle.title))}
                    className={`${snapshotNewsArticleStyles['title']}`} />
                }
                <div
                  onClick={this.onLinkClick}
                  className={`${snapshotNewsArticleStyles['article']}`}
                  data-is-news-of-the-day={this.props.newsArticle.subjectCodes && this.props.newsArticle.subjectCodes.indexOf('N/HOH') !== -1}
                  dangerouslySetInnerHTML={this.props.newsArticle.fullText ? CreateMarkup(this.props.newsArticle.fullText) : ''} />
              </React.Fragment>
            }

            { this.state.articleId && (this.state.articleId.indexOf('2993-') !== -1) &&
              <div className={`${snapshotNewsArticleStyles['NewsContainer']}`}>
                <div className={`${snapshotNewsArticleStyles['content']}`}>
                  {this.props.newsArticle.fullText && this.props.newsArticle.fullText.paragraph && this.props.newsArticle.fullText.paragraph.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {/* Video render on left */}
                        {index === 0 && (this.props.newsArticle.fullText.video.length > 0 || this.props.newsArticle.fullText.chart.length > 0) && <div className={`${snapshotNewsArticleStyles['leftShift']} ${snapshotNewsArticleStyles['imageSize']}`}>
                          {this.props.newsArticle.fullText.video[0] && this.props.newsArticle.fullText.video[0].URL &&
                            <div className={`${snapshotNewsArticleStyles['video']}`}>
                              <video width='100%' height='20%' controls>
                                <source src={this.props.newsArticle.fullText.video[0].URL} type='video/mp4' />
                              </video>
                              <span className={`${snapshotNewsArticleStyles['image-credit']}`}>
                                {this.props.newsArticle.fullText.video[0].CREDIT}
                              </span>
                            </div>
                          }
                          {!(this.props.newsArticle.fullText.video[0] && this.props.newsArticle.fullText.video[0].URL) && this.props.newsArticle.fullText.chart[0] && this.props.newsArticle.fullText.chart[0].URL &&
                            <div className={`${snapshotNewsArticleStyles['video']}`}>
                              <img src={this.props.newsArticle.fullText.chart[0].URL} alt='News Images' className={`${snapshotNewsArticleStyles['imageSize']}`} />
                            </div>
                          }
                        </div>}
                        {/* Actual Paragraph */}
                        <div className={index === 0 ? `${snapshotNewsArticleStyles['firstParagraph']} ${snapshotNewsArticleStyles['paraPadding']}` : `${snapshotNewsArticleStyles['paraPadding']}`}>
                          <span
                            // onClick={this.onLinkClick}
                            // data-is-news-of-the-day={this.props.newsArticle.subjectCodes && this.props.newsArticle.subjectCodes.indexOf('N/HOH') !== -1}
                            dangerouslySetInnerHTML={CreateMarkup(`<p>${item.PARAGRAPH}</p>`)} />
                        </div>
                        {/* Desktop View */}
                        {!this.props.isMobileView && <div>
                          {index === 4 && <div className={`${snapshotNewsArticleStyles['rightShift']}`}>
                            {this.props.newsArticle.fullText.video[0] && this.props.newsArticle.fullText.chart[0] && this.props.newsArticle.fullText.chart[0].URL && <div className={`${snapshotNewsArticleStyles['imageSpacing']}`}>
                              <img src={this.props.newsArticle.fullText.chart[0].URL} alt='News Images' className={`${snapshotNewsArticleStyles['imageSize']}`} />
                            </div>}
                            {this.props.newsArticle.fullText.image[0] && this.props.newsArticle.fullText.image.length <= 2 && <div className={`${snapshotNewsArticleStyles['imageSpacing']}`}>
                              <img src={this.props.newsArticle.fullText.image[0].URL} alt='News Images' className={`${snapshotNewsArticleStyles['imageSize']}`} />
                              <div className={`${snapshotNewsArticleStyles['image-credit']} ${snapshotNewsArticleStyles['creditPadding']}`}>
                                {this.props.newsArticle.fullText.image[0].CREDIT}
                              </div>
                            </div>}
                            {this.props.newsArticle.fullText.image.length >= 3 && <div className={`${snapshotNewsArticleStyles['rightShift']}`}>
                              <div className={`${snapshotNewsArticleStyles['carousal-news']}`}>
                                {this.props.newsArticle.fullText.image.length >= 3 &&
                                  <React.Fragment>
                                    {this.getCarouselData()}
                                  </React.Fragment>
                                }
                              </div>
                            </div>
                            }
                          </div>
                          }
                          {index === 6 && <div className={`${snapshotNewsArticleStyles['leftShift']}`}>
                            {this.props.newsArticle.fullText.image && this.props.newsArticle.fullText.image.length === 2 && <div className={`${snapshotNewsArticleStyles['imageSpacing']}`}>
                              <img src={this.props.newsArticle.fullText.image[1].URL} alt='News Images' className={`${snapshotNewsArticleStyles['imageSize']}`} />
                              <div className={`${snapshotNewsArticleStyles['image-credit']} ${snapshotNewsArticleStyles['creditPadding']}`}>
                                {this.props.newsArticle.fullText.image[1].CREDIT}
                              </div>
                            </div>}
                          </div>
                          }
                        </div>}
                        {/* Mobile View */}
                        {this.props.isMobileView && <div>
                          {index === 4 && <div className={`${snapshotNewsArticleStyles['rightShift']}`}>
                            {this.props.newsArticle.fullText.video[0] && this.props.newsArticle.fullText.chart[0] && this.props.newsArticle.fullText.chart[0].URL && <div className={`${snapshotNewsArticleStyles['imageSpacing']}`}>
                              <img src={this.props.newsArticle.fullText.chart[0].URL} alt='News Images' className={`${snapshotNewsArticleStyles['imageSize']}`} />
                            </div>}
                          </div>
                          }
                          {index === 5 && <div className={`${snapshotNewsArticleStyles['rightShift']}`}>
                            {this.props.newsArticle.fullText.image[0] && this.props.newsArticle.fullText.image.length <= 2 && <div className={`${snapshotNewsArticleStyles['imageSpacing']}`}>
                              <img src={this.props.newsArticle.fullText.image[0].URL} alt='News Images' className={`${snapshotNewsArticleStyles['imageSize']}`} />
                              <div className={`${snapshotNewsArticleStyles['image-credit']} ${snapshotNewsArticleStyles['creditPadding']}`}>
                                {this.props.newsArticle.fullText.image[0].CREDIT}
                              </div>
                            </div>}
                          </div>
                          }
                          {index === 6 && <div className={`${snapshotNewsArticleStyles['rightShift']}`}>
                            {this.props.newsArticle.fullText.image && this.props.newsArticle.fullText.image.length === 2 && <div className={`${snapshotNewsArticleStyles['imageSpacing']}`}>
                              <img src={this.props.newsArticle.fullText.image[1].URL} alt='News Images' className={`${snapshotNewsArticleStyles['imageSize']}`} />
                              <div className={`${snapshotNewsArticleStyles['image-credit']} ${snapshotNewsArticleStyles['creditPadding']}`}>
                                {this.props.newsArticle.fullText.image[1].CREDIT}
                              </div>
                            </div>}
                          </div>
                          }
                          {index === 7 && this.props.newsArticle.fullText.image.length >= 3 && <div className={`${snapshotNewsArticleStyles['rightShift']}`}>
                            <div className={`${snapshotNewsArticleStyles['carousal-news']}`}>
                              {this.props.newsArticle.fullText.image.length >= 3 &&
                                <React.Fragment>
                                  {this.getCarouselData()}
                                </React.Fragment>
                              }
                            </div>
                          </div>
                          }
                        </div>}

                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            }
          </div>
        }

        {/* <div className={`${marketsStyles['user-type-indicator']} ${newsArticleStyles['user-type-indicator']}`}>
          <UserTypeIndicator userType='Tracker' />
        </div> */}
      </div>
    )
  };
};

NewsArticle.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isErrorOccurred: PropTypes.bool.isRequired,
  newsArticle: PropTypes.object.isRequired,
  params: PropTypes.object,
  articleId: PropTypes.string,
  setFetchingDataFlag: PropTypes.func.isRequired,
  // errorInfo: PropTypes.object.isRequired,
  isMobileView: PropTypes.bool.isRequired
}

function mapStateToProps (state, ownProps) {
  let obj = {
    fetching: state.snapshotV2Reducer.newsArticle.fetching,
    isErrorOccurred: !!state.snapshotV2Reducer.newsArticle.error,
    errorInfo: state.snapshotV2Reducer.newsArticle.error || {},
    newsArticle: {}
  }

  if (state.snapshotV2Reducer.newsArticle && state.snapshotV2Reducer.newsArticle.data) {
    Object.assign(obj, {
      newsArticle: state.snapshotV2Reducer.newsArticle.data
    })
  }

  // check images if not available then add static images
  if (!obj.newsArticle.image &&
    ownProps.useStaticImage === 'true' &&
    ownProps.staticImageUrl) {
    obj.newsArticle.image = {
      Url: ownProps.staticImageUrl
    }
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(SnapshotV2Actions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestSnapshotV2NewsArticleData: (articleId) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2NewsArticleData(articleId, true, 'Large'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsArticle)
