/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import newsCard from '../../../../styles/snapshot.V2/news/newsCard.scss'
import marketsV2Styles from '../../../../styles/markets.V2/news/news.scss'
import { Format } from 'stifel-intyce-pattern-library'
import {RenameNewsProvider, CreateMarkupTitle, RemvoveHTMLTags, GetTitleToDisplay} from './Common'

function getNewsCardWithImageHtml (news) {
  return <div className={`${newsCard['news-card']} ${newsCard['image-news-card']}`}
    style={news.image ? { backgroundImage: 'url(' + news.image.Url + ')' } : {}}>
    <div className={`${newsCard['overlay']}`}>
      <div className={`${newsCard['title']}`} title={GetTitleToDisplay(news.title)}>
        <span dangerouslySetInnerHTML={CreateMarkupTitle(GetTitleToDisplay(news.title))} />
      </div>
      <div className={`${newsCard['publisher-info']}`}>
        <span className={`${newsCard['provider']}`}> {RenameNewsProvider(news.provider)} </span>
        <span data-el-identifier='news-card-date_value' className={`${newsCard['date']}`}> {Format.getFormattedDateTime(news.date, {tzConversion: true})}</span>
      </div>
    </div>
  </div>
}

function getNewsCardWithoutImageHtml (news, cardNumber) {
  return <div className={`${newsCard['news-card']}`}>
    <div className={`${newsCard['publisher-info']}`}>
      <span className={`${newsCard['provider']}`}> {RenameNewsProvider(news.provider)} </span>
      <span className={`${newsCard['date']}`}> {Format.getFormattedDateTime(news.date, {tzConversion: true})} </span>
    </div>
    <div className={`${newsCard['title']}`} title={GetTitleToDisplay(news.title)}>
      <span dangerouslySetInnerHTML={CreateMarkupTitle(GetTitleToDisplay(news.title))} />
    </div>
    <div className={`${newsCard['teaser']}`}>
      <span dangerouslySetInnerHTML={RemvoveHTMLTags(news.teaser, 160)} />
    </div>
  </div>
}

const NewsCard = props => {
  return (
    <div role='presentation'
      className={`${newsCard['news-card-container']} ${marketsV2Styles['news-card-container']}`}
      data-card-no={props.cardNumber}
      data-articleId={props.news.documentKey}
      data-use-static-image={props.news.useStaticImage}
      data-static-image-url={props.news.useStaticImage && props.news.image && props.news.image.Url}
      onClick={props.onClick}>
      {
        (props.news.image && props.news.image.Url)
          ? getNewsCardWithImageHtml(props.news) : getNewsCardWithoutImageHtml(props.news, props.cardNumber)
      }
    </div>
  )
}

NewsCard.propTypes = {
  news: PropTypes.object.isRequired,
  cardNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
}

export default NewsCard
