/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import { UserTypeIndicator } from 'stifel-intyce-pattern-library'
import WatchlistOverview from './WatchlistOverview'
import CreateWatchlistCard from './CreateWatchlistCard'
import WatchlistCard from './WatchlistCard'
import AsOfDate from './AsOfDate'
// import WatchlistsStyles from '../../../styles/watchlist/watchlists.scss'
import WatchlistCardStyles from '../../../styles/watchlist/watchlistCard.scss'
import applicationStyles from '../../../styles/main.scss'
// import { ISSUE_TYPES_MAP, ISSUE_TYPES } from '../../../utils/appConstants'
import { getLargestDate } from '../../common.v2/CommonMethods'

class WatchlistCardsContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      asOfDate: null,
      mfAsOfDate: null,
      watchlistHoldings: []
    }
  }

  componentDidMount () {
  }

  componentWillReceiveProps (nextProps) {
    // Get max date from all watchilis symbols
    if (nextProps.holdings && nextProps.holdings.length > 0) {
      let dates = getLargestDate(nextProps.holdings)

      if (dates.dateMf) {
        // get max date
        let maxDate = new Date(Math.max(dates.dateMf, this.state.mfAsOfDate))
        this.setState({mfAsOfDate: maxDate})
      }
      if (dates.date) {
        // get max date
        let maxDate = new Date(Math.max(dates.date, this.state.asOfDate))
        this.setState({asOfDate: maxDate})
      }
    }
  }

  render () {
    return (
      <div className={WatchlistCardStyles['watchlist-card-container']}>
        <div className={WatchlistCardStyles['as-of-date-container']}>
          <AsOfDate asOfDate={this.state.asOfDate} mfAsOfDate={this.state.mfAsOfDate} />
        </div>
        {
          this.props.watchlists.map(
            (item) => (
              <WatchlistCard key={item.id}>
                <WatchlistOverview
                  watchlistName={item.name}
                  watchlistId={item.id}
                  deleteWatchlist={this.props.deleteWatchlist}
                  deleteWatchlistEnter={this.props.deleteWatchlistEnter}
                  onSymbolClick={this.props.onSymbolClick}
                  onSymbolClickEnter={this.props.onSymbolClickEnter}
                  handleWatchlistSelection={this.props.handleWatchlistSelection}
                  createWatchlist={this.props.createWatchlist}
                  createWatchlistEnter={this.props.createWatchlistEnter}
                  // createWatchlistEnter={this.props.createWatchlistEnter}
                  isMobileView={this.props.isMobileView} />
              </WatchlistCard>
            )
          )
        }
        <div className={`${applicationStyles['pure-hidden-xs']} ${applicationStyles['pure-hidden-sm']}`}>
          <WatchlistCard>
            <CreateWatchlistCard onClick={this.props.createWatchlist}
              onKeyPress={this.props.createWatchlistEnter} watchlistCount={this.props.watchlists.length} />
          </WatchlistCard>
        </div>
        {/* <div className={`${WatchlistsStyles['user-type-indicator']}`}>
          <UserTypeIndicator userType='Tracker' />
        </div> */}
      </div>
    )
  }
}

WatchlistCardsContainer.propTypes = {
  watchlists: PropTypes.array.isRequired,
  onSymbolClick: PropTypes.func,
  onSymbolClickEnter: PropTypes.func,
  handleWatchlistSelection: PropTypes.func,
  createWatchlist: PropTypes.func.isRequired,
  createWatchlistEnter: PropTypes.func.isRequired,
  deleteWatchlist: PropTypes.func.isRequired,
  deleteWatchlistEnter: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool.isRequired,
  holdings: PropTypes.array
}

function mapStateToProps (state, ownProps) {
  let obj = {
    holdings: []
  }

  if (state.watchlistReducer.watchlistHoldings_Platform.data &&
    state.watchlistReducer.watchlistHoldings_Platform.data.holdings.length > 0) {
    Object.assign(obj, {
      holdings: state.watchlistReducer.watchlistHoldings_Platform.data.holdings
    })
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistCardsContainer)
