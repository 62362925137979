import Types from './types'
import { API_END_POINTS } from '../utils/appConstants'

const receiveMarketsV2Data = (response) => {
  return {
    type: Types.GET_MARKETS_V2_DATA_API_SUCCESS,
    payload: response.data
  }
}

const requestMarketsV2Data = (wsodIssue, loadImage, newsProvider) => {
  return {
    type: Types.GET_MARKETS_V2_DATA_API,
    apiCalls: [
      {
        resultKey: 'quoteData',
        endPoint: API_END_POINTS.QUOTE,
        params: { wsodIssue: wsodIssue },
        ajaxType: 'GET'
      },
      {
        resultKey: 'quoteIndiceData',
        endPoint: API_END_POINTS.QUOTE_INDICE,
        ajaxType: 'GET'
      },
      {
        resultKey: 'marketsTodayNews',
        endPoint: API_END_POINTS.NEWS,
        loadImage: loadImage,
        imageEndpoint: API_END_POINTS.NEWS_IMAGE,
        imgSize: 'Small',
        params: { rowNumber: 0, articleCount: 1, provider: newsProvider },
        ajaxType: 'GET'
      }
    ],
    onSuccess: receiveMarketsV2Data
  }
}

const receiveMarketsTreasuryYield = (response) => {
  return {
    type: Types.GET_TREASURYYIELD_API_SUCCESS,
    data: response.data
  }
}

const requestMarketsTreasuryYield = () => {
  return {
    type: Types.GET_TREASURYYIELD_API,
    apiCalls: [
      {
        resultKey: 'marketsTreasuryYield',
        endPoint: API_END_POINTS.MARKET_STREASURY_YIELD,
        ajaxType: 'GET'
      }
    ],
    onSuccess: receiveMarketsTreasuryYield
  }
}

const receiveIndicesV2Data = (response) => {
  return {
    type: Types.GET_INDICES_V2_DATA_API_SUCCESS,
    payload: response.data
  }
}

const requestIndicesV2Data = (wsodIssue) => {
  return {
    type: Types.GET_INDICES_V2_DATA_API,
    apiCalls: [
      {
        resultKey: 'quoteIndiceData',
        endPoint: API_END_POINTS.QUOTE_INDICE,
        ajaxType: 'GET'
      },
      {
        resultKey: 'quoteData',
        endPoint: API_END_POINTS.QUOTE,
        params: { wsodIssue: wsodIssue },
        ajaxType: 'GET'
      }
    ],
    onSuccess: receiveIndicesV2Data
  }
}

const receiveMarketsV2MoversData = (response) => {
  return {
    type: Types.GET_MARKETS_V2_MOVERS_DATA_API_SUCCESS,
    payload: response.data
  }
}

const requestMarketsV2MoversData = (exchange = 'NYSE', rankingType = 'MostActive', rowCount = 5) => {
  return {
    type: Types.GET_MARKETS_V2_MOVERS_DATA_API,
    apiCalls: [
      {
        resultKey: 'marketsRankAll',
        endPoint: API_END_POINTS.MARKETS_RANK_ALL,
        ajaxType: 'GET',
        params: { exchange: exchange, rankingType: rankingType, rowCount: rowCount }
      }
    ],
    onSuccess: receiveMarketsV2MoversData
  }
}

const receiveSymbolSearchApi = response => ({
  type: Types.RECIEVE_SYMBOLSEARCH,
  response
})

const requestSymbolSearchApi = (input) => ({
  type: Types.API_REQUEST_SYMBOLSEARCH_API,
  endPoint: '/xref/search',
  params: {searchText: input},
  ajaxType: 'GET',
  onSuccess: receiveSymbolSearchApi
})

const recieveDecodeApi = response => ({
  type: Types.RECIEVE_DECODE,
  response
})

const requestDecodeApi = (input) => ({
  type: Types.API_REQUEST_DECODE_API,
  endPoint: '/xref/decode',
  params: {key: input},
  ajaxType: 'GET',
  onSuccess: recieveDecodeApi
})

const onGenericApiFailure = (response) => ({
  type: Types.GENERIC_FAILURE,
  data: response.error
})

const setAccessToken = (accessToken) => ({
  type: Types.SET_ACCESS_TOKEN,
  accessToken: accessToken
})

const setFetchingDataFlag = (response) => ({
  type: Types.SET_FETCHING_DATA_FLAG,
  payload: response
})

export {
  setFetchingDataFlag,
  requestMarketsV2Data,
  receiveMarketsV2Data,
  requestMarketsTreasuryYield,
  receiveMarketsTreasuryYield,
  requestIndicesV2Data,
  receiveIndicesV2Data,
  requestMarketsV2MoversData,
  receiveMarketsV2MoversData,
  requestSymbolSearchApi,
  receiveSymbolSearchApi,
  requestDecodeApi,
  recieveDecodeApi,
  onGenericApiFailure,
  setAccessToken
}
