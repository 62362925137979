import React from 'react'
import Styles from '../../styles/Authentication/auth.scss'

class Logout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentWillMount () {
    localStorage.removeItem('access_token')
  }

  render () {
    return (
      <div className={`${Styles['container']}`}>
        <div className={`${Styles['login']}`}>
          Signed off successfully. Click <a href='/#/Login'> here</a> to login again.
        </div>
      </div>
    )
  }
};

export default Logout
