/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ArrowSVG, Format } from 'stifel-intyce-pattern-library'
import * as WatchlistActions from '../../../actions/watchlist.Actions'
import topMoverStyles from '../../../styles/markets.V2/marketMovers/topMover.scss'
import marketsStyles from '../../../styles/markets.V2/markets.scss'
import commonStyles from '../../../styles/common.v2/common.scss'

class TopMover extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fetching: true,
      topMover: null
    }
  }
  componentDidMount () {
    this.props.watchlists.map(
      (item) => (
        this.props.requestWatchlistHoldings(item.id)
      ))
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.holdingDetails && (nextProps.holdingDetails.length === this.props.watchlists.length)) {
      this.setState({
        fetching: false,
        topMover: this.getTopMover(nextProps.holdingDetails)
      })
    } else {
      this.setState({
        fetching: false
      })
    }
  }

  getTopMover (holdingDetails) {
    let sortedList = holdingDetails.sort(function (a, b) {
      return parseFloat(a.bestHolding.changePct) - parseFloat(b.bestHolding.changePct)
    })
    return sortedList[sortedList.length - 1]
  }

  render () {
    if (this.state.fetching) {
      return (
        <div className={topMoverStyles['top-mover']}>
          <div className={topMoverStyles['top-mover-header']}>
            My Top Mover
          </div>
          <div className={topMoverStyles['top-mover-details']}>
            <div className={`${commonStyles['loader']} ${marketsStyles['loader-no-border']}`}>
              <div className={`${commonStyles['rollingloader']}`} />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className={topMoverStyles['top-mover']}>
        <div className={topMoverStyles['top-mover-header']}>
          My Top Mover
        </div>
        {this.state.topMover
          ? <div role='presentation' className={topMoverStyles['top-mover-details']} data-symbol={this.state.topMover.bestHolding.symbol} data-wsod-issue={this.state.topMover.bestHolding.xid} onClick={this.state.topMover.bestHolding.xid ? this.props.onClick : ''}>
            <div className={topMoverStyles['company-details']}>
              <div className={topMoverStyles['company-name']}>
                <span data-el-identifier='topmover_name'>{this.state.topMover.bestHolding.name}</span>
              </div>
              <div className={topMoverStyles['symbol-exchange']}>
                <span>{this.state.topMover.bestHolding.symbol} </span>
                <span>{this.state.topMover.bestHolding.exchange}</span>
              </div>
            </div>
            <div className={topMoverStyles['last-price']}>
              <div className={topMoverStyles['label']}>
                <span>Last Price</span>
              </div>
              <div className={topMoverStyles['value']}>
                <span>{Format.formatNumber(this.state.topMover.bestHolding.last, {currencyPreFix: true})}</span>
              </div>
            </div>
            <div className={topMoverStyles['today-change']}>
              <div className={topMoverStyles['label']}>
                <span>Today's Change</span>
              </div>
              <div className={topMoverStyles['value']}>
                <span>{Format.formatNumber(this.state.topMover.bestHolding.change, {currencyPreFix: true, negSign: true, color: true})} </span>
                <span>{Format.formatNumber(this.state.topMover.bestHolding.changePct, {parentheses: false, postFix: true, negSign: true, color: true}) } </span>
                <ArrowSVG priceChange={this.state.topMover.bestHolding.change} positiveColor={'#5C8118'} negativeColor={'#CB223C'} />
              </div>
            </div>
          </div>
          : <div className={topMoverStyles['no-data']}>
            <span>
              Oops! We couldn't find related data.
            </span>
          </div>
        }
      </div>
    )
  }
}

TopMover.propTypes = {
  watchlists: PropTypes.array.isRequired,
  holdingDetails: PropTypes.array,
  requestWatchlistHoldings: PropTypes.func,
  onClick: PropTypes.func.isRequired
}

function mapStateToProps (state, ownProps) {
  let obj = {
    isErrorOccurred: !!state.watchlistReducer.watchlistHoldings_Platform.error,
    errorInfo: state.watchlistReducer.watchlistHoldings_Platform.error || {},
    holdingDetails: []
  }

  if (!obj.isErrorOccurred) {
    Object.assign(obj, {
      holdingDetails: state.watchlistReducer.watchlistHoldings_Platform.data.holdingDetails
    })
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    requestWatchlistHoldings: (id) => {
      return dispatch(WatchlistActions.requestWatchlistHoldings(id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMover)
