import {connect} from 'react-redux'
import * as marketV2Actions from '../../../../actions/markets.V2.Action'
import CompanySearch from './CompanySearch'

function mapStateToProps (state) {
  return {
    symbolSearchResults: state.marketsV2Reducer.symbolSearchResults,
    xid: state.marketsV2Reducer.xid
  }
}

function mapDispatchToProps (dispatch) {
  return {
    requestSymbolSearchApi: (input) => {
      return dispatch(marketV2Actions.requestSymbolSearchApi(input))
    },
    requestDecodeApi: (key) => {
      return dispatch(marketV2Actions.requestDecodeApi(key))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySearch)
