const INITIAL_STATE = {
  watchlistsData_Platform: {
    fetching: true,
    data: {
      watchlists: []
    }
  },
  watchlistHoldings_Platform: {
    fetching: true,
    data: {
      holdings: [],
      holdingDetails: []
    }
  },
  symbolsToWatchlist_Platform: {
    data: {
      holdings: []
    }
  },
  manageWatchlistResponse: {
    data: {
      id: null
    }
  },
  deleteSymbolFromWatchlistResponse: {
    data: {
      holdings: []
    }
  },
  watchlistsHoldings_Platform: {
    fetching: true,
    data: {
      holdings: []
    }
  }
}
export default INITIAL_STATE
