import React from 'react'
import PropTypes from 'prop-types'
import marketMoversTable from '../../styles/common.v2/marketMoversTable.scss'

const MarketMoversTableHeader = props => {
  return (
    <div className={`${marketMoversTable['header']} ${marketMoversTable['header-1']} ${marketMoversTable['row']}`}>
      <div className={`${marketMoversTable['symbol']}`}>Symbol / Name</div>
      <div className={`${marketMoversTable['last']}`}>Last</div>
      <div className={`${marketMoversTable['change']} ${props.rankType !== 'MostActive' ? marketMoversTable['large-Size-Bold'] : ''}`}>
        Change
      </div>
      <div className={`${marketMoversTable['range-52week-low-high']}`}>
        <div className={`${marketMoversTable['low']}`}>Low</div>
        <div className={`${marketMoversTable['high']}`}>High</div>
      </div>
      <div className={`${marketMoversTable['volume']}
        ${props.rankType === 'MostActive' ? marketMoversTable['large-Size-Bold'] : ''}`}>
        Volume
      </div>
    </div>
  )
}

MarketMoversTableHeader.propTypes = {
  rankType: PropTypes.string
}

export default MarketMoversTableHeader
