import 'babel-polyfill'
import React from 'react'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
import Header from './header/Header'
import MarketToday from './marketToday/MarketToday'
import MarketsNewsContainer from './news/MarketsNewsContainer'
import MarketMoversContainer from './marketMovers/MarketMoversContainer'
import TreasuryYields from './treasuryYields/TreasuryYields'
import marketsStyles from '../../styles/markets.V2/markets.scss'
import { INDICES_SYMBOL, MAX_WIDTH_TO_OPEN_SNAPSHOT_IN_INPAGE, SNAPSHOT_WSODISSUE_CONSTANTS, SNAPSHOT_SYMBOL_CONSTANTS, LOCALSTORAGETYPE_CONSTANTS } from '../../utils/appConstants'
import { UserTypeIndicator, SnapshotModalPopUp } from 'stifel-intyce-pattern-library'
import Snapshot from '../snapshot.V2/Container'
import { updateParentHeightForMobileView, GetLocalStorageIfAvailable, ClearLocalStorage } from '../common.v2/CommonMethods'
import * as UsageReporting from '../../utils/usageReporting'
import commonStyle from '../../styles/common.v2/common.scss'

export default class Markets extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isMobileView: false,
      isSnaphotModalPopupOpen: 0,
      symbol: null,
      wsodIssue: null
    }

    this.props.setFetchingDataFlag({ fetching: true })
    this.openSnapShot = this.openSnapShot.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.setIsMobileView = this.setIsMobileView.bind(this)
    sessionStorage.removeItem('snapshot-v2-expand-collapse')
    sessionStorage.removeItem('snapshot-v2-news-page-no')
    sessionStorage.removeItem('snapshot-v2-selected-tab')
    UsageReporting.trackPage('Tracker Markets and News', '')
  }

  componentWillMount () {
    this.setIsMobileView()
  }

  componentDidMount () {
    this.props.requestMarketsV2Data(INDICES_SYMBOL.DJIA, true, 'Top News')
    window.addEventListener('resize', this.handleResize)
    updateParentHeightForMobileView()
    let localStorage = GetLocalStorageIfAvailable(LOCALSTORAGETYPE_CONSTANTS.MARKETNEWS)
    if (localStorage) {
      this.openSnapShot(null, localStorage)
    }
  }

  componentDidUpdate (prevProps) {
    updateParentHeightForMobileView()
  }

  setIsMobileView () {
    if (window.innerWidth < 768) {
      this.setState({ isMobileView: true })
    } else {
      this.setState({ isMobileView: false })
    }
  }

  handleResize () {
    this.setIsMobileView()
  }

  openSnapShot (e, localStorage) {
    let wsodIssue
    let symbol
    if (e) {
      e.preventDefault()
      let el = e.currentTarget
      wsodIssue = el.getAttribute('data-wsod-issue')
      symbol = el.getAttribute('data-symbol')
    } else if (localStorage) {
      wsodIssue = localStorage[SNAPSHOT_WSODISSUE_CONSTANTS]
      symbol = localStorage[SNAPSHOT_SYMBOL_CONSTANTS]
      if (!wsodIssue) {
        return
      }
    } else {
      return
    }
    if (window.innerWidth < MAX_WIDTH_TO_OPEN_SNAPSHOT_IN_INPAGE) {
      hashHistory.push(`/Snapshot/V2/${symbol}/${wsodIssue}?returnUrl=/Markets/V2&page=${LOCALSTORAGETYPE_CONSTANTS.MARKETNEWS}`)
    } else {
      this.setState((prevState) => {
        return {
          isSnaphotModalPopupOpen: 1,
          symbol: symbol,
          wsodIssue: wsodIssue
        }
      })
    }
  }

  closePopup (e) {
    e.preventDefault()
    if (e.target.getAttribute('id') === 'closeButton' ||
      e.key === 'Escape' || e.keyCode === 27) {
      ClearLocalStorage(LOCALSTORAGETYPE_CONSTANTS.MARKETNEWS)
      this.setState((prevState) => {
        return { isSnaphotModalPopupOpen: 0 }
      })
    }
  }

  render () {
    if (this.props.fetching) {
      return (
        <div className={`${marketsStyles['markets-container']}`}>
          <div className={`${commonStyle['loader']} ${commonStyle['loader-no-border']}`}>
            <div className={`${commonStyle['rollingloader']} ${commonStyle['rollingloader-pos']}`} />
          </div>
        </div>
      )
    }
    return (
      <div className={`${marketsStyles['markets-container']}`}>
        <Header isMobileView={this.state.isMobileView} openSnapShot={this.openSnapShot} page={LOCALSTORAGETYPE_CONSTANTS.MARKETNEWS} />
        <MarketToday isMobileView={this.state.isMobileView}
          isMarketOpen={this.props.isMarketOpen}
          marketsTodayNews={this.props.marketsTodayNews}
          quoteIndiceData={this.props.quoteIndiceData}
          openSnapShot={this.openSnapShot} />
        <MarketsNewsContainer isMobileView={this.state.isMobileView} />
        <MarketMoversContainer isMobileView={this.state.isMobileView}
          openSnapShot={this.openSnapShot} />
        <TreasuryYields isMobileView={this.state.isMobileView} />
        <div className={`${marketsStyles['user-type-indicator']}`}>
          <UserTypeIndicator userType='Tracker' />
        </div>
        {
          this.state.isSnaphotModalPopupOpen === 1 &&
          <SnapshotModalPopUp
            onClick={this.closePopup}
            isOpen={this.state.isSnaphotModalPopupOpen}>
            <Snapshot wsodIssue={this.state.wsodIssue} symbol={this.state.symbol} page={LOCALSTORAGETYPE_CONSTANTS.MARKETNEWS} />
          </SnapshotModalPopUp>
        }
      </div>
    )
  }
}

Markets.propTypes = {
  fetching: PropTypes.bool.isRequired,
  setFetchingDataFlag: PropTypes.func.isRequired,
  requestMarketsV2Data: PropTypes.func.isRequired,
  quoteIndiceData: PropTypes.object,
  marketsTodayNews: PropTypes.object.isRequired,
  isMarketOpen: PropTypes.bool.isRequired
}
