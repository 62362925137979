/*
  This component will be used by client. URL: #/Navigator/CompanySnapshot/AAPL
*/
import 'babel-polyfill'
import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import * as SnapshotV2Actions from '../../../actions/snapshot.V2.Actions'
import snapshotStyles from '../../../styles/snapshot.V2/snapshot.scss'
import Snapshot from './Container'
import commonStyles from '../../../styles/common.v2/common.scss'

class CompanySnapshot extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      symbol: null
    }

    this.getErrorMessageHTML = this.getErrorMessageHTML.bind(this)
  }

  componentDidMount () {
    if (this.props.params.symbol) {
      this.setState({
        symbol: this.props.params.symbol
      }, () => {
        if (this.state.symbol) {
          this.props.requestSnapshotV2XidFromSymbol(this.props.params.symbol)
        }
      })
    }
  }

  getErrorMessageHTML (code, message) {
    return <div className={`${snapshotStyles['error-container']}`}>
      { code !== 404 &&
        <div>
          Oops! There was an error processing your request<br />
          <div className={`${snapshotStyles['error-info']}`}>
            (Error Code: {code}, Message: {message})
          </div>
        </div>
      }
      { code === 404 &&
        <div>
          We are sorry, but we are unable to generate a company overview for this symbol.<br />
        </div>
      }
    </div>
  }

  render () {
    if (!this.state.symbol) {
      return (
        this.getErrorMessageHTML('404', 'Missing required parameters.')
      )
    }

    if (this.props.fetching) {
      return (
        <div className={`${commonStyles['loader']}`}>
          <div className={`${commonStyles['rollingloader']}`} />
        </div>
      )
    }

    if (this.props.isErrorOccurred) {
      return (
        this.getErrorMessageHTML(this.props.errorInfo.code, this.props.errorInfo.message)
      )
    }

    return (
      <Snapshot wsodIssue={this.props.xid} symbol={this.props.symbol} />
    )
  };
};

CompanySnapshot.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isErrorOccurred: PropTypes.bool.isRequired,
  xid: PropTypes.string,
  symbol: PropTypes.string,
  params: PropTypes.object.isRequired,
  errorInfo: PropTypes.object.isRequired,
  requestSnapshotV2XidFromSymbol: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  let obj = {
    fetching: state.snapshotV2Reducer.xidFromSymbol.fetching,
    isErrorOccurred: !!state.snapshotV2Reducer.xidFromSymbol.error,
    errorInfo: state.snapshotV2Reducer.xidFromSymbol.error || {}
  }

  if (state.snapshotV2Reducer.xidFromSymbol.data &&
    state.snapshotV2Reducer.xidFromSymbol.data.xids) {
    Object.assign(obj, {
      symbol: state.snapshotV2Reducer.xidFromSymbol.data.symbol,
      xid: state.snapshotV2Reducer.xidFromSymbol.data.xids.venue
    })
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    requestSnapshotV2XidFromSymbol: (symbol) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2XidFromSymbol(symbol))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySnapshot)
